import React, {useEffect, useState} from "react";
import {toTitleCase} from "../../../../../service/string";

interface I {
  title: string
  eventityType: string

  data: any
}

const EventityItemName: React.FC<I> = ({eventityType, title, data}) => {

  const [name, setName] = useState("Eventity Item")
  useEffect(() => {
    if(data && data.hasOwnProperty(title)) {
      setName(data[title])
    }
  }, [data, title, eventityType])

  return <>
    {toTitleCase(eventityType)} "{name}"
  </>
}

export default EventityItemName
