import React from "react";
import {useQuery} from "@apollo/client";
import {READ_ME} from "../../graphql/queries/me";
import {ContentHeader} from "../layouts/style";
import {useHistory} from "react-router";
import {Avatar, Card, Col, Row} from "antd";
import {EditOutlined, SettingOutlined} from '@ant-design/icons';

export const Profile: React.FC = (props) => {
  const {goBack} = useHistory()
  const {data} = useQuery(
    READ_ME
  )

  return (<>
    <ContentHeader
      title={"User Profile"}
      onBack={() => void goBack()}
    />
    <Row gutter={[16,16]}>
      <Col xs={24} lg={6} >
        <Card
          actions={[
            <SettingOutlined key={"settings"} />,
            <EditOutlined key={"edit"} />
          ]}
        >
          <Card.Meta
            avatar={<Avatar gap={1}>{data.me.name.charAt(0).toLocaleUpperCase()}</Avatar>}
            title={data.me.name}
            description={data.me.email}
          />

        </Card>
      </Col>
      <Col xs={24} lg={6}>
        <Card

        >
          <Card.Meta
            description={"Some Other Function"}
          />

        </Card>
      </Col>
    </Row>

  </>)
}
