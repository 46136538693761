import styled from "styled-components";
import {Card} from "antd";

export const DashCard = styled(Card)`
  height: 14rem;
  color: #1890ff;
  cursor: pointer;
  &:hover {
    color: #40a9ff;
  }
  display: flex;
  flex-direction: column;
  .ant-card-body {
    margin-bottom: auto;
  }
  .ant-card-actions {
    min-height: 57px;
    .anticon {
      font-size: 1.5rem;
    }
    .ant-upload {
      color: inherit;
    }
  }
`

export const UploadCard = styled(DashCard)`
  cursor: initial;
  .ant-card-meta-title {
    color: #1890ff;
  }

`

export const DashCardTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  color: inherit;
`

export const DashCardIcon = styled.span`
  font-size: 3rem;
  margin: auto;
  text-align: center;
  display: block;

`
