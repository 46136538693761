import gql from "graphql-tag";
import {IWorkspace} from "./workspace";
import {IAccount} from "./auth";
import {DEFAULT_LISTLEN} from "./const";

export const READ_ORGS = gql`
  query ReadOrgs($filter: [JSON!] = [] $page: Int = 1 $limit: Int = ${DEFAULT_LISTLEN} $sort: [Sort!] = [{field: "name", direction: "ASC"}] ) {
    orgs(filter: $filter page: $page limit: $limit sort: $sort) {
      entries {
        name id
      }
      totalCount
      pageInfo {
        currentPage pageSize
      }
    }
  }
`;

export interface IDataOrganisation {
  readonly data: ISysOrganisation
}
export interface ISysOrganisation {
  readonly sys: IOrganisation
}
export interface IOrganisation {
  readonly name: string
  readonly id: string
  readonly workspaces: IWorkspace[]
  readonly accounts: IAccount[]
}

export const READ_ORG = gql`
  query readOrg($id: String!) {
    sys(id: $id) {
      id name

      accounts {
        name
        id
      }
    }
  }
`

export const CREATE_ORG = gql`
  mutation CreateOrg($name: String!) {
    CreateOrganisation(name: $name) {
      id name
    }
  }
`

export interface CurrentOrgId {
  readonly data: {
    readonly OrgID: string
  }
}
export const CURRENT_ORG_ID = gql`
  query currentOrgId {
    OrgID @client
  }
`

export const ADD_ACCOUNT_TO_ORG = gql`
  mutation AddAccountToOrg($email: String!, $orgId: String!) {
    AddAccountToOrganisation(email: $email, organisationId: $orgId) {
      id name
    }
  }
`

// export const DELETE_ORG = gql`
//   mutation DeleterOrg($id)
// `

