import React from "react";
import {Col, Row, Spin} from "antd";
import {toTitleCase} from "../../service/string";
import {DisplayJoiField} from "../Joi/DisplayJoiField";
import {WebComponentWrapper} from "../../containers/Extensions/WebComponentWrapper";
import {filterMetaFields} from "../../graphql/queries/generic";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {MetaTypeDisplay} from "../Joi/MetaTypeDisplay";
import {useReactiveVar} from "@apollo/client";

interface EventityItem {
  id: string
  schema: any
  item: any
  type: string
}

export const EventityItem: React.FC<EventityItem> = ({id, item, schema, type, ...props}) => {

  const workspaceId = useReactiveVar(currentWorkspaceId)

  const pieces = {type, data: item, schema}

  if (item.id) {
    return <><WebComponentWrapper schema={schema} viewName={"view"} workspaceId={workspaceId} context={pieces}>
      {filterMetaFields(Object.keys(schema)).map((key) => {
        const itemSchema = schema[key]
        return <WebComponentWrapper workspaceId={workspaceId} schema={itemSchema} viewName={""} key={`wrapper-${key}`}>
          <Row key={`${key}${item.id}`} className={"ant-row ant-form-item"}>
            <MetaTypeDisplay name={key} schema={schema[key]} data={item[key]}>
              <Col xs={6} className={"ant-form-item-label"}>
                <label>{toTitleCase(key)}</label>
              </Col>
              <Col flex={"auto"} style={{padding: '5px 12px'}}>
                <DisplayJoiField schema={schema[key]} data={item[key]}/>
              </Col>
            </MetaTypeDisplay>
          </Row>
        </WebComponentWrapper>
      })}
      <Row style={{marginBottom: '24px'}}>
        <Col offset={6} flex={"auto"}>{props.children}</Col>
      </Row>
    </WebComponentWrapper></>
  } else {
    return <div><Spin /></div>
  }
}
