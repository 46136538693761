import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {Button, message} from "antd";

interface I extends React.HTMLAttributes<HTMLDivElement> {
  id: string
}

const CopyID: React.FC<I> = ({id}) => {
  return <CopyToClipboard text={id} onCopy={() => message.info("Workspace ID copied!")}><Button>Copy Workspace ID</Button></CopyToClipboard>
}

export default CopyID
