import React from "react";

import CopyToClipboard from "react-copy-to-clipboard";
import {Button, message} from "antd";

interface CopyAPIKey extends React.HTMLAttributes<HTMLDivElement> {
  actionData?: any
  workspaceId: string
}

const CopyAPIKey: React.FC<CopyAPIKey> = ({actionData}) => <CopyToClipboard text={`${actionData.id}`} onCopy={() => message.info("API Key copied to clipboard")}><Button>Copy API Key</Button></CopyToClipboard>

export default CopyAPIKey
