import React from "react";
import {metaTypeMap, typeFormat} from "../Form/tools";

interface DisplayJoiField {
  schema: any
  data: any
}

export const DisplayJoiField: React.FC<DisplayJoiField> = ({schema, data, ...props}) => {
  // @ts-ignore
  if(metaTypeMap.has(schema["@type"])) {
    // @ts-ignore
    const Type = metaTypeMap.get(schema["@type"]).output || undefined
    if(!!Type) {
      return <><Type {...props} {...{data, schema}} /></>
    }
  }
  if(typeFormat.has(schema["@type"])) {
    const {display} = typeFormat.get(schema["@type"]) as any
    if(!!display) {
      return (<>{display(data)}</>)
    }
  }

  return (<>{data}</>)
}
