import React, {useEffect, useState} from "react";
import {JoiSchema, JoiTypeDef} from "../../../../service/joi";
import {Col, InputNumber, Row, Switch} from "antd";
import Title from "antd/lib/typography/Title";
import {toTitleCase} from "../../../../service/string";
import {TypeList} from "../../../../components/Eventity/TypeList";
import {metaTypeMap} from "../../../../components/Form/tools";

interface Changes {
  path: string[],
  data?: any | undefined
}

interface I {
  node: string[]
  schema: JoiSchema | JoiTypeDef
  root: any
  onChange(data: Changes[]): void
}

const NodeData: React.FC<I> = ({node= [], schema, onChange, root}) => {
  const [titleAble, setTitleAble] = useState(false)
  const [name, setName] = useState("")
  const [isTitle, setIsTitle] = useState(false)
  const [type, setType] = useState("")
  const [metatype, setmetaType] = useState<any>({})
  const [meta, setMeta] = useState<any>({})
  const [disableTypeList, setDisableTypeList] = useState<boolean>(false)

  useEffect(() => {
    let mt: any = {}
    let mta: any = {}
    if(!!schema) {
      if(schema["@type"] && ["string", "number"].includes(`${schema["@type"]}`)) {
        setTitleAble(true)
      } else {
        setTitleAble(false)
      }

      if(schema["@type"] && schema["@type"] === "object") {
        const hasChildren = Object.keys(schema).filter((key) => key.charAt(0) !== "@" && key !== "min" && key !== "max")
        if(hasChildren.length > 0) {
          setDisableTypeList(true)
        } else {
          setDisableTypeList(false)
        }
      } else {
        setDisableTypeList(false)
      }


      let t: string = `${schema["@type"]}` || ""

      if(schema.hasOwnProperty("@meta")) {
        mta = schema["@meta"]
        if(!!mta && mta.hasOwnProperty("function") && mta.function.hasOwnProperty("metatype")) {
          t = mta.function.metatype
        }
      }

      if(metaTypeMap.has(t)) {
        mt = metaTypeMap.get(t)
      }

      setType(t)

      if(root.hasOwnProperty("@meta")) {
        if(root["@meta"].hasOwnProperty("titleField") && root["@meta"].titleField === node.join("/")) {
          setIsTitle(true)
        } else {
          setIsTitle(false)
        }
      }

    } else {
      setTitleAble(false)
    }

    setmetaType(mt)
    setMeta(mta)
  }, [schema, root])

  useEffect(() => {

  }, [schema, root])

  useEffect(() => {
    const n = [...node].pop()
    setName(n || "LOADING")
  }, [node])

  const setField = (name: string, data: any) => {
    onChange([{path: [...node, name], data}])
  }


  return <>
    <Title level={4}>{toTitleCase(name)}</Title>
    {titleAble && <Row>
      <Col xs={6}>
        Use as List Title
      </Col>
      <Col>
        <Switch onClick={() => {
          if(isTitle) {
            onChange([{path: ["@meta", "titleField"], data: undefined}])
          } else {
            onChange([{path: ["@meta"], data: {titleField: node.join("/")}}])
          }
        }} checked={isTitle} />
      </Col>
    </Row>}
    <Row>
      <Col>
        <TypeList disabled={disableTypeList} selected={type} setSelection={(s) => {

          const changes: Changes[] = [

          ]

          const {type = "any", isMetaType = false} = metaTypeMap.get(s) as any
          if(isMetaType) {
            changes.push({path: [...node, "@meta"], data: {function: {metatype: s}}})
            changes.push({path: [...node, "@type"], data: type})
          } else {
            changes.push({path: [...node, "@type"], data: s})
            if(schema.hasOwnProperty("@meta")) {
              changes.push({path: [...node, "@meta", "function"]})
            }
          }

          onChange(changes)
        }} />
      </Col>
    </Row>

    {!!metatype.allowRequired && <Row>
      <Col xs={6}>Required Field</Col>

      <Col>
        <Switch checked={!!schema["@required"] || false} onChange={(val) => setField("@required", val) } />
      </Col>
    </Row>}

    {!!metatype.hasMinMax && <Row>

      <Col>
        <InputNumber onChange={(val) => {
          if(!!val && val >= 0) {
            setField("min", val)
          }
        }} value={Number(schema.min) || 0} name={"min"} id={"min"} style={{width: "4rem"}} placeholder={"Min"} />
        <InputNumber onChange={(val) => {
          if(!!val && val >= 0) {
            setField("max", val)
          }
        }} value={Number(schema.max) || 0} name={"max"} id={"max"} style={{width: "4rem"}} placeholder={"Max"} />
      </Col>
    </Row>}

    {!!metatype.allowRequired && <Row>
      <Col xs={6}>Read Only</Col>
      <Col>
        <Switch checked={!!meta.readOnly || false} onChange={(val) => onChange([{path: [...node, "@meta"], data: {readOnly: val}}])} />
      </Col>
    </Row>}

    {!!metatype.allowRequired && <Row>
      <Col xs={6}>Prevent Deletion</Col>
      <Col>
        <Switch checked={!!meta.noDelete || false} onChange={(val) => onChange([{path: [...node, "@meta"], data: {noDelete: val}}])} />
      </Col>
    </Row>}

  </>
}

export default NodeData
