import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {Button, message} from "antd";

interface CopyKey extends React.HTMLAttributes<HTMLDivElement> {
  actionData?: any
}

const CopyKey: React.FC<CopyKey> = ({actionData}) => {
  return <CopyToClipboard text={actionData.id} onCopy={() => message.info("Encryption Key ID copied to clipboard!")}><Button>Copy Key ID</Button></CopyToClipboard>
}

export default CopyKey
