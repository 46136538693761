import React, {useEffect, useState} from "react";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {DELETE_WORKSPACE} from "../../service/workspace";
import {READ_WORKSPACES_PAGE} from "../../service/workspace";
import {EntityList} from "../../components/EntityList";
import {CreateWorkspace} from "./CreateWorkspace";
import {DEFAULT_LENGTH, formPaginatedResponse, pageSizeOptions} from "../../service/pagination";
import RemoveWorkspace from "./Components/RemoveWorkspace";
import FilterTools from "../../components/Common/FilterTools";

interface WorkspacesList {
  orgId: string
}

export const WorkspacesList: React.FC<WorkspacesList> = ({orgId, ...props}) => {

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_LENGTH)

  const [filter, setFilter] = useState<any[]>([])

  const {
    data: {Organisation: org} = {
      Organisation: {
        id: '',
        workspaces: formPaginatedResponse({}),
      }
    },
    loading,
    fetchMore,
    refetch,
  } = useQuery(READ_WORKSPACES_PAGE, {variables: {id: orgId, limit: pageSize, page, filter, hasId: !!orgId}})

  useEffect(() => {
    if (!!orgId) {
      setPage(1)
    }
  }, [orgId])

  useEffect(() => {
    if (!!orgId) {
      fetchMore({variables: {page}})
    }
  }, [page, orgId])

  const [deleteWorkspace, {loading: deleteLoading}] = useMutation(
    DELETE_WORKSPACE,
    {
      // refetchQueries:[{
      //   query: READ_WORKSPACES_PAGE,
      //   variables: {id: orgId}
      // }],
      onError: (e) => console.log(e),
      onCompleted: () => {
        refetch()
      }
    }
  )

  return (<>
      <FilterTools fields={["name"]} titleField={"name"} onFilter={(f) => {
        console.log("SETTING", f)
        setFilter(f)
      }}/>
      <EntityList
        list={org.workspaces.entries || []}
        actions={[<RemoveWorkspace onRemove={async (workspaceId: string) => deleteWorkspace({
          variables: {
            input: {
              workspaceId,
              organisationId: orgId
            }
          }
        })}/>]}
        loading={loading || deleteLoading}
        pagination={{
          defaultPageSize: pageSize,
          total: org.workspaces.totalCount || 0,
          showSizeChanger: true,
          pageSizeOptions,
          onChange: (p, ps) => {
            setPage(p)
            if (!!ps) {
              setPageSize(ps)
            }
          }
        }}
      >
        <CreateWorkspace orgId={orgId} onComplete={() => {
          refetch()
        }}/>
      </EntityList>
    </>
  )
}

