import React from "react";
import {Col, Form, Input, Row, Button} from "antd";
import {useForm} from "antd/lib/form/Form";

interface I {
  register(a: {variables: {email: string, name: string, password: string}}): void
}

const RegisterForm: React.FC<I> = ({register, ...props}) => {

  const [regForm] = useForm()

  const onFinish = ({email, password, name}: any) => {
    register({variables: {email, password, name}})
  }
  return (
    <>
      <Row justify={"center"}>
        <Col xs={"auto"} flex={"15rem"}>
          <Form
            onFinish={onFinish}
            form={regForm}
            validateTrigger={"onBlur"}
          >
            <Form.Item
              name={"email"}
              rules={[
                {required: true},
                {type: "email"},
              ]}
            >
              <Input placeholder={"Email Address"} />
            </Form.Item>
            <Form.Item
              name={"password"}
              rules={[
                {required: true},
                {min: 8, message: "Your password must be at least eight characters long."}
                ]}
            >
              <Input.Password placeholder={"Password"} />
            </Form.Item>
            <Form.Item
              name={"name"}
              rules={[{required: true}]}
            >
              <Input placeholder={"Name"} />
            </Form.Item>
            <Form.Item>
              <div className={"form-element space-between"}>
                <Button htmlType={"submit"} type={"primary"}>Register</Button>
                <Button onClick={() => {
                  regForm.resetFields()
                }}>Reset</Button>
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default RegisterForm
