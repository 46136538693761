import React, {useState} from "react";
import {useHistory, useParams} from "react-router";
import {Button, Card, Col, message, notification, Row, Space} from "antd";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {READ_EVENTITY, UPDATE_WORKSPACE_ENTITY} from "../../graphql/queries/eventity";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import Schema from "./Components/Schema";
import {clearEventityCache} from "../../service/eventity";
import {ContentHeader} from "../layouts/style";
import {currentOrgID} from "../../graphql/type-policies/orgs";

interface I {}

const Edit: React.FC<I> = () => {
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const organisationId = useReactiveVar(currentOrgID)
  const {eventityName} = useParams<any>()
  const {push} = useHistory()
  const [schema, setSchema] = useState<any>({})
  const {data: {GetWorkspaceEntity: {id, name, schema: origSchema}} = {GetWorkspaceEntity: {id: "", name: "", schema: {}}}, loading} = useQuery<{GetWorkspaceEntity: { id: string, name: string, schema: any }}>(
    READ_EVENTITY,
    {
      variables: {
        name: eventityName,
        workspaceId,
      },
      onCompleted(data) {
        if(!!data && !!data.GetWorkspaceEntity) {
          const {GetWorkspaceEntity: {id, schema: s}} = data
          setSchema(s)
        }
      }
    }
  )

  const [updateEventity, {loading: updateLoading}] = useMutation(
    UPDATE_WORKSPACE_ENTITY,
    {
      onCompleted(data) {
        clearEventityCache()
        notification.success({message: "Eventity Updated!", description: <>"{name}" has been updated</>})
      },
      onError(e) {
        notification.error({message: "An error occured", description: e.message})
      }
    }
  )

  return <>

    <ContentHeader
      title={"Edit Eventity Schema"}
      onBack={() => push(`/dashboard/organisation/${organisationId}/workspace/${workspaceId}`)}
    />
    <Card
      title={eventityName}
    >
      <Schema
        schema={schema}
        onChange={(a) => {
          setSchema(a)
        }}
      />

      <Row>
        <Col flex={"100%"}>
          <Space>
            <Button loading={updateLoading} type={"primary"} onClick={() => {
              if(Object.keys(schema).filter((key) => key.charAt(0) !== "@" && key !== "min" && key !== "max" && !!schema[key]).length > 0) {
                updateEventity({
                  variables: {
                    input: {
                      workspaceId,
                      name: eventityName,
                      schema: JSON.stringify(schema),
                      id,
                    }
                  }
                })
              } else {
                message.error("A schema must have at least one field.")
              }
            }}>Save Schema</Button>
            <Button onClick={() => setSchema(origSchema)}>Reset</Button>
          </Space>
        </Col>
      </Row>

    </Card>
  </>
}

export default Edit
