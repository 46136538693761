import {gql} from "@apollo/client";

export interface SITE_USER {
  readonly email: string
  readonly id: string
  readonly name: string
}

export const READ_ME = gql`
  query me {
    me {
      email id name
    }
  }
`
