import {makeVar} from "@apollo/client";

export const currentWorkspaceId = makeVar('');
export const currentWorkspaceWebComponents = makeVar([] as any);
export const currentWorkspaceViews = makeVar({} as any)
export const workspaceFields = {
  WorkspaceID: {
    read() {
      return currentWorkspaceId();
    }
  },
  workspaceWebComponents: {
    read() {
      return currentWorkspaceWebComponents()
    }
  },
  workspaceViews: {
    read() {
      return currentWorkspaceViews()
    }
  }
}
