import React, {useEffect} from "react";
import {DEFAULT_TO} from "../../service/const";
import {useReactiveVar} from "@apollo/client";
import {useHistory} from "react-router";
import {AUTH_STATE, authState} from "../../graphql/type-policies/auth";

interface PreAuthOnly {}

export const PreAuthOnly = (Component: any, to = DEFAULT_TO) => {
  const C: React.FC<PreAuthOnly> = (props) => {
    const currentAuthState = useReactiveVar(authState)

    const loggedIn = (): boolean => {
      return currentAuthState === AUTH_STATE.LOGGED_IN
    }

    const history = useHistory();
    useEffect(() => {
      if(loggedIn()) {
        history.push(to);
      }
    }, [currentAuthState, history]);

    if (!loggedIn()) {
      return <Component {...props}/>
    } else {
      return <></>
    }
  }

  return C;
}
