import React from "react";
import {FormItem} from "./FormItem";
import {metaTypeMap} from "./tools";

interface MetaTypeInput extends FormItem {
  type: string
  onUpdate?(value: any): void
}

export const MetaTypeInput: React.FC<MetaTypeInput> = ({type, ...props}) => {
  if(metaTypeMap.has(type)) {
    const Type = (metaTypeMap.get(type) as any).input
    // @ts-ignore
    return (<Type  {...props} key={JSON.stringify(props)}/>)
  } else {
    return (<>{props.children}</>)
  }
}

