import React, {useState} from "react";
import {Button, Card, Col, message, Result, Row, Steps} from "antd";
import Title from "./Components/SetTitle";
import Schema from "./Components/Schema";
import Commands from "./Components/Commands";
import Actions from "./Components/Actions";
import {RouteComponentProps, useHistory} from "react-router";
import Review from "./Components/Review";
import {useMutation, useReactiveVar} from "@apollo/client";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {CREATE_WORKSPACE_ENTITY} from "../../graphql/queries/eventity";
import {ContentHeader} from "../layouts/style";
import {currentOrgID} from "../../graphql/type-policies/orgs";

const {Step} = Steps

interface I extends RouteComponentProps {}

const Design: React.FC<I> = () => {
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const organisationId = useReactiveVar(currentOrgID)
  const steps: number = 5
  const [currentStep, setCurrentStep] = useState(0)
  const [title, setTitle] = useState("")
  const [schema, setSchema] = useState<any>({})

  const {push} = useHistory()

  const reset = () => {
    setTitle("")
    setSchema({})
    setCurrentStep(0)
  }

  const [createEntity, {client: {cache}}] = useMutation(
    CREATE_WORKSPACE_ENTITY,
    {
      onCompleted() {
        cache.evict({id: `Workspace:${workspaceId}`, fieldName: "entities"})
      }
    }
  )

  return <>
    <ContentHeader
      title={"Design Eventity Schema"}
      onBack={() => push(`/dashboard/organisation/${organisationId}/workspace/${workspaceId}`)}
    />

    <Card
      title={title || "UntitledSchema"}
    >
      <Row>
        <Col xs={4}>
          <Steps current={currentStep} direction={"vertical"}>
            <Step title={"Schema Title"}/>
            <Step title={"Schema Design"}/>
            <Step title={"Commands and Queries"} description={"Optional"}/>
            <Step title={"Review and Complete"}/>
            <Step title={"Done!"}/>
          </Steps>
        </Col>
        <Col xs={16}>
          {currentStep === 0 && <Title onComplete={(name) => {
            setTitle(name)
            // setCurrentStep(1)
          }}><Actions
            position={currentStep}
            setPosition={setCurrentStep}
            steps={steps}
          /></Title>}
          {currentStep === 1 && <Schema
            schema={schema}
            onChange={(a) => {
              setSchema(a)
            }}
            onComplete={() => setCurrentStep(2)}
          >
            <Actions position={currentStep} setPosition={setCurrentStep} steps={steps} validate={() => {
              if (Object.keys(schema).filter((key) => key.charAt(0) != "@").length > 0) {
                return true
              } else {
                message.error("A schema cannot be empty!")
                return false
              }
            }}/>
          </Schema>}
          {currentStep === 2 && <Commands>
            <Actions position={currentStep} setPosition={setCurrentStep} steps={steps}/>
          </Commands>}
          {currentStep === 3 && <>
            <Review schema={schema} title={title}>
              <Button onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
              <Button onClick={() => {
                createEntity({
                  variables: {
                    input: {
                      name: title,
                      schema: JSON.stringify(schema),
                      workspaceId,
                    }
                  }
                })
                  .then((e) => {
                    setCurrentStep(4)
                  })
                  .catch((e) => {
                    message.error("Failed")
                    console.log(e)
                  })
              }} type={"primary"}>Save Eventity Schema</Button>
            </Review>
          </>}
          {currentStep === 4 && <Result
            status={"success"}
            title={<>You have successfully created the {title} eventity.</>}
            extra={[
              // <Button type={"primary"} onClick={() => {
              //   const np = path.resolve(match.url, "..", "workspaceentity", title)
              //   push(np)
              // }}>View Eventity</Button>,
              <><Button type={"primary"} onClick={() => {
                push(`/dashboard/organisation/${organisationId}/workspace/${workspaceId}`)
              }}>Go to Workspace</Button> <Button onClick={() => void reset()}>Create Another</Button></>
            ]}
          />
          }
        </Col>
      </Row>

    </Card>
  </>
}

export default Design
