import React, {useEffect, useState} from "react";
import {useQuery, useReactiveVar} from "@apollo/client";
import {GET_WORKSPACE_ENTITIES} from "../../graphql/queries/workspace";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {formPaginatedResponse} from "../../service/pagination";
import {IWorkspaceEntity} from "../../service/workspace";
import {Button, List, message, Radio, Select} from "antd";
import {toTitleCase} from "../../service/string";

interface EventityLinkFunction {
  name: string,
  updateFields(data: { schema?: any, metadata?: any }): void
  required: boolean
  data: any
}

const items = {
  linkName: {"@type": "string"},
  linkId: {"@type": "string"},
}

export const EventityLinkFunction: React.FC<EventityLinkFunction> = ({name, updateFields, data}) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [sort, setSort] = useState([])
  const [titleField, setNameField] = useState("")
  const [selected, setSelected] = useState<string | undefined>(undefined)
  const workspaceId = useReactiveVar(currentWorkspaceId)

  const {data: {workspace: {entities: {entries, totalCount, pageInfo}}} = {
    workspace: {entities: formPaginatedResponse<IWorkspaceEntity>({})}
  }} = useQuery(
    GET_WORKSPACE_ENTITIES,
    {
      variables: {page, limit, sort, id: workspaceId}
    }
  )

  useEffect(() => {
    if(data && data.eventity && data.eventity.name && entries.length > 0) {
      if(!selected) {
        setSelected(data.eventity.name)
        setNameField(data.eventity.titleField)
      } else if (selected) {
        setNameField(data.eventity.titleField)
      }
    }
  }, [data, entries, selected])

  useEffect(() => {
    if(titleField) {
      selectEventity(selected)
    }
  }, [titleField])

  const selectEventity = (name: string | undefined) => {
    setSelected(name)
    if(name) {
      const eventity = entries.find((ev) => ev.name === name)
      if(eventity) {
        updateFields({metadata: {function: {eventity: {name: eventity.name, owner: eventity.owner, titleField}}}})
      } else {
        message.error("Could not select Eventity")
      }
    } else {
      updateFields({metadata: {function: {eventity: undefined}}})
    }
  }

  return <>
    <List
      pagination={{
        pageSize: limit,
        total: totalCount,
        onChange: (p, ps) => {
          setPage(p)
        }
      }}
      dataSource={entries}
      renderItem={(item: any) => {
        const opts = Object.keys(item.schema)
          .filter((key) => key.charAt(0) !== "@" && key !== "min" && key !== "max" && item.schema[key]["@type"] === "string")
          .map((key) =>({value: key, label: toTitleCase(key)}))
        let select = <></>
        if(item.name === selected) {
          select = <Select
            key={`select-${item.id}`}
            options={opts}
            style={{minWidth: "8rem"}}
            placeholder={"Select Title Field"}
            onChange={(val) => {
              setNameField(val)
            }}
            defaultValue={titleField}
          />
        }
        return <List.Item
          actions={[
            select,
            <Radio checked={item.name === selected} onClick={() => {
              setNameField(undefined)
              selectEventity(item.name)
            }} />
          ]}
        >
          {item.name}
        </List.Item>
      }}
      footer={<Button onClick={()=> void selectEventity(undefined)}>Clear Selection</Button>}
    />
  </>
}
