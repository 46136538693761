import React from "react";
import {ViewUser} from "../../components/Users/ViewUser";
import {useQuery} from "@apollo/client";
import {READ_ORG_USER} from "../../graphql/queries/org";
import {useParams} from "react-router";
import {currentOrgID} from "../../graphql/type-policies/orgs";

interface User {}

export const User: React.FC<User> = ({...props}) => {
  const orgId = currentOrgID()
  const {userId} = useParams<{userId: string}>()
  const {data = {ReadOrganisationAccount: {name: ""}}} = useQuery(
    READ_ORG_USER,
    {
      variables: {
        accountId: userId,
        orgId,
      }
    }
  ) as any

  return (
    <>
      <ViewUser user={data.ReadOrganisationAccount}/>
    </>
  )
}
