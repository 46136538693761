import React, {useEffect} from "react";
import {Button, Form, Space} from "antd";
import {useForm} from "antd/lib/form/Form";
import {JoiSchema} from "../../service/joi";
import {typeFormat} from "../Form/tools";
import FieldFromType from "./FieldFromType";
import {toSnakeCase} from "../../service/string";

interface IGenerateFromJoi {
  schema: JoiSchema
  data?: any
  excludeTypes?: string[]
  excludeKeys?: string[]
  execute(variables: any): void
  reset?(): void
  cancel?(): void
  executeName?: string
  disabled?: boolean
  hideLabels?: boolean
  name?: string

  onFieldsChange?: (fields: any) => void
}

const genInitialValues = (schema: any, data: any, prefix?: string) => {
  let initialValues = {} as any
  const keys = Object.keys(schema).filter((key) => key.charAt(0) !== "@")
  keys.forEach((key) => {
    const item = schema[key]
    let node = key
    if(!!prefix) {
      node = `${prefix}_${key}`;
    }

    if(data && data.hasOwnProperty(key)) {
      if(item["@type"] === "object") {
        initialValues[key] = genInitialValues(item, data[key])
      } else {
        initialValues[key] = data[key]
        if (typeFormat.has(item["@type"])) {
          const {in: format} = typeFormat.get(item["@type"]) as any
          if (format) {
            initialValues[node] = format(data[key])
          }
        }
      }
    } else {
      if (item['@type'] === 'array') {
        initialValues[key] = Array(item.min || 0).fill("")
      }
    }
  })

  return initialValues
}



export const GenerateFromJoi: React.FC<IGenerateFromJoi> = ({schema, data, hideLabels= false, excludeTypes = [], excludeKeys= [], execute, executeName, reset, cancel, name, onFieldsChange = (fields: any) => void(0) }) => {
  const [form] = useForm()

  useEffect(() => {
    if(!!data) {
      const iv = genInitialValues(schema, data)
      form.setFieldsValue(iv)
    }
  }, [schema, data])

  const layout = {
    labelCol: { span: 6 },
    // wrapperCol: { span:  },
  };
  const tailLayout = {
    wrapperCol: { offset: 6},
  };

  const fieldKeys = Object.keys(schema)
    .filter((key) => key.charAt(0) !== "@")

  const constructRequest = (fields: any, schema: any) => {
    let dataObject: any = {}
    Object.keys(schema)
      .filter((key) => !excludeKeys.includes(key) && key.charAt(0) !== "@")
      .forEach((key) => {

        if(schema[key]["@type"] === "object") {
          dataObject[key] = constructRequest(fields[key], schema[key])
        } else {
          let value = fields[key]
          if(typeFormat.has(schema[key]["@type"])) {
            const {out} = typeFormat.get(schema[key]["@type"]) as any
            if(out) {
              value = out(value)
            }
          }
          dataObject[key] = value
        }
      })
    return dataObject
  }

  return (<>

    <Form
      form={form}
      name={name || (!! executeName && toSnakeCase(executeName)) || ""}
      onFieldsChange={(a, b) => {
        console.log("CHANGE", a, b)
      }}
      onValuesChange={(a) => {
        console.log("VALUES CHANGE", a)
      }}
      onChange={(e) => {
        console.log("BIG CHANGE", (e.target as any).value)
        onFieldsChange(form.getFieldsValue())
      }}
      {...layout}
      onFinish={(values) => {
        const output = constructRequest(values, schema)
        execute(output)
      }}
    >
    {fieldKeys
      .filter((key) => !excludeKeys.includes(key))
      .map((key) => {
        const fieldData: any = schema[key];
        return <FieldFromType name={key} data={fieldData} type={fieldData["@type"]} key={key} hideLabel={hideLabels} />
    })}
    <Form.Item {...tailLayout}>
      <Space direction={"horizontal"}>
        <Button type={"primary"} htmlType={"submit"}>{executeName || "Execute"}</Button>
        {!!cancel && <Button type={"primary"} onClick={() => {
          form.resetFields()
          cancel()
        }}>Cancel</Button>}
      </Space>
    </Form.Item>
    </Form>
  </>)

}
