import gql from "graphql-tag";
import {DEFAULT_LISTLEN} from "./const";
import {QueryResult} from "@apollo/client";
import {PaginatedResponse} from "./pagination";
import {WORKSPACE_ENTITIES_FRAGMENT} from "../graphql/queries/workspace";

export interface CurrentWorkspaceId {
  readonly data: {
    readonly WorkspaceID: string,
  }
}

export const CURRENT_WORKSPACE_ID = gql`
  query currentWorkspaceId {
    WorkspaceID @client
  }
`

export const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($input: CreateWorkspaceInput!) {
    CreateWorkspace(input: $input) {
      id
    }
  }
`

export const DELETE_WORKSPACE = gql`
  mutation DeleteWorkspace($input: DeleteWorkspaceInput!) {
    DeleteWorkspace(input: $input) {
      id
    }
  }
`

export const READ_WORKSPACES_PAGE = gql`
  query readWSList($id: String!, $page: Int = 0, $limit: Int = ${DEFAULT_LISTLEN} $sort: [Sort!] $filter: [JSON!] $hasId: Boolean!) {
    Organisation(id: $id) @include(if: $hasId) {
      id
      workspaces(limit: $limit, page: $page sort: $sort filter: $filter) {
        totalCount
        entries {name id}
      }
    }
  }
`

export interface IWorkspaceData extends QueryResult {
  readonly data: {
    readonly workspace: IWorkspace
  }
}

export interface IWorkspace {
  readonly __typename: string
  readonly id: string
  readonly name: string
  readonly entities: IWorkspaceEntity[]
  readonly commands: IWorkspaceCommand[]
}

export const GET_WORKSPACE = gql`
  ${WORKSPACE_ENTITIES_FRAGMENT}
  query GetWorkspace($id: String! $page: Int, $limit: Int $sort: [Sort!] $filter: [JSON!] $hasId: Boolean!) {
    workspace(id: $id) @include(if: $hasId)  {
      development_enabled
      id name commands {
        handler inputSchema outputSchema name
      }
      entities(limit: $limit, page: $page sort: $sort filter: $filter) {
        ...WorkspaceEntities
      }
    }
  }
`

export interface IWorkspaceEntity {
  readonly __typename?: string
  readonly id?: string
  readonly name: string
  readonly workspaceId?: string
  readonly commands: IWorkspaceCommand[]
  readonly schema: any
  readonly owner: string
}

export const READ_ENTITIES_PAGE = gql`
  query ReadEntitiesPage($id: String!, $page: Int, $limit: Int $sort: [Sort!] $filter: [JSON!] ) {
    workspace(id: $id) {
      id
      entities(limit: $limit, page: $page sort: $sort filter: $filter) {
        totalCount
        entries {
          id name schema
          commands {
            handler inputSchema outputSchema name
          }
          queries {
            handler inputSchema outputSchema name
          }
        }
      }
    }
  }
`

// export const READ_ENTITY = gql`
//   query ReadEntity($workspaceId: String!, $entityId: String!) {
//     workspace(id: $workspaceId) {
//       id
//       entities()
//     }
//   }
// `

// export const CREATE_WORKSPACE_COMMAND = gql`
//   mutation CreateWorkspaceCommand($input: CreateWorkspaceEntityInput!) {
//     Create(input: $input) {
//       id
//     }
//   }
// `

export interface IEntityCommand {
  readonly name: string
  readonly handler: string
  readonly inputSchema: string
  readonly outputSchema: string
}

export const READ_COMMANDS_PAGE = gql`
  query ReadCommandsPage($id: String!, $start: Int = 0, $limit: Int = ${DEFAULT_LISTLEN}) {
    workspace(id: $id) {
      id
      commands(limit: $limit, start: $start) {
        handler inputSchema outputSchema name
      }
    }
  }
`

export interface IWorkspaceCommand {
  readonly handler: string
  readonly inputSchema: any
  readonly name: string
  readonly outputSchema: any
  readonly [key: string]: any // this is cheeky
}

export const initCommand: IWorkspaceCommand = {
  title: '',
  handler: '',
  inputSchema: '{}',
  outputSchema: '{}',
  name: '',
}

export interface WorkspaceEntity {
  commands: Array<IWorkspaceCommand>
  name: string
  workspaceId: string
  schema: string
}

export interface WorkspaceEntityResponse {
  CreateWorkspaceEntity: {
    id: string
  }
}

export interface Workspace {
  commands: Array<IWorkspaceCommand>
  entities: PaginatedResponse<IWorkspaceEntity>
  creationDate?: any
  id: string
  name: string
  development_enabled: boolean
}

export type WorkspaceData = {
  workspace: Workspace
}

export const initWorkspace: IWorkspace = {
  __typename: '',
  commands: [],
  entities: [],
  id: "",
  name: "",
}
