import React from "react";
import {UserAvatarMenu} from "../../components/User/UserAvatarMenu";
import {useQuery} from "@apollo/client";
import {IS_LOGGED_IN} from "../../graphql/queries/auth";
import {READ_ME} from "../../graphql/queries/me";
import {Spin} from "antd";

interface UserHeaderMenu {}

export const UserHeaderMenu: React.FC<UserHeaderMenu> = () => {
  const {data} = useQuery(IS_LOGGED_IN);
  const {data: {me} = {me: {email: "", id: "", name: ""}}, loading} = useQuery(READ_ME)

  return ((data && data.isLoggedIn && <Spin spinning={loading}><UserAvatarMenu user={me} /></Spin>) || <></>)
}
