import React from "react";
import {Button} from "antd";
import {useMutation} from "@apollo/client";
import {REMOVE_EXTENSION_FROM_WORKSPACE} from "../../../graphql/queries/extensions";

interface RemoveExtension {
  id: string
  workspaceId: string
  onComplete(): void
}

const RemoveExtension: React.FC<RemoveExtension> = ({id, workspaceId, onComplete}) => {

  const [removeExtension, {loading}] = useMutation(
    REMOVE_EXTENSION_FROM_WORKSPACE,
  )

  return <Button
    loading={loading}
    danger
    ghost
    onClick={() => removeExtension({
      variables: {
        id
      }
    })
      .then(() => onComplete())

    }
  >Remove</Button>
}

export default RemoveExtension
