import React, {useState} from "react";
import {Button, Card, Form, Input, Space, Switch} from "antd";

interface I {
  path: string
  onChange(path: string): void
  onReset(): void
}

const ConfigureRemoteSchema: React.FC<I> = ({path, onChange, onReset}) => {
  const [edit, setEdit] = useState<boolean>(false)

  return <>
    <Card
      title={<h4 style={{display: "flex"}}><span>Remote Schema</span>
        <Switch onChange={(val) => setEdit(val)}
                checkedChildren={"Edit"}
                unCheckedChildren={"Edit"}
                checked={edit}
                style={{marginLeft: "auto"}}
        />
      </h4>}
    >

      {edit && <>

        <Form.Item>

          <Input
            defaultValue={path}
            value={path}
            onChange={(e) => {
              onChange(e.target.value)
            }}
          />

        </Form.Item>
        <Form.Item>

          <Space direction={"horizontal"}>
            <Button
              type={"primary"}
              onClick={() => {
                setEdit(false)
              }}>Save</Button>
            <Button
              onClick={() => {
                setEdit(false)
                onReset()
              }}>Cancel</Button>
          </Space>

        </Form.Item>
      </>}
      {!edit && <>
        <p>{path}</p>
      </>}
    </Card>
  </>

}

export default ConfigureRemoteSchema
