import {gql} from "@apollo/client";

export const contentQuery = (name: string, fields?: string[]) => {
  if(!fields || fields.length === 0) {
    fields = ['id']
  }
  const queryText = `query content${name}($page: Int, $limit: Int $cursor: String $sort: [Sort!] $filter: [JSONObject!]){
    content {
      ${name} {
        search(input: {page: $page limit: $limit cursor: $cursor sort: $sort filter: $filter}) {
          totalCount pageInfo
          entries {
            ${filterMetaFields(fields).join(' ')}
          }
        }
      }
    }
  }`
  try {
    return gql`${queryText}`
  } catch (e) {
    console.error(e)
    console.log(queryText)
  }
}

export const eventityItemQuery = (name: string, fields?: string[], history?: boolean) => {
  if(!fields || fields.length === 0) {
    fields = ['id']
  }
  const queryText = `query content${name}($id: String!){
    content {
      ${name} {
        node(id: $id) {
          ${filterMetaFields(fields).join(' ')}
          ${history?"history {data, date, domainId, id, type}":""}
        }
      }
    }
  }`
  return gql`${queryText}`
}

export const commandQuery = (name: string, fields?: string[]) => {
  if(!fields || fields.length === 0) {
    fields = ['id']
  }

  const queryText = `query ${name}($page: Int, $limit: Int $cursor: String $sort: Sort $filter: [JSONObject!]) {
    ${name}(page: $page limit: $limit cursor: $cursor sort: $sort filter: $filter) {
      totalCount
      entries {
        ${filterMetaFields(fields).join(' ')}
      }
    }
  }`

  return gql`${queryText}`
}

export const filterMetaFields = (fields: string[]): string[] => {
  return fields.filter((field) => field.charAt(0) !== "@")
}

export const commandQueryWithParams = (name: string, params?: any, fields?: string[]) => {
  if(!fields || fields.length === 0) {
    fields = ['id']
  }
  const paramVars = "";
  const queryText = `query ${name}() {

  }`
}

export const paramsFromJoi = (schema: any) => {
  return filterMetaFields(Object.keys(schema))
}

export const commandMutation = (name: string, fields: string[]) => {
  if (!fields || fields.length === 0) {
    fields = ['id']
  }
  const mutationText = `mutation ${name}($input: ${name}Input!) {
    ${name}(input: $input) {
      ${filterMetaFields(fields).join(' ')}
    }
  }`

  return gql`${mutationText}`
}

export const truncateEventity = (name: string) => {
  const command = `Truncate${name}`

  return gql`
    mutation truncate {
      ${command} {
        success message
      }
    }
  `
}
