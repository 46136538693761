import {makeVar} from "@apollo/client";

export const currentOrgID = makeVar('');

export const orgFields = {
  OrgID: {
    read() {
      return currentOrgID();
    }
  }
}
