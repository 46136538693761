import React from "react";
import {Helmet} from "react-helmet";
import {EVFront} from "./style";

const MinimalLayout: React.FC = ({children}) => {
  return <>
    <Helmet
      defaultTitle={"Eventicle - An error has occured"}
    >
      <html lang="en"/>
      <meta charSet="utf-8"/>
    </Helmet>
    <EVFront style={{height: "100vh"}}>
      {children}
    </EVFront>
  </>
}

export default MinimalLayout
