import React from "react";
import {EventityTable} from "./EventityTable";
import {Col} from "antd";
import {toTitleCase} from "../../service/string";

interface SchemaItemOutput {
  name: string
  data: any
}

export const SchemaItemOutput: React.FC<SchemaItemOutput> = ({name, data}) => {
  return (<Col xs={24}>
    <label>{toTitleCase(name || "")}</label>
    <EventityTable eventity={{data} as any}/>
  </Col>)
}
