import React, {useState} from "react";
import {Button, Modal} from "antd";

interface I {
  onRemove(workspaceId: string): Promise<any>
  actionData?: any
}

const RemoveWorkspace: React.FC<I> = ({onRemove, actionData}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  return <>
    <Modal
      destroyOnClose
      confirmLoading={loading}
      visible={visible}
      onCancel={() => void setVisible(false)}
      title={"Remove Workspace?"}
      onOk={() => {
        setLoading(true)
        onRemove(actionData?.id)
          .then(() => {
            setLoading(false)
            setVisible(false)
          })
      }}
    >
      <p><em>This will <strong>permanently</strong> erase the "{actionData?.item?.name}" workspace.</em></p>
      <p>Are you sure you want to do this?</p>
    </Modal>
    <Button type={"primary"} danger onClick={() => void setVisible(true)}>Remove Workspace</Button>
  </>
}

export default RemoveWorkspace
