import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {useApolloClient, useLazyQuery, useReactiveVar} from "@apollo/client";
import {WORKSPACE_COMPONENTS} from "../../../../graphql/queries/web-component";
import {currentWorkspaceId} from "../../../../graphql/type-policies/workspace";
import RenderComponent from "./RenderComponent";
import JSONPretty from "react-json-pretty";

const ComponentPage: React.FC<RouteComponentProps> = ({match}) => {
  const {pathname} = useLocation()
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const client = useApolloClient()
  const [context, setContext] = useState<any>({})
  const [tag, setTag] = useState<string>(undefined)
  const [slug, setSlug] = useState<string>(undefined)

  const [readWorkspaceComponents, {data: {WorkspaceComponents} = {WorkspaceComponents: []}}] = useLazyQuery(
    WORKSPACE_COMPONENTS,
    {
      variables: {
        workspaceId,
      }
    })

  useEffect(() => {
    // This seems necessary for when we don't have a workspaceID
    if (workspaceId) {
      setContext({workspaceId})
      readWorkspaceComponents()
    } else {
      setContext({})
    }
  }, [workspaceId])

  useEffect(() => {
    if (pathname.length > 0) {
      const path = pathname.replace(match.url, "").replace(/^\/|\/$/g, '').split("/")

      const found: any = WorkspaceComponents.find((c: any) => c.id === path[1] && c.namespace === path[0])

      if (found && found.pages) {
        const urlSlug = path.slice(2).join("/")
        const page = found.pages.find((p: any) => p.slug === urlSlug)
        setTag(page.tag)
        setSlug(page.slug)
      }
    }
    return () => setTag(undefined)
  }, [pathname, WorkspaceComponents])

  return <>
    {tag && <RenderComponent key={`${tag}-${slug}`} slug={slug} tag={tag} workspaceId={workspaceId} client={client} context={context}/>}
  </>
}

export default ComponentPage
