import {gql} from "@apollo/client";

export const IS_LOGGED_IN = gql`
  query isUserLoggedIn {
    isLoggedIn @client
  }
`

export const RESET_PASSWORD_REQUEST = gql`
  mutation ResetPassword($email: String!) {
    BeginResetPassword(email: $email) {
      success message
    }
  }
`

export const CHECK_RESET_CODE = gql`
  query CheckResetCode($code: String!) {
    CheckResetCode(code: $code) {
      success message
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($code: String! $password: String! $passwordCompare: String!) {
    ResetPassword(code: $code passwordCompare: $passwordCompare password: $password) {
      success message
    }
  }
`
