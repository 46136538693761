import styled from "styled-components";
import {breakpoints} from "../../layouts/style";

export const PanelContainer = styled.div`
  width: 100%;
  display: flex;

  flex-wrap: wrap;
  grid-gap: 1rem;

  &>* {
    flex-basis: 30%;
    flex-grow: 1;

    &.eventity-item {
      max-width: 33.3%;
    }

    &.eventity-list {
      &:last-child {
      }
    }
  }
`

