import React from "react";
import {Route, RouteComponentProps, Switch} from "react-router";
import ListKeys from "./ListKeys";
import ViewKey from "./ViewKey";

interface KMS extends RouteComponentProps {}

const KMS: React.FC<KMS> = ({match}) => {
  const pathname = match.url
  return <Switch>
    <Route exact path={pathname} component={ListKeys} />
    <Route path={`${pathname}/view/:keyId`} component={ViewKey} />
  </Switch>
}

export default KMS
