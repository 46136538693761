import React, {useEffect} from "react";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {useLazyQuery, useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {GENERATE_API_KEY, LIST_API_KEYS, REVOKE_API_KEY} from "../../graphql/queries/api-keys";
import {EntityList} from "../../components/EntityList";
import {Button, Card, Space} from "antd";
import {GenerateApiKey} from "./Components/GenerateApiKey";
import {initWorkspace, IWorkspaceData, READ_ENTITIES_PAGE} from "../../service/workspace";
import CopyAPIKey from "./Components/CopyAPIKey";

interface ListApiKeys {}

export const ListApiKeys: React.FC<ListApiKeys> = () => {
  const workspaceId = useReactiveVar(currentWorkspaceId)

  const {data: {workspace: {entities}} = {workspace: initWorkspace}} = useQuery(
    READ_ENTITIES_PAGE,
    {variables: {id: workspaceId}}
  ) as IWorkspaceData;

  const [refreshKeys, {
    data: {ApiKeys} = {ApiKeys: []},
    loading,
    refetch
  }] = useLazyQuery(LIST_API_KEYS, {fetchPolicy: "cache-and-network"})

  useEffect(() => {
    refreshKeys()
  }, [workspaceId])


  const [generateKey, {loading: generateLoading}] = useMutation(
    GENERATE_API_KEY,
    {
      onCompleted: () => {
        if (refetch) {
          refetch()
        }
      }
    }
  )

  const [revokeApiKey, {loading: revokeLoading}] = useMutation(
    REVOKE_API_KEY,
    {
      onCompleted: () => {
        if (refetch) {
          refetch()
        }
      }
    }
  )

  return (<>

    <EntityList
      list={ApiKeys}
      loading={loading || generateLoading || revokeLoading}
      urlPrefix={"view"}
      indexName={"client_id"}
      actions={[
        <CopyAPIKey workspaceId={workspaceId}/>,
        {
          name: "Revoke Key", onClick(args: any) {
            revokeApiKey({variables: {client_id: args.item.client_id, id: args.item.id}})

          },
          type: "primary",
          danger: true
        },
      ]}
    >
      <Space direction={"horizontal"}>
        <GenerateApiKey entities={entities} generateKey={async (name, types = [], methods = []) => {
          await generateKey({variables: {name, methods, types}})
        }}/>
        <Button
          onClick={() => refreshKeys()}
        >Refresh API Keys</Button>
      </Space>
    </EntityList>
    <Card
      style={{maxWidth: "40rem"}}
    >
      API Keys can be restricted to specific Eventity Types and specific mutations or queries on an eventity. An API key
      that has no restrictions will currently have access to the entire workspace. This may be subject to change at a
      future date.
    </Card>
  </>)
}
