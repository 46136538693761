import React, {useEffect, useState} from "react";
import {useQuery, useReactiveVar} from "@apollo/client";
import {currentWorkspaceId} from "../../../../graphql/type-policies/workspace";
import {useHistory, useParams} from "react-router";
import {READ_EVENTITY} from "../../../../graphql/queries/eventity";
import EventityView from "./EventityView";
import {toTitleCase} from "../../../../service/string";
import {ContentHeader} from "../../../layouts/style";
import {WebComponentWrapper} from "../../../Extensions/WebComponentWrapper";
import {Button, Spin} from "antd";
import {currentOrgID} from "../../../../graphql/type-policies/orgs";
import {PanelContainer} from "../style";
import Panels from "../Panels";
import {levelOnekeys, orderFields} from "../../../../service/joi";
import {filterMetaFields} from "../../../../graphql/queries/generic";

const ViewEventity: React.FC = () => {
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const organisationId = useReactiveVar(currentOrgID)
  const {eventityName} = useParams<{ eventityName: string }>()
  const {push} = useHistory()


  const [fields, setFields] = useState<string[]>([])
  const [titleField, setTitleField] = useState<string>("")

  const {
    data: {
      GetWorkspaceEntity: eventity,
      GetWorkspaceEntity: {
        schema,
        commands,
      }
    } = {
      GetWorkspaceEntity: {
        schema: {},
        commands: []
      }
    },
    refetch,
    loading
  } = useQuery(
    READ_EVENTITY,
    {variables: {name: eventityName, workspaceId}}
  ) as any;

  useEffect(() => {
    if (eventity && schema) {
      const f = orderFields(schema, filterMetaFields(Object.keys(schema)))
      if (f.length > 0) {
        const tField = f[0] ?? ""
        setTitleField(tField)
        setFields(f)
      }
    }
  }, [eventity, schema])

  return <>

    <ContentHeader
      title={toTitleCase(eventityName)}
      onBack={() => push(`/dashboard/organisation/${organisationId}/workspace/${workspaceId}`)}
      extra={<><Button onClick={() => refetch({name: eventityName, workspaceId})}
                       loading={loading}>Reload</Button></>}
    />
    <PanelContainer>
      <WebComponentWrapper schema={schema} viewName={"list"} workspaceId={workspaceId} context={{eventity, schema}}>
        {eventity && schema && titleField && <EventityView
          eventity={eventity}
          eventityName={eventityName}
          fields={fields}
          schema={schema}
          workspaceId={workspaceId}
          titleField={titleField}
        />}
        {!(eventity && schema && titleField) && <Spin spinning size={"large"}/>}
      </WebComponentWrapper>
      <Panels workspaceId={workspaceId} type={"list"} entityName={eventityName}
              context={{data: eventity, schema, type: eventityName}}/>

    </PanelContainer>

  </>
}

export default ViewEventity
