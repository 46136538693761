import React from "react";
import {ExtensionComponents} from "./ExtensionComponents";
import {useQuery} from "@apollo/client";
import {WORKSPACE_COMPONENTS} from "../../graphql/queries/web-component";

interface AttachExtensions {
  workspaceId: string
}

export const AttachExtensions: React.FC<AttachExtensions> = ({workspaceId}) => {

  const {data: {WorkspaceComponents: list} = {WorkspaceComponents: []}} = useQuery(
    WORKSPACE_COMPONENTS,
    {
      variables: {
        workspaceId,
      },
      fetchPolicy: "network-only",
    })

  if(list.length > 0) {
    return (<>
      {list.map((extension: any, id: any) => <ExtensionComponents extension={extension} workspaceId={workspaceId} key={`${id}-${extension.id}`} />)}
    </>)
  } else {
    return (<></>)
  }
}
