import React, {useState} from "react";
import {StreamContainer} from "./style";
import EventList from "./Components/EventList";
import ViewEvent from "./Components/ViewEvent";

interface Stream {
  stream: any[]
}
type top = number
type left = number
type width = number
type height = number
type pos = [top,left,height,width]
export const Stream: React.FC<Stream> = ({stream}) => {

  const [item, setItem] = useState<any>()
  const [pos, setPos] = useState<pos>()

  const viewItem = (id: string, position: [top, left, height, width]) => {
    if(item && item.id === id) {
      setItem(undefined)
      setPos(undefined)
    } else {
      const i = stream.find((s) => s.id === id)
      if(i) {
        setItem(i)
        setPos(position)
      }
    }
  }

  return <>
    <StreamContainer>
      <EventList items={stream} viewItem={viewItem}/>
      {item && <ViewEvent id={item.id} item={item} pos={pos} />}
    </StreamContainer>

  </>
}
