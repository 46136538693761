import React from "react";
import {useHistory} from "react-router";
import {useQuery, useReactiveVar} from "@apollo/client";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {useLocation} from "react-router-dom";
import ExtensionList from "./Components/ExtensionList";
import {ExtensionResult, READ_INSTALLED_EXTENSIONS} from "../../graphql/queries/extensions";
import {Button, Card, Popover, Spin} from "antd";
import {UploadCard} from "../Dashboard/dashboard.styles";
import {AppstoreAddOutlined} from "@ant-design/icons";
import Extensions from "../../service/extensions";
import {ContentHeader} from "../layouts/style";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import ManifestEditor from "./Components/ManifestEditor";
import UploadExtension from "./Components/UploadExtension";
import AddRemoteExtension from "./Components/AddRemoteExtension";

const InstalledExtensions: React.FC = () => {
  const {push} = useHistory()
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const organisationId = useReactiveVar(currentOrgID)

  const {data: {ListInstalledExtensions: {results: extensions, page, query}} = {ListInstalledExtensions:  {results: [], page: 1, query: ""}}, loading, refetch} = useQuery<{ListInstalledExtensions: ExtensionResult}>(
    READ_INSTALLED_EXTENSIONS,
    {
      fetchPolicy: "network-only"
    }
  )

  const {pathname} = useLocation()
  return <>

    <ContentHeader
      title={"Installed Extensions"}
      onBack={() => push(`/dashboard/organisation/${organisationId}/workspace/${workspaceId}`)}
      extra={<>
        <Button onClick={() => push(`${pathname}/install`)}>Install Extensions</Button>

        <ManifestEditor onComplete={() => void refetch()} workspaceId={workspaceId} />

      </>}
    />
    <Spin spinning={loading}>
      <ExtensionList extensions={extensions} workspaceId={workspaceId} onComplete={() => {
        refetch()
        Extensions().reloadWorkspaceComponents(workspaceId)
      }}>

        <UploadCard
          actions={[
            <Popover content={"Install from App Store"}><AppstoreAddOutlined onClick={() => push(`${pathname}/install`)} /></Popover>,
            <AddRemoteExtension onComplete={async () => {
              refetch()
              Extensions().reloadWorkspaceComponents(workspaceId)
            }} />,
            <UploadExtension onComplete={() => {
              refetch()
              Extensions().reloadWorkspaceComponents(workspaceId)
              return void(0)
            }} />,
          ]}
        >
          <Card.Meta
            title={"Add Extension"}
            description={"Install a system extension, link to a remote extension, or upload a custom manifest."}
          />



        </UploadCard>
      </ExtensionList>
    </Spin>
  </>
}

export default InstalledExtensions
