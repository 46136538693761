import React from "react";
import {metaTypeMap} from "../Form/tools";

interface MetaTypeDisplay {
  name: string
  schema: any
  data: any
}

export const MetaTypeDisplay: React.FC<MetaTypeDisplay> = ({schema, ...props}) => {
  if(
    schema.hasOwnProperty("@meta") &&
    schema["@meta"].hasOwnProperty("function") &&
    schema["@meta"].function.hasOwnProperty("metatype")
  ) {
    const type = schema["@meta"].function.metatype
    if(metaTypeMap.has(type)) {
      // @ts-ignore
      const Type = (metaTypeMap.get(type)).output || undefined
      if (Type) {
        return <Type {...props} {...{schema}} />
      }
    }
  }
  return (<>{props.children}</>)
}
