import React from "react";
import {RouteComponentProps, useHistory} from "react-router";
import {useQuery, useReactiveVar} from "@apollo/client";
import {DEV_ENABLED} from "../../graphql/queries/workspace";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import CopyWorkspaceId from "./Components/CopyWorkspaceId";
import {ContentHeader} from "../layouts/style";
import {useLocation} from "react-router-dom";
import DeveloperActions from "./Components/DeveloperActions";

interface I extends RouteComponentProps {}

const DeveloperSettings: React.FC<I> = ({...props}) => {


  const {push} = useHistory()
  const {pathname} = useLocation()


  const workspaceId = useReactiveVar(currentWorkspaceId)
  const {data: {workspace: {
    development_enabled,
    entities
  }} = {workspace: {development_enabled: false, entities: []}},
    loading,
    // refetch,
  } = useQuery(
    DEV_ENABLED,
    {
      variables: {
        id: workspaceId
      }
    }
  )

  return <>
    <ContentHeader
      title={development_enabled?"Workspace Settings":"Developer Options Disabled"}
      extra={[
        <CopyWorkspaceId id={workspaceId} />,
      ]}
    />

    {development_enabled && <DeveloperActions entities={entities} />}

  </>
}

export default DeveloperSettings
