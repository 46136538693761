import React from "react";
import {FormItem} from "./FormItem";
import {Button, Form, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import FieldFromType from "../Joi/FieldFromType";
import {isDisabled} from "./tools";

interface ArrayWithSchemaItem extends FormItem {}

export const ArrayWithSchemaItem: React.FC<ArrayWithSchemaItem> = ({name, label, data, required, fieldKey}) => {

  const fieldsRow = (field: any) => {
    const rowData = data["@meta"].function.items
    const rowNames = Object.keys(rowData)
    let fixedFieldKey: any = []
    if(!!fieldKey) {
      if (Array.isArray(fieldKey)) {
        fixedFieldKey = [...fieldKey]
      } else {
        fixedFieldKey = [fieldKey]
      }
    }
    return rowNames
      .filter((row) => row.charAt(0) !== "@")
      .map((row) => {
        // @ts-ignore
        const type: string = rowData[row]["@type"]
        const name = [Number(field.name), `${row}`] as any[]
        const fieldKey = [...fixedFieldKey, field.fieldKey, row]
        return (<>
          <FieldFromType name={name} data={rowData[row]} type={type} fieldKey={fieldKey} hideLabel key={fieldKey.join("/")}/>
        </>)
      })
  }

  return (<>
    <Form.List
      name={name}
    >
      {(fields, {add, remove}) => {
        let disabled = isDisabled(data)
        if (!disabled && data.max) {
          if (fields.length >= data.max) {
            disabled = true
          }
        }

        return (<>
          <Form.Item
            label={label}
            required={required}
          >
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              disabled={disabled}
              style={{marginBottom: "1rem"}}
            >
              <PlusOutlined/> Add Row
            </Button>

            {fields.map((field, index) => (
              <Space key={index} align={"baseline"}>
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  style={{margin: '0 8px'}}
                  onClick={() => {
                    remove(field.name);
                  }}
                />
                {fieldsRow(field)}
              </Space>
            ))}

          </Form.Item>
        </>)
      }}</Form.List></>)
}
