import React from "react";
// import {useQuery} from "@apollo/client";
import {useParams} from "react-router";

interface ViewAccount {}

export const ViewAccount: React.FC<ViewAccount> = () => {
  const {sysaccountId} = useParams<{sysaccountId: string}>()

  return (
    <div>{sysaccountId}</div>
  )
}
