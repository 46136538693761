import React, {useState} from "react";
import {LogInForm} from "../components/Auth/LogInForm";
import {ApolloClient, useApolloClient, useMutation} from "@apollo/client";
import {AuthService, LOG_IN_USER} from "../service/auth";

interface LogIn {}

export const LogIn: React.FC<LogIn> = (props) => {
  const client: ApolloClient<any> = useApolloClient();
  const initialError = {state: false, message: ''};
  const [error, setError] = useState<any>()
  const [login] = useMutation(
    LOG_IN_USER,
    {
      onCompleted({AccountLogin: {jwt}}) {
        const authService = new AuthService(client)
        setError(initialError)
        AuthService.setAuthToken(jwt);
        authService.setLoggedIn()
      },
      onError(e) {
        console.log(e.message)
        setError({state: true, message: e.message})
      },
    }
  );

  return (<>
    <LogInForm login={login} error={error} resetError={() => setError(initialError)}/>
    </>)
}
