import React, {useEffect} from "react";
import {Select} from "antd";
import {toTitleCase} from "../../../service/string";

interface I {
  onChange(value: any): void
  selected?: string
  fields?: string[]
}

const {Option} = Select

const SimpleFieldSelect: React.FC<I> = ({fields= [], ...props}) => {



  useEffect(() => {

  },[fields])

  return <>
    <Select placeholder={"Select A Field"} value={props.selected} onSelect={(value) => props.onChange(value)}>
      {fields.map((field) => <Option key={`title-${field}`} value={field}>{toTitleCase(field)}</Option>)}
    </Select>
  </>
}

export default SimpleFieldSelect
