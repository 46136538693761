import React, {useEffect, useState} from "react";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {useQuery} from "@apollo/client";
import {READ_ORGS} from "../../service/orgs";
import {CreateOrganisation} from "../orgs/CreateOrganisation";
import {Helmet} from "react-helmet";
import OrgCard from "./OrgCard";
import {PlusOutlined} from "@ant-design/icons";
import {DashCard, DashCardIcon, DashCardTitle} from "./dashboard.styles";
import {ContentHeader} from "../layouts/style";
import FilterTools from "../../components/Common/FilterTools";
import {DEFAULT_LISTLEN} from "../../service/const";
import {Pagination, Spin} from "antd";
import {pageSizeOptions} from "../../service/pagination";

export const Home: React.FC = () => {

  const [filter, setFilter] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(DEFAULT_LISTLEN)
  const [sort, setSort] = useState<any[]>([{field: "name", direction: "ASC"}])

  // TODO pagination
  const {
    data: {orgs: {entries, pageInfo: {pageSize, currentPage}, totalCount}} = {
      orgs: {
        entries: [],
        pageInfo: {currentPage: 0, pageSize: 0},
        totalCount: 0
      }
    }, loading, refetch, fetchMore
  } = useQuery(
    READ_ORGS,
    {
      variables: {filter, limit, page, sort}
    }
  ) as any;


  useEffect(() => {
    // @ts-ignore
    currentOrgID(null)
    // @ts-ignore
    currentWorkspaceId(null)

  }, [])

  const onPageChange = (p, ps) => {
    if(p) {
      setPage(p)
    }
    if (ps) {
      setLimit(ps)
    }
  }

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <ContentHeader title={"Dashboard"}/>
      <FilterTools fields={["name"]} titleField={"name"} onFilter={(f) => setFilter(f)}/>
      <Pagination
        style={{marginTop: "1rem", marginBottom: "1rem"}}
        defaultPageSize={limit}
        current={page}
        pageSize={limit}
        total={totalCount ?? 0}
        showSizeChanger={true}
        pageSizeOptions={pageSizeOptions}
        onChange={onPageChange}
      />
      <Spin spinning={loading}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: "repeat(auto-fill, 15rem)",
        gridAutoRows: "auto",
        gridGap: "1rem"
      }}>
        {entries.map((org: any) => <OrgCard key={org.id} org={org}/>)}
        <CreateOrganisation
          onComplete={() => {
            if (refetch) {
              refetch()
            }
          }}
        >
          <DashCard>
            <DashCardTitle>
              create organisation
            </DashCardTitle>
            <DashCardIcon>
              <PlusOutlined/>
            </DashCardIcon>
          </DashCard>
        </CreateOrganisation>
      </div>
      </Spin>
      <Pagination
        style={{marginTop: "1rem"}}
        current={page}
        defaultPageSize={limit}
        pageSize={limit}
        total={totalCount ?? 0}
        showSizeChanger={true}
        pageSizeOptions={pageSizeOptions}
        onChange={onPageChange}
      />
    </>
  );
}

/*

{
  defaultPageSize: pageSize,
  total: org.workspaces.totalCount || 0,
  showSizeChanger: true,
  pageSizeOptions,
  onChange: (p, ps) => {
    setPage(p)
    if (!!ps) {
      setPageSize(ps)
    }
  }
}

 */
