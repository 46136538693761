import React from "react";
import {useMutation} from "@apollo/client";
import {IWorkspaceEntity, READ_ENTITIES_PAGE,} from "../../service/workspace";
import {EntityList} from "../../components/EntityList";
import {DELETE_WORKSPACE_ENTITY} from "../../graphql/queries/eventity";
import {useLocation} from "react-router-dom";

interface WorkspaceEntities {
  workspaceId: string
  entities: IWorkspaceEntity[]
  loading?: boolean
  onComplete(): Promise<void>
  pagination?: any
}

export const WorkspaceEntities: React.FC<WorkspaceEntities> = ({workspaceId, entities, loading, ...props}) => {

  const {pathname} = useLocation()

  const [deleteWorkspaceEntity] = useMutation(
    DELETE_WORKSPACE_ENTITY,
    {
      // refetchQueries: [
      //   {query: READ_ENTITIES_PAGE, variables: {id: workspaceId}}
      // ],
      onCompleted() {
        props.onComplete()
      }
    }
  )

  const editUrl = `${pathname}/edit-eventity`

  return (
    <>
      <EntityList
        list={entities}
        // edit={() => message.info("Edit is not currently implemented.", 5)}
        actions={[{name: "Edit", action: "link", url: editUrl}]}
        indexName={"name"}
        loading={loading}
        pagination={props.pagination}
        remove={async (workspaceEntityName: string, item: IWorkspaceEntity) => {

          await deleteWorkspaceEntity({
            variables: {
              input: {
                workspaceId,
                workspaceEntityName: item.name,
              }
            }
          })
        }
        }
      />
    </>
  )
}
