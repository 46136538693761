import React from "react";
import {useMutation, useQuery} from "@apollo/client";
import {LIST_KEYS, REVOKE_KEY} from "../../graphql/queries/kms";
import {EntityList} from "../../components/EntityList";
import {Card, message} from "antd";
import GenerateKey from "./Components/GenerateKey";
import CopyKey from "./Components/CopyKey";

const ListKeys: React.FC = () => {
  const {data: {ListEncryptionKeys} = {ListEncryptionKeys: {list: [], count: 0}}, loading, refetch} = useQuery(
    LIST_KEYS
  )

  const [revokeKey] = useMutation(
    REVOKE_KEY,
    {
      onCompleted() {
        if(refetch) {
          refetch()
        }
      }
    }
  )

  return <><EntityList
    list={ListEncryptionKeys.list}
    loading={loading}
    urlPrefix={"view"}
    indexName={"id"}
    actions={[
      <CopyKey />,
      {name: "Revoke Key", onClick: (args: any) => {
        revokeKey({variables: {keyId: args.id}})
          .then((e) => message.success("Key Revoked"))
          .catch(e => message.error(e.message))
        }}
    ]}

  >
    <GenerateKey />
  </EntityList>

  <Card>Encryption Keys are useful sometimes</Card>

  </>
}

export default ListKeys
