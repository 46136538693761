import React from "react";
import {Button} from "antd";
import {useHistory} from "react-router";
import {DashCard, DashCardIcon, DashCardTitle} from "./dashboard.styles";
import {faSitemap} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

interface OrgCard {
  org: any
}

const OrgCard: React.FC<OrgCard> = ({org}) => {
  const link = `/dashboard/organisation/${org.id}`
  const {push} = useHistory()
  return <DashCard
    className={"extensioncard"}
    actions={[
      <Button onClick={() => push(link)}>View Organisation</Button>
    ]}
  >
    <Link to={link}>
      <DashCardTitle>{org.name}</DashCardTitle>
      <DashCardIcon>
        <FontAwesomeIcon
          // @ts-ignore
          icon={faSitemap}/>
      </DashCardIcon>
    </Link>
  </DashCard>
}

export default OrgCard
