import React from "react";
import {AuthService} from "../../service/auth";
import {useApolloClient} from "@apollo/client";

interface LogOutButton {}

export const LogOutButton: React.FC<LogOutButton> = (props) => {
  const client = useApolloClient();
  const authService = new AuthService(client)

  return (
    <a
      onClick={async () => {
        await authService.logOut()
      }}
    >
      Log Out
    </a>
  )
}
