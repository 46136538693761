import React from "react";
import {SITE_USER} from "../../graphql/queries/me";
import {Avatar} from "antd";
import {Link} from "react-router-dom";
import {LogOutButton} from "../Auth/LogOutButton";

interface UserMenu {
  user: SITE_USER
}

export const UserMenu: React.FC<UserMenu> = ({user}) => (
  <div className={"user-menu container"}>
    <div className={"user-menu header"}>
      <Avatar className={"user-menu avatar"} size={"default"}>{user.name.charAt(0).toLocaleUpperCase()}</Avatar>
        <div>{user.name}</div>
        <div>{user.email}</div>
    </div>
    <ul className={"user-menu body"}>
      <li>
        <Link to={"/account/profile"}>My Profile</Link>
      </li>
      <li>
        <LogOutButton />
      </li>
    </ul>
  </div>
)
