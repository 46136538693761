import {ListItem} from "./ListItem";
import {ArrayItem} from "./ArrayItem";
import {TextAreaItem} from "./TextAreaItem";
import {StringItem} from "./StringItem";
import {JoiSchema} from "../../service/joi";
import {DateItem} from "./DateItem";
import moment, {Moment} from "moment";
import {SchemaItem} from "./SchemaItem";
import {SchemaItemOutput} from "../Eventity/SchemaItemOutput";
import {ArrayWithSchemaFunction} from "../Eventity/ArrayWithSchemaFunction";
import {ArrayWithSchemaItem} from "./ArrayWithSchemaItem";
import {ArrayWithSchemaItemOutput} from "../Eventity/ArrayWithSchemaItemOutput";
import MultipleChoiceItem from "./MultipleChoiceItem";
import {ObjectOutput} from "../Eventity/ObjectOutput";
import {ArrayOutput} from "../Eventity/ArrayOutput";
import {EventityLinkItem} from "./EventityLinkItem";
import {EventityLinkOutput} from "../Eventity/EventityLinkOutput";
import {EventityLinkFunction} from "../Eventity/EventityLinkFunction";

export const generateRules = (item: any): any[] => {
  return []
}

export const isDisabled = (item: any): boolean => {
  if(item.hasOwnProperty("@meta") && item["@meta"].hasOwnProperty("readOnly")) {
    return item["@meta"].readOnly
  }
  return false
}

export interface IMetaType {
  input?: any
  output?: any
  build?: any
  isMetaType?: boolean
  hasMinMax?: boolean
  name: string
  schema?: JoiSchema
  type: string
  additionalFields?: string[],
  allowRequired: boolean
  blockUpdate?: boolean
}

export const metaTypeMap = new Map<string, IMetaType>([
  ["string",{name: "String", type: "string", hasMinMax: true, allowRequired: true}],
  ["number",{name: "Number",type: "number", hasMinMax: true, allowRequired: true}],
  ["boolean",{name: "Boolean",type: "boolean", allowRequired: false}],
  ["date",{name: "Date",type: "date", allowRequired: true}],
  ["binary",{name: "Binary",type: "binary",allowRequired: true}],
  ["email",{name: "Email",type: "email", hasMinMax: true, allowRequired: true}],
  ["uuid",{name: "UUID",type: "uuid", allowRequired: true}],
  ["object",
    {
      name: "Object",
      type: "object",
      allowRequired: false,
      output: ObjectOutput,
    }
    ],
  ["array",
    {
      name: "Array",
      type: "array",
      hasMinMax: true,
      allowRequired: true,
      input: ArrayItem,
      output: ArrayOutput,
    }
  ],
  ["array-with-schema",
    {
      name: "Array With Schema",
      type: "array",
      hasMinMax: true,
      build: ArrayWithSchemaFunction,
      input: ArrayWithSchemaItem,
      output: ArrayWithSchemaItemOutput,
      isMetaType: true,
      additionalFields: ['items'],
      allowRequired: false,

    }
  ],
  ['list', {
    name: "List",
    input: ListItem,
    // build: ArrayFunction,
    isMetaType: true,
    schema: {list_items: {"@type": "array", items: {"@type": "string"}}},
    type: "string",
    hasMinMax: true,
    allowRequired: true,
    blockUpdate: true,
  }],
  ['multiple-choice',{
    name: "Multiple Choice",
    isMetaType: true,
    // build: MultipleChoiceFunction,
    input: MultipleChoiceItem,
    schema: {
      items: {
        "@type": "array",
        "@meta": {
          function: {
            metatype: "array-with-schema",
            items: {
              "@type": "object",
              label: {
                "@type": "string",
              },
              value: {
                "@type": "string",
              }
            }
          }
        }
      }
    },
    type: "array",
    additionalFields: ['items'],
    allowRequired: true,
  }],
  ['textarea',{
    name: "Text Area",
    input: TextAreaItem,
    type: "string",
    hasMinMax: true,
    isMetaType: true,
    allowRequired: true
  }],
  // TODO fix this whole thing
  // ['search-query', {
  //   name: "GraphQL Search",
  //   input: StringItem,
  //   isMetaType: true,
  //   schema: {
  //     "@meta": {
  //       "boxed": true,
  //     },
  //     query: {"@type": "string", "@required": true},
  //     variables: {"@type": "any", "@meta": {function: {metatype: "textarea"}}}
  //   },
  //   type: "string",
  //   allowRequired: true
  // }],
  ['search-endpoint', {
    name: "Endpoint Search",
    input: StringItem,
    isMetaType: true,
    schema: {
      target: {"@type": "string", "@required": true}
    },
    type: "string",
    allowRequired: true
  }],
  ['joi-schema-builder', {
    name: "Joi Schema Builder",
    isMetaType: true,
    input: SchemaItem,
    output: SchemaItemOutput,
    type: "string",
    allowRequired: true
  }],
  ["eventity-link", {
    name: "Eventity Link",
    isMetaType: true,
    build: EventityLinkFunction,
    input: EventityLinkItem,
    output: EventityLinkOutput,
    type: "string",
    additionalFields: ['items'],
    allowRequired: true
  }]
])

export const typeMap = new Map([
  ["array", ArrayItem],
  ["date", DateItem],
])

export const typeFormat = new Map([
  ["date", {
    in: (date: any) => {
      return moment(date)
    },
    out: (date: Moment) => {
      return date.format("YYYY-MM-DD HH:mm:ss.SSS")
    },
    display: (date: string) => {
      return moment(date).format("ddd [the] Do [of] MMM YYYY")
    }
  }],
  ["array", {
    display: (data: any[]) => {
      return data.join(", ")
    }
    // out: (data: any[]) => {
    //   return JSON.stringify(data)
    // }
  }],
  ["boolean", {
    in: (data: any) => !!data,
    out: (data: any) => !!data,
    display: (data: boolean) => {
      return data?"true":"false"
    },
  }],
  ["any", {
    display: (data: any) => JSON.stringify(data)
  }],
])

export const isMetaFunctionType = (meta: any) => {
  return meta.hasOwnProperty('function') && metaTypeMap.has(meta.function.metatype)
}

export const metaFunction = (meta: any) => {
  return meta.function.metatype
}

export const hasHelp = (schema: any) => {
  if(schema.hasOwnProperty("@meta") && schema["@meta"].hasOwnProperty("description")) {
    return schema["@meta"].description;
  }
  return null
}
