import React, {useEffect, useState} from "react";
import {Button, Card} from "antd";
import Display from "./Display";
import Configure from "./Configure";
import {metaTypeMap} from "../../../../../../components/Form/tools";
import {intersection} from "lodash"

interface I {
  onChange(path: string[], data: any): void
  item: any
  node: string[]
}

const MetaBuilder: React.FC<I> = ({node, onChange, item}) => {
  const [edit, setEdit] = useState(false)
  const [type, setType] = useState("")
  const [configurable, setConfigurable] = useState(false)
  const [metaFunction, setMetaFunction] = useState<any>(undefined)
  const [metaType, setMetaType] = useState<any>(undefined)
  useEffect(() => {
    let mf: any;
    let cf: boolean = false;
    let mt: any;

    if(!!item) {
      setType(item["@type"] || "string")

      if(item.hasOwnProperty("@meta")) {
        const meta = item["@meta"]

        if(meta.hasOwnProperty("function")) {
          mf = meta.function
        }

        if(!!mf && metaTypeMap.has(mf.metatype)) {
          mt = metaTypeMap.get(mf.metatype)

          const keys = intersection(Object.keys(mt as any), ["schema", "build"])

          if(keys.length > 0) {
            cf = true
          }
        }
      }
    }

    setMetaFunction(mf)
    setConfigurable(cf)
    setMetaType(mt)
    setEdit(false)
  }, [item])

  if(!metaFunction || !configurable) {
    return <></>
  }

  if(edit) {
    return <Card style={{margin: "1rem 0"}}>
      <Configure type={type} metafunction={metaFunction} metatype={metaType} onComplete={(data) => {
        onChange([...node, "@meta", "function"], data)
        setEdit(false)
      }} onCancel={() => setEdit(false)} />
    </Card>

  } else {

    return <Card style={{margin: "1rem 0"}}>

      <Display metafunction={metaFunction} metatype={metaType} node={node} onComplete={({schema, metadata}: {schema: any, metadata?: any}) => {

        if(metadata) {
          Object.keys(metadata).forEach((key) => {
            onChange([...node, "@meta", key], metadata[key])
          })
        }
        if(schema) {
          Object.keys(schema).forEach((key) => {
            onChange([...node, "@meta", key], schema[key])
          })
        }
      }}>
        <Button onClick={() => setEdit(true)}>Configure</Button>
      </Display>
    </Card>
  }
}

export default MetaBuilder
