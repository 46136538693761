import React, {useEffect, useState} from "react";
import {Form} from "antd";
import {EventityTree} from "./EventityTree";

interface ArrayWithSchemaFunction {
  name: string
  updateFields(fieldData: any, otherFieldData?: any): void
  required: boolean
  data: any
}

export const ArrayWithSchemaFunction: React.FC<ArrayWithSchemaFunction> = ({name, updateFields, data, required}) => {
  const [schema, setSchema] = useState({} as any)

  const fName = `${name}:items`
  const onComplete = (items: any) => {
    updateFields({metadata: {items: {...items, ["@type"]: "object"}}})
    setSchema(items)
  }

  useEffect(() => {
    if(data && data.items) {
      setSchema(data.items)
    }
  }, [data])

  return (<>
    <Form.Item
      noStyle
      name={`${name}:@type`}
    />
    <Form.Item
      name={fName}
      noStyle
      preserve={false}
    />
    <EventityTree eventity={{name: "", data: schema}} updateSchema={onComplete} editable/>
  </>)
}
