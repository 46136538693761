import React from "react";
import {List} from "antd";
import {toTitleCase} from "../../service/string";

interface I {
  name: string
  data: any[]
}

export const ArrayOutput: React.FC<I> = ({name, data}) => {
  return <><List
    bordered
    header={toTitleCase(name || "")}
    dataSource={data}
    renderItem={(item) => <List.Item>{item}</List.Item>}
  />
    </>
}
