import React, {useEffect} from "react";
import {Button, Form, Input, message} from "antd";
import {useForm} from "antd/lib/form/Form";
import {toPascalCase} from "../../../service/string";

interface I {
  onComplete(name: string): void
}

const Title: React.FC<I> = ({onComplete, ...props}) => {

  const [titleForm] = useForm()

  const fixTitle = (val: string):string => {
    if (val.lastIndexOf(" ") !== -1 || val.charAt(0).toUpperCase() !== val.charAt(0)) {
      return toPascalCase(val)
    } else {
      return val
    }
  }

  useEffect(() => {
    return () => {
      const name = titleForm.getFieldValue("name")
      onComplete(name)
    }
  }, [])

  let children = <>error</>;
  let only: any;

  try {
    only = React.Children.only(props.children)
    if(!!only) {
      children = React.cloneElement(only, {validate:() => {
          const name = titleForm.getFieldValue("name")
          if(!!name && name.length > 0) {
            onComplete(name)
            return true
          } else {
            message.error("A name is required")
            return false
          }
        }})
    }
  } catch (e) {

  }

  return <>
    <Form
      form={titleForm}
      onFinish={(data) => {
        const name = fixTitle(data.name)
        onComplete(name)
      }}
    >
      <Form.Item
        key={"name"}
        name={"name"}
        required
      >
        <Input
          placeholder={"Schema Title"}
          onBlur={(e) => {
            titleForm.setFieldsValue({name: fixTitle(e.target.value)})
          }}
        />
      </Form.Item>
      <p>A Schema name takes the form of a CamelCase string. Anything you type will be automatically transformed if needed.</p>
      <Form.Item>
        {children}
      </Form.Item>
    </Form>
  </>
}

export default Title
