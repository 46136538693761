import {Switch} from "react-router";
import {Route} from "react-router-dom";
import {PreAuthOnly} from "./components/Auth/PreAuthOnly";
import {WithAuth} from "./components/Auth/WithAuth";
import {Dashboard} from "./containers/layouts/Dashboard";
import {LogIn} from "./containers/LogIn";
import {Register} from "./containers/Auth/Register";
import {MainLayout} from "./containers/layouts/MainLayout";
import React from "react";
import {ResetPassword} from "./containers/Auth/ResetPassword";
import {LostPassword} from "./containers/Auth/LostPassword";
import {User} from "./containers/User/User";
import {useReactiveVar} from "@apollo/client";
import {networkError} from "./graphql/type-policies/error";
import MinimalLayout from "./containers/layouts/MinimalLayout";
import {Button, Result} from "antd";

export const AppRoot = () => {

  const isNetworkError = useReactiveVar(networkError)

  if(isNetworkError === true) {
    return <MinimalLayout>
      <Result
        status={"error"}
        title={"Network Error"}
        subTitle={"A network error has occured. You may be offline."}
        extra={[
          <Button type={"primary"} onClick={() => location.reload()}>Reload Page</Button>
        ]}
      />
    </MinimalLayout>
  }

  return (<MainLayout>
    <Switch>
      <Route exact path={'/'} component={PreAuthOnly(LogIn)} />
      <Route path={'/dashboard'} component={WithAuth(Dashboard)} />
      <Route path={"/account"} component={WithAuth(User)}/>
      <Route path={"/login"} component={PreAuthOnly(LogIn)} />
      <Route path={"/register"} component={PreAuthOnly(Register)} />
      <Route path={"/reset-password/:specialCode"} component={PreAuthOnly(ResetPassword)} />
      <Route path={"/lost-password"} component={PreAuthOnly(LostPassword)} />
    </Switch>
  </MainLayout>)
}
