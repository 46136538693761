import {authTypeDefs} from "./auth";
import {eventityTypeDefs} from "./eventity";
import {orgTypeDefs} from "./org";
import {workspaceTypeDefs} from "./workspace";

export const typeDefs = [
  authTypeDefs,
  eventityTypeDefs,
  orgTypeDefs,
  workspaceTypeDefs,
]
