import React, {useEffect, useState} from "react";
import {TitleContainer} from "./style";
import {Spin, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";

interface I {
  title: string
  onChange(title: string): Promise<void>
  loading: boolean
}

const {Title} = Typography

const EditableTitle: React.FC<I> = ({title, onChange, loading, ...props}) => {
  const [text, setText] = useState<string>("")
  const [edit, setEdit] = useState<boolean>(false)
  useEffect(() => {
    setText(text)
  }, [title])
  return <Title
    level={3}
    style={{marginBottom: 0}}
    editable={{
      icon: loading? <Spin spinning/> : <EditOutlined />,
      onChange,
      editing: edit,
      onStart: () => setEdit(true),
      onCancel: () => setEdit(false),
      onEnd: () => setEdit(false),
      // triggerType: ["icon", ""]
    }}
  >{title}</Title>

}

export default EditableTitle
