import {useEffect, useMemo, useReducer, useState} from "react";
import {useLocation} from "react-router-dom";
import {useHistory} from "react-router";

const paramReducer = (state, action) => {
  switch (action.type) {
    case "set_param": {
      return {
        [action.name]: action.value,
        ...state,
      }
    }

    case "clear_param": {
      const s = {...state}
      delete s[action.name]
      return {...s}
    }
  }
}

const coerceParams = <T>(key, value, init: T, fp: any) => {

    if(key.includes("[")) return

    if (fp.hasOwnProperty(key)) {
      let final: any[]
      if (typeof fp[key] === "string") {
        final = [fp[key], value]
      } else {
        final = [...fp[key], value]
      }
      fp[key] = final
    } else {
      if(init) {
        if(typeof init[key] === "number") {
          fp[key] = Number(value)
        } else {
          fp[key] = value
        }
      } else {
        fp[key] = value
      }
    }

}

const useParamStore = <T = any>(init?: any) => {

  const {search} = useLocation()
  const history = useHistory()
  const [initialised, setInitialised] = useState<boolean>(false)

  const params = useMemo(() => new URLSearchParams(search), [search])

  const [finalParams, setFinalParams] = useState<T>({} as T)

  useEffect(() => {

    if(!initialised) {
      const initKeys = Object.keys(init)
      initKeys
        .filter((ik) => !params.has(ik))
        .forEach((ik) => {
          const val = init[ik]
          if(!val || val.length === 0) {
            params.delete(ik)
          } else {
            if (Array.isArray(val)) {
              val.forEach((v) => params.append(ik, v))
            } else {
              params.set(ik, val)
            }
          }
        })

      let fp: any = {}

      params
        .forEach((val, key) => {
          coerceParams<T>(key, val, init, fp)
        })

      setFinalParams(fp)

      history.replace({search: params.toString()})

      setInitialised(true)
    }

  }, [init, initialised])

  useEffect(() => {
    if(initialised) {
      const fp: any = {}
      params
        .forEach((val, key) => coerceParams<T>(key, val, init, fp))

      setFinalParams(fp)
    }
  }, [params, initialised])


  const setParam = (name: string, value: any) => {
    if(!value) {
      params.delete(name)
    } else {
      params.set(name, value)
    }
    history.replace({search: params.toString()})
  }

  const clearParam = (name: string) => {
    params.delete(name)
  }

  return {
    setParam,
    clearParam,
    params: finalParams,
  }
}

export default useParamStore
