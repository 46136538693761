import React, {useEffect} from "react";
import {Route, RouteComponentProps, Switch, useParams} from "react-router";
import {ViewWorkspace} from "./ViewWorkspace";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {Extensions} from "../Extensions";
import {AttachExtensions} from "../../components/Workspace/AttachExtensions";
import {ApiKeys} from "../ApiKeys/ApiKeys";
import KMS from "../KMS";
import Design from "../Design";
import Edit from "../Design/Edit";
import DeveloperSettings from "./DeveloperSettings";
import ComponentPage from "./Components/ComponentPage";
import {Eventity} from "../Eventity/Eventity";

interface Workspace extends RouteComponentProps {}

export const Workspace: React.FC<Workspace> = ({match}) => {
  const {workspaceId} = useParams<{workspaceId: string}>()

  useEffect(() => {
    currentWorkspaceId(workspaceId)
    return () => {
      currentWorkspaceId('')
    }
  }, [workspaceId])

  return(<>
    <Switch key={workspaceId}>
      {!!workspaceId && workspaceId.length > 1 && <Route exact path={match.url} component={ViewWorkspace} />}
      <Route path={`${match.url}/workspaceentity`} component={Eventity}/>
      <Route path={`${match.url}/extensions`} component={Extensions}/>
      <Route path={`${match.url}/apikeys`} component={ApiKeys} />
      <Route path={`${match.url}/build-eventity`} component={Design} />
      <Route path={`${match.url}/edit-eventity/:eventityName`} component={Edit}/>
      <Route path={`${match.url}/kms`} component={KMS} />
      <Route path={`${match.url}/playground`} render={() => <>Playground Not Implemented Yet</>} />
      <Route path={`${match.url}/developer-settings`} component={DeveloperSettings} />
      <Route path={`${match.url}/p`} component={ComponentPage}/>
    </Switch>
    {workspaceId && <AttachExtensions workspaceId={workspaceId} />}
  </>)
}
