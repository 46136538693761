import React from "react";
import {Link} from "react-router-dom";
import {Button} from "antd";

export interface IAction {
  readonly action: "link" | "button"
  onClick?(args: any, item?: any): void
  readonly url?: string
  readonly name: string
  readonly [key: string]: any
  readonly id: string
  onClickArgs?: any
}

export const ListAction: React.FC<IAction> = ({action, url, id, name, onClickArgs, onClick= () => void(0), ...props}) => {
  if (action === "link") {
    return (<Link to={`${url}/${id}`} {...props}>{name}</Link>)
  } else {
    return (<Button {...props} onClick={() => onClick(onClickArgs)}>{name}</Button>)
  }
}
