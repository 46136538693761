import fetch from 'cross-fetch'
import {ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache, NormalizedCacheObject} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {typeDefs} from "./type-defs";
import {AuthService} from "../service/auth";
import {getApiUri} from "../service/url";
import {fields} from "./type-policies";
import {currentWorkspaceId} from "./type-policies/workspace";
import {federationError, networkError as setNetworkError} from "./type-policies/error";
import {onError} from "@apollo/client/link/error";
import {AUTH_STATE, authState, loggedIn} from "./type-policies/auth";
import {message} from "antd";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields,
    }
  }
}).restore(typeof window !== "undefined"?(window as any).__APOLLO_STATE__:"{}");

export const authLink = setContext((_: any, {headers, ...context}) => {
  const authorization = AuthService.getAuthToken("Bearer")
  const xEventWorkspace = currentWorkspaceId()
  let newHeaders = {
    ...headers,
  }
  if(xEventWorkspace && xEventWorkspace !== '') {
    newHeaders = {
      ...newHeaders,
      "x-event-workspace": xEventWorkspace,
    }
  }
  return {
    headers: {
      ...newHeaders,
      authorization
    },
    ...context,
  }
})

const httpLink = createHttpLink({
  uri: `${getApiUri()}/graphql`,
  fetch,
})

let client: ApolloClient<NormalizedCacheObject>

export const mergeTypePolicies = () => {

}

export const removeTypePolicies = (types: string[]) => {

}

const federationLink = new ApolloLink((operation, next) => {
  const complete = next(operation)
    .map(response => {
      const context = operation.getContext()
      const {response: {headers}} = context
      if(headers.has("x-failed-federation")) {
        // const federror = JSON.parse(headers.get("x-failed-federation"))
        federationError(true)
      } else {
        federationError(undefined)
      }
      return response
    })

  return complete
})

const errorLink = onError(({ networkError, forward, operation, response, ...rest}) => {
  if(networkError) {
    console.log("Network Errors", networkError)
    if(networkError.message.includes("Network")) {
      message.error({
        content: `A Network error occured: ${networkError.message}`,
        key: "network-error-message"
      })
    }

    //   // A complete failure of some sort.
    //   setNetworkError(true)
    //   authState(AUTH_STATE.ERROR)
    // } else
    if(Object.prototype.hasOwnProperty.call(networkError, "response")) {
      // We're getting an error response rather than a failure
      const {response, result} = (networkError as any)
      console.log(response)
      let error = ""
      if(response.ok === false) {
        if(result && result.error) {
          error = result.error
        } else {
          error = networkError.message
        }

        if(error.toLowerCase().includes("unauthorized")) {
          message.error({
            content: `You have been logged out. Your session may have expired.`,
            key: "network-error-logout"
          })
          loggedIn(false)
          authState(AUTH_STATE.WAITING)
          AuthService.clearAuthToken()
          authState(AUTH_STATE.LOGGED_OUT)
        }
      }
    }
    // return forward(operation)
  } else {
    setNetworkError(false)
  }
})

export {client}

export const initClient = (): void => {
  client = new ApolloClient({
    cache,

    headers: {
      authorization: AuthService.getAuthToken("Bearer"),
    },
    typeDefs,
    ssrForceFetchDelay: 100,
    // link: authLink.concat(concat(concat(federationLink, httpLink), errorLink))
    link: from([authLink, errorLink, federationLink, httpLink])
  })
}

export const getClient = () => client
