import React from "react";
import {Button, Table} from "antd";
import {IEventity} from "../../service/eventity";
import { CheckOutlined } from '@ant-design/icons';

interface EventityTable {
  eventity: IEventity,
  remove?(name: string): void
  setMeta?(name: string, type: string): void
}

const {Column} = Table

export const EventityTable: React.FC<EventityTable> = ({eventity, remove, ...props}) => {

  // @ts-ignore
  const data = Object.keys(eventity.data).map((name) => ({...eventity.data[name], name}))

  let footer = undefined
  if(React.Children.count(props.children) > 0) {
    footer = () => (<>{props.children}</>)
  }

  return(
    <Table
      dataSource={data}
      pagination={false}
      footer={footer}
    >
      <Column title={'Required'} dataIndex={'@required'} key={'required'} align={'center'} render={(required) => {
        if(required) {
          return <CheckOutlined />
        }
        return []
      }} />
      <Column title={'Name'} dataIndex={'name'} key={'name'} />
      <Column title={'Type'} dataIndex={'@type'} key={'type'} />
      <Column title={'Min'} dataIndex={'min'} key={'min'} align={'center'}/>
      <Column title={'Max'} dataIndex={'max'} key={'max'} align={'center'}/>
      {!!remove && <Column align={"center"} dataIndex={"name"} render={(name) => {
        return <Button size={"small"} danger onClick={() => remove(name)}>Remove</Button>
      }}/>}
    </Table>
  )
}
