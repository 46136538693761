import React, {ReactNode, ReactNodeArray, useState} from "react";
import {useHistory} from "react-router";
import {Pagination, Select} from "antd";
import {useQuery, useReactiveVar} from "@apollo/client";

import {READ_WORKSPACES_PAGE} from "../../service/workspace";
import {formPaginatedResponse} from "../../service/pagination";
import {AUTH_STATE, authState} from "../../graphql/type-policies/auth";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";

const  {Option} = Select

interface WorkspaceList {
  orgId: string
  onSelect?(s: string): void
  workspaceId?: string
  Footer?: ReactNode | ReactNodeArray | any
}

export const WorkspaceList: React.FC<WorkspaceList> = ({orgId, Footer, onSelect, ...props}) => {
  const history = useHistory()

  const workspaceId = useReactiveVar(currentWorkspaceId)

  const currentAuthState = useReactiveVar(authState)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(6)

  const isLoggedIn = (): boolean => {
    return currentAuthState === AUTH_STATE.LOGGED_IN
  }

  const {
    data: {Organisation: org, Organisation: {workspaces, workspaces: {totalCount}}} = {
      Organisation: {
        workspaces: formPaginatedResponse({}),
      }
    }
  } = useQuery(READ_WORKSPACES_PAGE, {variables: {id: orgId, hasId: !!orgId}}) as any

  const selectWorkspace = (id: any): void => {
    if(onSelect) {
      onSelect(id)
    } else {
      if (id && id !== "-1") {
        currentWorkspaceId(id)
        const path = `/dashboard/organisation/${orgId}/workspace/${id}`
        history.push(path)
      }
    }
  }

  const onPageChange = (p, ps) => {
    if(p) {
      setPage(p)
    }
  }


  if(isLoggedIn()) {
    return (
      <Select
        size={"large"}
        dropdownMatchSelectWidth={false}
        style={{minWidth: "6rem"}}
        dropdownStyle={{width: "18rem"}}
        onSelect={(id: string) => {
          selectWorkspace(id)
        }}
        key={`select-${orgId}`}
        placeholder={"Workspaces"}
        value={workspaceId}
        dropdownRender={(menu) => (
          <div style={props.children ? {display: 'flex', flexDirection: 'column', paddingBottom: '1.4rem'} : {}}>
            {menu}
            <div style={{display: "flex"}}>
              <Pagination
                size={"small"}
                style={{margin: "0.5rem auto -0.5rem"}}
                current={page}
                defaultPageSize={limit}
                pageSize={limit}
                total={totalCount ?? 0}
                // showSizeChanger={true}
                // pageSizeOptions={pageSizeOptions}
                onChange={onPageChange}
              />
            </div>
            {props.children}
          </div>
        )}
      >
        {org && formPaginatedResponse(workspaces).entries.map((workspace: any) => (
          <Option key={workspace.id} value={workspace.id}>{workspace.name}</Option>))}
      </Select>
    )
  } else {
    return <></>
  }
}
