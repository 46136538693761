import React, {useEffect, useState} from "react";
import {FormItem} from "./FormItem";
import {Checkbox, Divider, Form} from "antd";
import {generateRules} from "./tools";

const CheckboxGroup = Checkbox.Group;

const MultipleChoiceItem: React.FC<FormItem> = ({name, label, required, data}) => {
  const [indeterminate, setIndeterminate] = useState(false)
  // const [checkedList, setCheckedList] = useState<string[]>([])
  const [options, setOptions] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [keys, setKeys] = useState([])
  useEffect(() => {
    if(!!data && data.hasOwnProperty("@meta") && data["@meta"].hasOwnProperty("function")) {
      const items = data["@meta"].function.items || []
      const opts = items.map((item: any) => item.value)
      setOptions(items)
      setKeys(opts)
    }
  }, [data])

  const onChange = (set: any, list: any[]) => {
    setIndeterminate(!!list.length && list.length < Object.keys(options).length)
    setCheckAll(list.length === Object.keys(options).length)
    set({[(name as any)]: list })
  }

  const onCheckAllChange = (set: any, e: any) => {
    const list = e.target.checked ? keys : []
    setIndeterminate(false)
    setCheckAll(e.target.checked)
    set({[(name as any)]: list })
  }

  return <><Form.Item
    shouldUpdate={(prev, curr) => {
      const nm = (name as string)
      if(!nm || !prev[nm]) {
        return true
      }
      return prev[nm] && prev[nm].length !== curr[nm].length
    }}
  >
    {({setFieldsValue, getFieldValue}) => {
      const checked = getFieldValue(name) ?? false
      return <>
        <h3>{label}</h3>
        <Checkbox indeterminate={indeterminate || (!!checked.length && checked.length !== Object.keys(options).length)} checked={checkAll || (checked.length > 0 && checked.length === Object.keys(options).length)} onChange={(e) => onCheckAllChange(setFieldsValue, e)}>
          Select All
        </Checkbox>
        <Divider />
        <CheckboxGroup value={checked} options={options} onChange={(list) => onChange(setFieldsValue, list)} />
      </>
    }}
  </Form.Item>
    <Form.Item
      noStyle
      rules={generateRules(data)}
      name={name}
      required={required}
    />
  </>
}

export default MultipleChoiceItem
