import React, {useEffect, useRef, useState} from "react";
import {Card, notification} from "antd";

interface I {
  tag: string
  client: any
  workspaceId: string
  context?: any
}

const Panel: React.FC<I> = ({tag, workspaceId, client, context = {}}) => {
  let ref = useRef<HTMLDivElement>(null);
  const [componentMounted, setComponentMounted] = useState(false)
  const [coreNotification, ContextHolder] = notification.useNotification()

  useEffect(() => {
    if(!componentMounted) {
      const c = document.createElement(tag) as any
      ref.current.appendChild(c)
      setComponentMounted(true)
    }
  }, [tag, componentMounted])

  useEffect(() => {
    if(componentMounted && ref.current) {
      const webc = (ref.current.querySelector(tag) as any)
      webc.workspaceId = workspaceId
      webc.client = client
      webc.coreNotification = coreNotification
    }
  }, [componentMounted])

  useEffect(() => {
    if(componentMounted && ref.current) {
      const webc = (ref.current.querySelector(tag) as any)
      webc.context = context
    }
  }, [context])

  return <Card>
    {ContextHolder}
    <div ref={ref} />
  </Card>
}

export default Panel
