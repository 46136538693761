import React, {useState} from "react";
import {FormItem} from "./FormItem";
import {Form, Space, Switch} from "antd";
import {EventityTree} from "../Eventity/EventityTree";
import loadable from "@loadable/component"
// import RawEditor from "../../containers/Eventity/Components/RawEditor";
const RawEditor = loadable(() => import("../../containers/Design/Components/RawEditor"), {
  fallback: <></>
})

interface SchemaItem extends FormItem {}

export const SchemaItem: React.FC<SchemaItem> = ({name, label, data, required, ...props}) => {

  const [editDirect, setEditDirect] = useState(false)

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) => {
        if(editDirect) return false
        if (Array.isArray(name)) {

          return prevValues[name[1]] !== curValues[name[1]]
        } else {
          return prevValues[name] !== curValues[name]
        }
      }}
    >
      {({getFieldValue, setFieldsValue}) => {
        const schemaItem = getFieldValue(name) || {}
        return (
          <>
            <Space direction={"vertical"} style={{width: "100%"}}>
            <Switch onClick={() => setEditDirect((!editDirect))} checked={!editDirect} unCheckedChildren={"Raw Editor"} checkedChildren={"Smart Editor"} />
            <Form.Item
              name={name}
              noStyle
            />
            {!editDirect &&
            <EventityTree
              editable
              eventity={{data: schemaItem} as any}
              updateSchema={(data) => {
                // const schemaData = getFieldValue(name)
                if (Array.isArray(name)) {
                  setFieldsValue({[name[1]]: undefined})
                  setFieldsValue({[name[1]]: data})
                } else {
                  setFieldsValue({[name]: undefined})
                  setFieldsValue({[name]: data})
                }
              }}/>}
            {editDirect && <RawEditor editable data={schemaItem} updateSchema={(data) => {
              if (Array.isArray(name)) {
                setFieldsValue({[name[1]]: undefined})
                setFieldsValue({[name[1]]: data})
              } else {
                setFieldsValue({[name]: undefined})
                setFieldsValue({[name]: data})
              }
            }} /> }
            </Space>
          </>

        )
      }}
    </Form.Item>
  )
}
