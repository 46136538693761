import React from "react";
import {Table} from "antd";
import {toTitleCase} from "../../service/string";

interface ObjectOutput {
  name: string
  data: any
}

// @ts-ignore
export const ObjectOutput: React.FC<ObjectOutput> = ({name, data}) => {
  const columns = [
    {dataIndex: "name", key: "name"},
    {dataIndex: "val", key: "val"}
  ]

  let keyedData = []

  if (data) {
    keyedData = Object.keys(data).map((name: any, id: any) => ({key: id, name: toTitleCase(name), val: data[name]}))
  }

  return <div style={{border: "1px solid #cacaca", padding: "0.5rem", marginBottom: "0.5rem", width: "100%"}}>
    {!!name && <h3>{toTitleCase(name)}</h3>}
    <>
      <Table dataSource={keyedData} columns={columns} showHeader={false} style={{width: "100%"}} pagination={false}/>
    </>
  </div>
}
