import React, {useEffect, useState} from "react";
import {ChangePasswordForm} from "../../components/Auth/ChangePasswordForm";
import {Col, Result, Row, Spin} from "antd";
import {useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import {CHECK_RESET_CODE, RESET_PASSWORD} from "../../graphql/queries/auth";
import { Link } from "react-router-dom";
import {onError} from "@apollo/client/link/error";

interface ResetPassword {}

enum FormState {
  START,
  INVALID,
  FAIL,
  SUCCESS,
}

export const ResetPassword:React.FC<ResetPassword> = () => {
  const {specialCode} = useParams() as any
  const [formState, setFormState] = useState(FormState.START)
  const initialError = {state: false, message: ""}
  const [error, setError] = useState(initialError)
  const [initialLoad, setInitialLoad] = useState(false)
  const {data: {CheckResetCode: {success: codeCheckSuccess}} = {CheckResetCode: {success: false}}, loading = true}= useQuery(
    CHECK_RESET_CODE,
    {
      variables: {
        code: specialCode
      },
      onCompleted({CheckResetCode: {success}}) {
        if(!success) {
          setFormState(FormState.INVALID)
        }
        setInitialLoad(true)
      }
    }
  )

  const [resetPassword] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted({ResetPassword: {success, message}}) {
        if(!!success) {
          setError(initialError)
          setFormState(FormState.SUCCESS)
        } else {
          setError({state: true, message})
        }
      },
      onError(data) {
        console.log(data)
      }
    }
  )

  if(!initialLoad) {
    return (<div style={{display: "flex", justifyContent: "center"}}><Spin spinning size={"large"} /></div>)
  }

  if(formState === FormState.INVALID) {
    return (
      <Result
        title={"Invalid or Expired Code"}
        status={"error"}

      />
    )
  }

  if(formState === FormState.SUCCESS) {
    return (
      <Result
        title={"Reset Successful"}
        subTitle={"You have successfully reset your password and can now log in using your new details."}
        extra={<><Link to={"/login"}>Log In Now</Link></>}
      />
    )
  }

  return (<>
    <Row justify={"center"}>
      <Col xs={"auto"}>
        <ChangePasswordForm error={error} onFinish={(data: any) => resetPassword({variables: {...data, code: specialCode}})} />
      </Col>
    </Row>
  </>)
}
