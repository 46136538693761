import styled from "styled-components";

export const ComponentContainer = styled.div`
  margin: -2rem;
  min-height: calc(100% + 4rem);
  display: flex;
  align-items: stretch;
  &>* {
    width: 100%;
  }
`
