import React from "react";
import {Alert, Button, Form, Input} from "antd";
import {useForm} from "antd/lib/form/Form";

interface ChangePasswordForm {
  onFinish(data: any): void
  error?: any
  resetError?(): void
}

export const ChangePasswordForm: React.FC<ChangePasswordForm> = ({onFinish, error, resetError = () => void(0)}) => {
  const [form] = useForm()

  const compareToNext = async (rule: any, value: any): Promise<void> => {
    const nextIsTouched = form.isFieldTouched("passwordCompare")
    if (value && nextIsTouched) {
      await form.validateFields(["passwordCompare"])
    }
  }
  return (<>
    <Form
      form={form}
      onFinish={(data) => {
        onFinish(data)
      }}
      validateTrigger={"onBlur"}
    >
      <Form.Item
        name={"password"}
        rules={[
          {required: true},
          ({getFieldValue}) => ({
            validator(rule, value) {
              if(!value || getFieldValue("passwordCompare") === value) {
                return Promise.resolve()
              } else {
                form.validateFields(["passwordCompare"])
                return Promise.reject("Passwords must match")
              }
            }
          })
        ]}
      >
        <Input placeholder={"Password"}/>
      </Form.Item>
      <Form.Item
        name={"passwordCompare"}
        rules={[
          {required: true},
          ({getFieldValue}) => ({
            validator(rule, value) {
              if(!value || getFieldValue("password") === value) {
                return Promise.resolve()
              } else {
                return Promise.reject("Passwords must match")
              }
            }
          })
          // {validator: () => {}}
        ]}
      >
        <Input placeholder={"Repeat Password"}/>
      </Form.Item>
      <Form.Item>
        <Button htmlType={"submit"} type={"primary"}>Submit</Button>
      </Form.Item>
    </Form>
      {(error && error.state) && <Alert type={"warning"} message={error.message}/>}
    </>
  )
}
