import React from "react";
import {Helmet} from "react-helmet";
import {getApiUri} from "../../service/url";

interface ExtensionComponents {
  extension: any
  workspaceId: string
}

export const ExtensionComponents: React.FC<ExtensionComponents> = ({extension, workspaceId}) => {
  const {id, namespace, webcomponents, version} = extension
  return (<>
      {webcomponents.map((component: any) => {
        const encodedURL = btoa(component)
        const path = `${getApiUri()}/extension/${workspaceId}/${namespace}.${id}/webc/${encodedURL}?v=${version}`
        return <Helmet
          key={`helm-${id}-${component.name}`}
        >
          <script
          key={`scr-${id}`}
          src={path} />
        </Helmet>
      })}

  </>)
}
