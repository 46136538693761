import {gql} from "@apollo/client";
import {JoiTypeDef} from "./joi";
import {toTitleCase} from "./string";
import {getClient} from "../graphql/client";

export const eventityTypes = [
  "any",
  "string",
  "number",
  "boolean",
  "date",
  "binary",
  "email",
  "uuid",
  "object",
  "array",
  // "alternatives",
];

export interface IEventityInFlight {
  readonly data: {
    readonly EventityInFlight: any,
  }
}

export const GET_EVENTITY_IN_FLIGHT = gql`
  query getEventityInFlight {
    EventityInFlight @client
  }
`

export interface IEventityFieldData extends JoiTypeDef {
}

export interface IEventity {
  readonly name: string
  readonly data: IEventityFieldData
}

export const hasMin = (type: string): boolean => {
  const noMins = [
    'boolean',
    'date',
    'any'
  ]
  if(!type) return false;
  return !noMins.includes(type)
}

export const hasMax = (type: string): boolean => {
  const max = [
    'boolean',
    'date',
    'any'
  ]
  if(!type) return false;
  return !max.includes(type)
}

export const isTitle = (root: EventityDataTree, itemName: string) => {
  const meta = root.getMeta()
  if(!!meta) {
    return meta.hasOwnProperty("titleField") && meta.titleField === itemName
  } else {
    return false
  }
}

export class EventityDataTree {
  private children: EventityDataTree[] = []
  constructor(public name: string, public data?: any) {
    if(!this.name) this.name = "unnamed_field"
    if(data.hasOwnProperty("@type") && data["@type"] === "object") {
      Object.keys(data)
        .filter((key) => key.charAt(0) !== "@" && key !== "min" && key !== "max")
        .map((key) => this.createChild(key, data[key]))
    }
  }

  createChild(name: string, data: any): EventityDataTree {
    const idx = this.children.findIndex((child) => child.name === name)
    // If a child is added, we need to become an object
    if(this.data["@type"] !== "object") {
      let meta = this.data["@meta"]
      if(!!meta && meta.hasOwnProperty("function")) {
        delete meta.function
      }
      this.data = {"@type": "object", "@required": this.data["@required"] || false, "@meta": {...meta}}
    }
    const child = new EventityDataTree(name, data)
    if(idx === -1) {
      this.children.push(child)
    } else {
      this.children[idx] = child
    }
    return child;
  }

  hasChild(name: string): boolean {
    const idx = this.children.findIndex((child) => child.name === name)
    if(idx !== -1) {
      return true
    }
    return false
  }

  update(name: string, data: any): void {

  }

  public addMeta(name: string, data: any): void {
    let meta = {}
    if(this.data.hasOwnProperty("@meta")) {
      meta = this.data["@meta"]
    }
    this.data["@meta"] = {...meta, [name]: data}
  }

  public removeMeta(name: string): void {
    const keys = Object.keys(this.data["@meta"])
    const idx = keys.findIndex((key) => key === name)
    if(idx !== -1) {
      keys.splice(idx, 1)
      let meta: any = {}
      keys.forEach((key) => meta[key] = this.data["@meta"][key])
      if(Object.keys(meta).length > 0) {
        this.data["@meta"] = meta
      } else {
        delete this.data["@meta"]
      }
    }
  }

  public getMeta() {
    return this.data["@meta"]
  }

  removeChild(name: string) {
    const idx = this.children.findIndex((child) => child.name === name)
    if(idx !== -1) {
      delete this.data[name]
      this.children.splice(idx, 1)
    }
  }

  /**
   * Extract a static JOI JSON schema representation
   */
  public extractJOI() {
    if(this.children.length > 0) {
      let ret: any = {
        ...this.data,
        "@type": "object",
      }
      this.children.forEach((child) => {
        ret = {...ret, ...child.extractJOI()}
      })
      return {[this.name]: ret}

    } else {
      return {[this.name]: this.data}
    }
  }

  /**
   * Extract an antd Tree data object
   */
  public extractTree(parent: string = ""): any {
    const key = !!parent ? `${parent}/${this.name}`: this.name
    if (this.name === "root") {
      return this.children
        .filter((k) => k.name.charAt(0) !== "@")
        .map((child) => child.extractTree(key))
    } else {
      return(
        {
          title: toTitleCase(this.name),
          key,
          children: this.children
            .filter((k) => k.name.charAt(0) !== "@")
            .map((child) => child.extractTree(key))
        })
    }
  }

  public find(path: string[]): EventityDataTree | undefined {
    if(path[0] === this.name) {
      if(path.length > 1) {
        const set: any = this.children.find((child) => !!child.find(path.slice(1)))
        if(set) {
          return set.find(path.slice(1))
        } else {
          return undefined
        }
      } else {
        return this
      }
    }
    return undefined
  }

  findParentPath(path: string[]): string[] {
    if(path.length <= 1) {
      return []
    }
    const up = path.slice(0, -1)
    return up
  }
}

export const clearEventityCache = () => {
  const client = getClient()
  const state = client.extract()
  Object.keys(state)
    .filter((key) => key.includes("WorkspaceEntity:"))
    .forEach((key) => client.cache.evict({id: key}))

}
