import React, {useEffect} from "react";
import {useReactiveVar} from "@apollo/client";
import {useHistory} from "react-router";
import {AUTH_STATE, authState} from "../../graphql/type-policies/auth";


interface WithAuth {}

export const WithAuth = (Component: any) => {
  const C: React.FC<WithAuth> = (props) => {
    const currentAuthState = useReactiveVar(authState)

    const loggedIn = (): boolean => {
      return currentAuthState === AUTH_STATE.LOGGED_IN
    }
    const history = useHistory();
    useEffect(() => {
      if(!loggedIn() && currentAuthState !== AUTH_STATE.WAITING) {
        history.push("/login");
      }
    }, [currentAuthState, history]);

    if(loggedIn()) {
      return <Component {...props} />
    } else {
      return <></>
    }
  }

  return C;
}
