import React from "react";
import {gql, useLazyQuery, useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {ExtensionResult, READ_EXTENSIONS, READ_INSTALLED_EXTENSIONS} from "../../graphql/queries/extensions";
import {Button, Space, Spin, Typography} from "antd";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import ExtensionList from "./Components/ExtensionList";
import {LeftCircleTwoTone} from "@ant-design/icons";
import {Link} from "react-router-dom";
import Extensions from "../../service/extensions";
import ManifestEditor from "./Components/ManifestEditor";
import {ContentHeader} from "../layouts/style";
import {useHistory} from "react-router";
import {currentOrgID} from "../../graphql/type-policies/orgs";

const {Title} = Typography
interface ListExtensions {}

export const ListExtensions: React.FC<ListExtensions> = () => {
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const organisationId = useReactiveVar(currentOrgID)
  const {push} = useHistory()

  const {data: {ListExtensions: {results: extensions, page, query}} = {ListExtensions: {results: [], page: 1, query: ""}}, loading, refetch} = useQuery<{ListExtensions: ExtensionResult}>(
    READ_EXTENSIONS,
    {
      fetchPolicy: "no-cache"
    }
  )

  const [readExtensions, {loading: eLoading}] = useLazyQuery(
    READ_INSTALLED_EXTENSIONS,
    {
      fetchPolicy: "network-only",
    }
  )

  const [reloadExtensions, {loading: rLoading}] = useMutation(
    gql`
      mutation reload {
        ReloadExtensions {
          success
        }
      }
    `,
  )

  return (<>

      <ContentHeader
        title={"Extensions"}
        onBack={() => push(`/dashboard/organisation/${organisationId}/workspace/${workspaceId}/extensions`)}
        extra={<>
          <Button onClick={() => reloadExtensions()}>Reload</Button>
        </>}
      />
      <Spin spinning={(loading || rLoading)}>
        <ExtensionList extensions={extensions} workspaceId={workspaceId} onComplete={() => {
          refetch()
            .then(() => {
              readExtensions()
              Extensions().reloadWorkspaceComponents(workspaceId)
            })
        }} />
      </Spin>
    </>
  )
}

