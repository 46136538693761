import styled from "styled-components";
import {Card} from "antd";

export const StreamItem = styled.div`
  padding: 0.5rem;
  border: 1px solid #c0c0c0;
  background-color: #fff;
  cursor: pointer;
`

export const StreamContainer = styled.div`
  display: grid;
  grid-template-columns: 25rem 1fr;
  width: 100%;
  grid-gap: 1rem;
`

export const StreamEvent = styled(Card)`

`
