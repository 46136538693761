import React from "react";
import {Switch, Route, match} from "react-router";
import {Home} from "../Dashboard/Home";
import {Organisation} from "../orgs/Organisation";
interface Dashboard {
  match: match
}

export const Dashboard: React.FC<Dashboard> = ({match}) => {



  return (<>
    <Switch>
      <Route exact path={match.url} component={Home}/>
      <Route path={`${match.url}/organisation/:orgId`} component={Organisation} />
    </Switch>
  </>
  );
}
