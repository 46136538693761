import {gql} from "@apollo/client";

export const READ_ORG = gql`
  query readOrg($id: String! $hasId: Boolean!) {
    sys(id: $id) @include(if: $hasId) {
      id name
    }
  }
`

export const READ_ORG_ACCOUNTS = gql`
  query readOrgAccounts($id: String!) {
    sys(id: $id) {
      accounts {
        name id email
      }
    }
  }
`

export const ADD_ACCOUNT_TO_ORG = gql`
  mutation AddAccountToOrganisation($email: String!, $orgId: String!) {
    AddAccountToOrganisation(email: $email, organisationId: $orgId) {
      status
    }
  }
`

export const READ_ORG_USER = gql`
  query ReadOrganisationAccount($accountId: String!, $orgId: String!) {
    ReadOrganisationAccount(accountId: $accountId, organisationId: $orgId) {
      name id email
    }
  }
`

export const UPDATE_ORG = gql`
  mutation UpdateOrganisation($organisationId: String! $name: String!) {
    UpdateOrganisation(organisationId: $organisationId name:  $name) {
      success
    }
  }
`
