import React from "react";
import {List, message, Typography} from "antd";
import {CopyToClipboard} from "react-copy-to-clipboard";

interface ApiKeyDetail {
  readonly secret?: string
  readonly types: string[]
  readonly methods: string[]
  readonly name: string
  readonly client_id: string
  readonly loading?: boolean
}

const {Item, Item: {Meta}} = List

const {Text, Title} = Typography

export const ApiKeyDetail: React.FC<ApiKeyDetail> = ({secret, types = [], methods = [], name, client_id, loading}) => (

  <List
    header={<Title level={5}>{name}</Title>}
    itemLayout={"vertical"}
  >
    {!loading && <>{!!secret && <Item>
      <Meta
        title={"Secret"}
        description={"Be sure to copy the secret and keep it safe. You will not be able to retrieve it again."}
      />
      <Text code copyable>{secret}</Text>
    </Item>}
      <Item>
        <Meta
          title={"Types"}
        />
        {!loading && <Text code>{types.join(', ')}</Text>}
      </Item>
      <Item>
        <Meta
          title={"Mutations and Queries"}
        />
        {!loading && <Text code>{methods.join(', ')}</Text>}
      </Item>
      <Item>
        <Meta title={"Client ID"} description={"Click on the code to copy to your clipboard."}/>
        {!loading && <CopyToClipboard text={client_id} onCopy={() => message.info("API Key copied to clipboard!")}><Text style={{cursor: "pointer"}} title={"Click to Copy"} code>{client_id}</Text></CopyToClipboard>}
      </Item></>}
  </List>
)
