export interface JoiMetaData {
  noDelete?: boolean
  readOnly?: boolean
  [key: string]: any | any[]
}

export interface JoiTypeDef {
  '@type': string
  '@string'?: string
  min?: number
  max?: number
  '@required'?: boolean
  '@meta'?: JoiMetaData
  items?: JoiTypeDef | JoiTypeDef[]
}

export interface JoiSchema {
  '@meta'?: JoiMetaData
  [key: string]: JoiTypeDef | JoiSchema | JoiMetaData | string | number | undefined
}

export const levelOnekeys = (schema: JoiSchema) => Object.keys(schema)
  .filter((key) => key.charAt(0) !== "@" && key !== "min" && key !== "max")
  // @ts-ignore
  .filter((key) => !!schema[key] && schema[key]["@type"] !== "object" && schema[key]["@type"] !== "boolean" && schema[key]["@type"] != "any")

export const orderFields = (schema: JoiSchema, fields: string[]): string[] => {
  let l1fields = [...fields]
  let tField = l1fields[0] ?? ""
  if(schema.hasOwnProperty("@meta") && schema["@meta"].hasOwnProperty("titleField")) {
    tField = schema["@meta"].titleField
    const find = l1fields.findIndex((f) => f === tField)
    if(find !== -1) {
      l1fields.splice(find, 1)
      l1fields = [
        tField,
        ...l1fields
      ]
    }
  }
  return l1fields
}

/**
 * Ported from Core. This should be in a common lib really.
 * @param response
 * @param schema
 * @param list
 */
export const conformResponseToSchema = (response: any, schema: any, list: boolean = false): any => {
  if(!response) {
    if(list) {
      return []
    } else {
      return conformResponseToSchema({}, schema)
    }
  }
  if(list || Array.isArray(response)) {
    return response
      .map((res) => conformResponseToSchema(res, schema))
      .filter((res) => Object.keys(res).length > 0)
  } else {
    let ret: any = {}
    const sKeys = Object.keys(schema)
      .filter((key) => key.charAt(0) !== "@" && key !== "min" && key !== "max")

    sKeys
      .filter((key) => Object.hasOwnProperty.call(schema[key], "@type"))
      .forEach((key) => {
      const type = schema[key]["@type"]
      if(Object.hasOwnProperty.call(response, key)) {
        if (type === "object") {
          ret[key] = conformResponseToSchema(response[key], schema[key])
        } else {
          if(type === "object") {
            ret[key] = conformResponseToSchema({}, schema[key])
          } else {
            ret[key] = response[key]
          }
        }
      } else {
        ret[key] = typeDefault(schema[key])
      }
    })

    if(response.hasOwnProperty("id")) {
      return {
        id: response.id,
        ...ret
      }
    } else {
      return ret
    }
  }
}

const typeDefault = (typeDef: any) => {
  switch (typeDef["@type"]) {
    case "array": {
      return []
    }
    case "object": {
      return {}
    }
    case "number": {
      return 0
    }
    case "boolean": {
      return false
    }
    case "string":
    default: {
      return "undefined"
    }
  }
}
