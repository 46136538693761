import React, {useEffect, useState} from "react";
import {Form} from "antd";
import {IMetaType, metaTypeMap} from "../../components/Form/tools";
import {useForm} from "antd/lib/form/Form";

interface EventityMetaBuilder {
  updateMetaData(data: any, otherData: any, items?: any): void
  type: string
  // metaForm: any
}

export const EventityMetaBuilder: React.FC<EventityMetaBuilder> = ({updateMetaData, type}) => {

  const [metaForm] = useForm()

  const [metaType, setMetaType] = useState<IMetaType | any>({} as any)

  const formatMetaData = (data: any) => {
    // const func = type
    let parts = {}
    // const metaType = metaTypeMap.get(type) || {}
    if(metaType.hasOwnProperty("isMetaType")) {
      parts = {metatype: type}
    }

    Object.keys(data)
      .forEach((item) => {
        if (item.includes(`${type}:`)) {
          const name = item.split(":")[1]
          parts = {...parts, [name]: data[item]}
        }
      })
    if(!parts || Object.keys(parts).length === 0) {
      return undefined
    } else {
      return {function: parts}
    }
  }

  useEffect(() => {
    metaForm.resetFields()
    clearAllFields()
    if(metaTypeMap.has(type)) {
      const mt = metaTypeMap.get(type) || {}
      setMetaType(mt)
      if(mt.hasOwnProperty("isMetaType")) {
        metaForm.setFieldsValue({type})
        return
      }
    }
    metaForm.setFieldsValue({type: ""})

  }, [type])

  const filteredMetaTypes = () => {
    const metaTypes = Array.from(metaTypeMap.entries())
      .filter((metaType) => metaType[1].type.includes(type))
    return new Map(metaTypes)
  }

  const updateTypeFields = (fields: any, otherFields: any = {}): void => {
    metaForm.setFieldsValue({...fields, ...otherFields})
    const formattedData = formatMetaData(fields)

    updateMetaData(formattedData, otherFields)
  }

  const clearAllFields = () => {
    const allFields = metaForm.getFieldsValue()
    let data = allFields
    Object.keys(allFields)
      .filter((field) => field.includes(`${type}:`) && field.includes(':'))
      .forEach((field) => {
        delete data[field]
      })
    data = {...data}
    const formattedData = formatMetaData(data)
    updateMetaData(formattedData, {})
  }

  return (<>
    <Form
      form={metaForm}
      onValuesChange={(fields, allFields) => {
        updateTypeFields(allFields)
      }}
    >
      <Form.Item
        noStyle
        shouldUpdate={(prev,curr) => {
          return prev["type"] !== curr["type"]
        }}
      >
        {({getFieldValue, setFieldsValue}) => {
          const func = getFieldValue("type")
          if (func && metaTypeMap.has(func)) {
            const config = metaType
            if (config.hasOwnProperty("build")) {
              return <config.build name={func} updateFields={updateTypeFields} required />
            }
          }
        }}
      </Form.Item>
    </Form>
  </>)
}
