import React from "react";
import {message, Popover, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useMutation} from "@apollo/client";
import {UPLOAD_MANIFEST} from "../../../graphql/queries/extensions";

interface IUploadExtension {
  onComplete(): Promise<void>
}

const UploadExtension: React.FC<IUploadExtension> = ({onComplete}) => {

  const [uploadManifest, {loading}] = useMutation(
    UPLOAD_MANIFEST
  )

  return <Upload
    accept={".json"}
    showUploadList={false}
    beforeUpload={(file) => {
      const reader = new FileReader()
      reader.onload = () => {
        const manifest = JSON.parse(reader.result as string)
        uploadManifest({variables:{manifest, configuration: {}}})
          .then(() => {
            onComplete()
            message.success("Manifest Uploaded")
          })
          .catch((e) => console.log(e))
      }
      reader.readAsText(file)
      return false
    }}
  >
    <Popover content={"Upload Custom Extension Manifest"}><UploadOutlined title={"Upload Extension Manifest"}/></Popover>
  </Upload>
}

export default UploadExtension
