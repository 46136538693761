import React, {useState} from "react";
import {Button, Form, Input, Modal} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {useInput} from "../../hooks/UseInput";
import {useMutation} from "@apollo/client";
import {CREATE_WORKSPACE} from "../../service/workspace";

interface CreateWorkspace {
  orgId: string
  onComplete?(): void
}

export const CreateWorkspace: React.FC<CreateWorkspace> = ({orgId, onComplete, ...props}) => {
  const [visible, setVisible] = useState(false)
  const [name, bindName, resetName] = useInput("")

  const [createWorkspace, {loading}] = useMutation(
    CREATE_WORKSPACE,
    {
      onCompleted({CreateWorkspace = {id: ''}}) {
        if (CreateWorkspace.id) {
          setVisible(false)
          resetName()
          if(onComplete) {
            onComplete()
          }
        }
      },
      onError(e) {
        console.log(e)
      }
    }
  )

  const makeCreateWorkspace = () =>{
    createWorkspace({
      variables: {
        input: {
          organisationId: orgId,
          name,

        },
      },
    })
      .catch(e => console.error(e))
  }

  return (<>
    <Button
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        padding: '8px',
        alignItems: 'center',
        cursor: 'pointer',
        margin: 'auto'
      }}
      onClick={() => {
        setVisible(true)
      }}
    >
      <span style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer', lineHeight: 0}}>
        <PlusOutlined/>
      </span>
      <span>Create New Workspace</span>

    </Button>
    <Modal
      title={"Create New Workspace"}
      visible={visible}
      onOk={() => makeCreateWorkspace()}
      confirmLoading={loading}
      zIndex={2020}
      onCancel={() => {
        setVisible(false)
        resetName()
      }}
    >
      <div>
        <Form
          onFinish={() => makeCreateWorkspace() }
        >
          <Input name={"workspaceName"} {...bindName} autoFocus={true}/>
        </Form>
      </div>
    </Modal>
  </>)
}
