import React, {useState} from "react";
import {Button, message, Modal} from "antd";
import {GenerateFromJoi} from "../../../components/Joi/GenerateFromJoi";
import {useMutation} from "@apollo/client";
import {CREATE_KEY, LIST_KEYS} from "../../../graphql/queries/kms";

interface GenerateKey {
  onComplete?(): void
}

const GenerateKey: React.FC<GenerateKey> = ({onComplete}) => {
  const [visible, setVisible] = useState(false)

  const formConfig = {
    name: {
      "@type": "string",
      "@required": true,
    }
  }

  const [createKey, {loading}] = useMutation(
    CREATE_KEY,
    {

    }
  )

  return <>
    <Modal
      visible={visible}
      footer={null}
      title={"Generate New Key"}
      onCancel={() => setVisible(false)}
    >
      <GenerateFromJoi
        schema={formConfig}
        execute={(variables) => {
          createKey({variables})
            .then(() => {
              if(onComplete) {
                onComplete()
              }
              setVisible(false)
            })
            .catch((e) => message.error(e.message))
        }}
        cancel={() => setVisible(false)}
      />
    </Modal>
    <Button
      loading={loading}
      type={"primary"}
      onClick={() => setVisible(true)}
    >
      Generate Encryption Key
    </Button>
  </>
}

export default GenerateKey
