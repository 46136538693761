import React, {useCallback, useEffect, useState} from "react";
import {JoiSchema} from "../../../service/joi";
import {Card, Input, Select} from "antd";
import SimpleFieldSelect from "./SimpleFieldSelect";
import {debounce} from "throttle-debounce";
import {FiltersAction, FiltersExtra, FiltersExtraBody} from "./style";
import {toTitleCase} from "../../../service/string";
import {FilterOutlined} from "@ant-design/icons"


interface FilterExtra {
  type: "fields" | "filter"
}

interface I {
  schema?: JoiSchema
  fields: string[]
  titleField: string
  onChange?(filters: any[]): void
  onFilter?(filters: any[]): void
  onColumnChange?(fields: string[]): void
}

const functionProps = ["onFilter", "onColumnChange"]

const cardProps = {
  style: {marginBottom: "1rem", padding: 0},
  bodyStyle: {padding: "1rem"}
}

const FilterTools: React.FC<I> = ({fields, titleField, onFilter, onColumnChange}) => {

  const [filterField, setFilterField] = useState<string>(titleField)
  // const [baseFields, setBaseFields] = useState<string[]>([])
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
  const [selectedFields, setSelectedFields] = useState<string[]>([])
  const [fieldOpts, setFieldOpts] = useState<any[]>([])

  const [filterText, setFilterText] = useState<string>()

  const [initialised, setInitialised] = useState<boolean>(false)

  const isOnlyFilter = (): boolean => {
    const funcs = [
      // onFilter,
      onColumnChange
    ]
    const ret = funcs.filter((f) => (typeof f === "function")).length
    return ret === 0
  }

  const debounceFilterText = useCallback(
    // @ts-ignore
    debounce(500, (input) => {
      if (!!filterField && !!input) {
        onFilter([
          {field: filterField, value: {op: "LIKE", value: {like: input, path: [filterField]}}}
        ])
      } else {
        onFilter([])
      }
    }),
    [filterField]
  )

  useEffect(() => {
    if(initialised && filterField && typeof filterText !== "undefined") {
      debounceFilterText(filterText)
    }
  }, [filterText, filterField, initialised])

  useEffect(() => {
    onColumnChange && onColumnChange(selectedFields)
  }, [selectedFields])

  useEffect(() => {
    if (fields) {
      const fo = fields
        .filter((f) => f !== titleField)
        .map((f) => ({label: toTitleCase(f), value: f}))
      setFieldOpts(fo)
      setInitialised(true)
    }
  }, [fields, initialised])

  if(isOnlyFilter()) {
    return <Card {...cardProps}>
      <Input
        addonBefore={fields.length > 1 && <SimpleFieldSelect selected={filterField} fields={fields} onChange={setFilterField}/>}
        onChange={(e) => {
          setFilterText(e.target.value)
        }}
        allowClear
      />
    </Card>
  }

  return <Card {...cardProps}>
    <Input
      placeholder={"Type to filter"}
      addonBefore={fields.length > 1 && <SimpleFieldSelect selected={filterField} fields={fields} onChange={setFilterField}/>}
      onChange={(e) => {
        setFilterText(e.target.value)
      }}
      allowClear
    />
      <FiltersExtra open={filtersOpen}>
        <FiltersExtraBody>

          {onColumnChange && typeof onColumnChange === "function" && <Select
            mode={"multiple"}
            style={{width: "100%"}}
            value={selectedFields}
            options={fieldOpts}
            onChange={(value) => {
              setSelectedFields(value.filter((f) => f !== titleField))
            }}
            placeholder={"Select columns to hide"}
            maxTagCount={"responsive"}
          />}
        </FiltersExtraBody>
      </FiltersExtra>
    <FiltersAction onClick={() => setFiltersOpen(!filtersOpen)}>
      <FilterOutlined/> <h3 style={{margin: 0}}>More Filters</h3>
    </FiltersAction>
    </Card>
}

export default FilterTools
