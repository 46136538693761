import React from "react";
import {Select} from "antd";
import {metaTypeMap} from "../Form/tools";

const {Option} = Select

interface TypeList {
  setSelection(s: any): void
  selected?: any
  disabled?: boolean
}

export const TypeList: React.FC<TypeList> = ({setSelection, selected, disabled = false}) => {

  return (<Select
      onSelect={(s) => setSelection(s)}
      placeholder={"Type"}
      style={{minWidth: "16rem"}}
      value={selected}
      disabled={disabled}
    >
      {Array.from(metaTypeMap.entries()).map(([type, data]) => <Option key={type}
                                           value={type}>{data.name}</Option>)}
    </Select>
  )
}
