import React from "react";
import {Button} from "antd";
import AddChild from "./AddChild";

interface I {
  node: string[]
  onChange(node: string[], data: any): void
}

const NodeActions: React.FC<I> = ({node, onChange}) => {
  return <div style={{padding: "0.5rem 0"}}>
    <Button onClick={() => {
      onChange(node, undefined)
    }}>Remove Node</Button>

    <AddChild onComplete={(name) => {
      onChange([...node, name], {"@type": "string"})
    }} />
  </div>
}

export default NodeActions
