import React, {useEffect, useState} from "react";
import {useLazyQuery, useMutation, useReactiveVar} from "@apollo/client";
import {GET_WORKSPACE, IWorkspaceEntity, WorkspaceData} from "../../service/workspace";
import {Button, Tabs} from "antd";
import {WorkspaceEntities} from "./WorkspaceEntities";
import {DEFAULT_LENGTH, formPaginatedResponse, pageSizeOptions} from "../../service/pagination";
import CopyWorkspaceId from "./Components/CopyWorkspaceId";
import {ContentHeader} from "../layouts/style";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import ToggleDevMode from "./Components/ToggleDevMode";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import EditableTitle from "../../components/Common/EditableTitle";
import {RENAME_WORKSPACE} from "../../graphql/queries/workspace";
import FilterTools from "../../components/Common/FilterTools";

const {TabPane} = Tabs

interface ViewWorkspace {
  workspaceId?: string
}

export const ViewWorkspace: React.FC<ViewWorkspace> = () => {
  const {push} = useHistory()
  const {pathname} = useLocation()
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const organisationId = useReactiveVar(currentOrgID)

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_LENGTH)

  const [sort, setSort] = useState<"ASC" | "DESC">("ASC")

  const [filter, setFilter] = useState<any[]>([])

  const [fetchWorkspace, {data: {
    workspace: {
      development_enabled,
      name,
      entities,
    }
  } = {
    workspace: {
      development_enabled: false,
      name: "",
      entities: formPaginatedResponse<IWorkspaceEntity>({}),
    }
  }, refetch, fetchMore, loading}] = useLazyQuery<WorkspaceData>(
    GET_WORKSPACE,
    {
      variables: {id: workspaceId, limit: pageSize, page, sort: [{field: "name", direction: sort}], filter, hasId: !!workspaceId},
    }
  )

  const [renameWorkspace, {loading: updateLoading}] = useMutation(
    RENAME_WORKSPACE,
  )

  useEffect(() => {
    if(fetchMore && workspaceId) {
      fetchMore({variables: {page}})
    }
  }, [page])

  useEffect(() => {
    if(workspaceId && workspaceId.length > 0) {
      fetchWorkspace()
    }
  }, [workspaceId])

  return (
    <>
      <ContentHeader
        title={<EditableTitle
        loading={loading || updateLoading}
          title={name} onChange={async (title) => {
          await renameWorkspace({variables: {input: {name: title, workspaceId}}})
          await refetch()
        }} /> }
        onBack={() => push(`/dashboard/organisation/${organisationId}`)}
        extra={[
          <ToggleDevMode key={"dev-mode"} devMode={development_enabled} onComplete={async () => {
            if(refetch) {
              await refetch()
            }
          }} />,
          <CopyWorkspaceId id={workspaceId} key={"workspace-id"} />,
          <Button
            key={"build-eventity"}
            style={{

            }}
            onClick={() => push(`${pathname}/build-eventity`)}
          >Design New Eventity</Button>
        ]}
      />
      <Tabs>
        <TabPane tab={"Eventities"} key={"eventities"}>
          <FilterTools fields={["name"]} titleField={"name"} onFilter={(filters) => setFilter(filters)} />
          <WorkspaceEntities
            workspaceId={workspaceId}
            entities={entities.entries}
            loading={loading}
            onComplete={async () => {
              if(refetch) {
                await refetch()
              }
            }}
            pagination={{
              defaultPageSize: DEFAULT_LENGTH,
              total: entities.totalCount || 0,
              showSizeChanger: true,
              pageSizeOptions,
              onChange: (p: number, ps: number) => {
                setPage(p)
                if(!!ps) {
                  setPageSize(ps)
                }
              }
            }}
          />
        </TabPane>
      </Tabs>
    </>
  )

}
