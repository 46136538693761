import React from "react";
import {EntityList} from "../EntityList";

interface ViewUsers {
  accounts: any[]
  remove(id: string): void
  loading: boolean
}

export const ViewUsers: React.FC<ViewUsers> = ({accounts, remove, ...props}) => (
  <>
    <EntityList
      list={accounts}
      titleField={"name"}
      remove={(id: string) => remove(id)}
      urlPrefix={"/view"}
      loading={props.loading}
    >{props.children}</EntityList>
  </>
)
