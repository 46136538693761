export interface PaginatedResponse<T> {
  totalCount: number
  entries: Array<T>
  pageInfo?: {
    currentPage: number,
    pageSize: number,
  }
}

export const formPaginatedResponse = <T extends any>(response: any): PaginatedResponse<T> => ({
  totalCount: response.totalCount || 0,
  entries: response.entries || [],
  pageInfo: formPageInfo(response.pageInfo),
});

export const formPageInfo = (info: any) => {
  if(!!info) {
    return {
      currentPage: info.currentPage || 1,
      pageSize: info.pageSize || 20,
    }
  } else {
    return undefined
  }
}

export const DEFAULT_LENGTH = 20

export const pageSizeOptions = [
  "10", "20", "50"
]
