import React from "react";
import {Route, Switch, match} from "react-router";
import {ListApiKeys} from "./ListApiKeys";
import {ApiKey} from "./ApiKey";

interface ApiKeys {
  match: match
}

export const ApiKeys: React.FC<ApiKeys> = ({match}) => (
  <Switch>
    <Route exact path={match.url} render={() => <><ListApiKeys /></>} />
    <Route path={`${match.url}/view/:clientId`} component={ApiKey} />
  </Switch>
)
