import React, {useEffect} from "react";
import {match, Route, Switch, useParams} from "react-router";
import {ViewOrganisation} from "./ViewOrganisation";
import {ViewAccount} from "../SysAccount/ViewAccount";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import {Workspace} from "../workspace/Workspace";
import {Users} from "./Users";
import {User} from "./User";

interface Organisation {
  match: match
}

export const Organisation: React.FC<Organisation> = ({match}) => {
  const {orgId} = useParams<{orgId: string}>()

  useEffect(() => {
    currentOrgID(orgId)
    return () => {
      currentOrgID('')
      currentWorkspaceId('')
    }

  },[orgId])

  return (<>
    <Switch key={orgId}>
      <Route exact path={`${match.url}`} component={ViewOrganisation}/>
      <Route path={`${match.url}/workspace/:workspaceId`}
             component={Workspace}
      />
      <Route exact path={`${match.url}/users`} component={Users}/>
      <Route path={`${match.url}/users/view/:userId`} component={User} />
      <Route exact path={`${match.url}/sysaccount/:sysaccountId`}
             component={ViewAccount}
      />
    </Switch>

  </>)
}
