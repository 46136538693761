import React, {useState} from "react";
import {EntityControl} from "../../components/EntityList";
import {useInput} from "../../hooks/UseInput";
import {Form, Input, message, Modal} from "antd";
import {useMutation} from "@apollo/client";
import {CREATE_ORG, READ_ORGS} from "../../service/orgs";

interface CreateOrganisation extends EntityControl {
  onComplete?(): void
}

export const CreateOrganisation: React.FC<CreateOrganisation> = ({onComplete, ...props}) => {
  const [name, bindName, resetName] = useInput("")

  const [visible, setVisible] = useState(false);

  const saveOrg = async () => {
    await createOrg({variables: {name}})
  }

  const [createOrg, {loading}] = useMutation(
    CREATE_ORG,
    {
      onError(e) {
        console.log(e)
        message.warn("Could not save organisation.", 5)
      },
      onCompleted(data) {
        setVisible(false)
        resetName()
        if(onComplete) {
          onComplete()
        }
        message.success("Organisation created!", 5)
      },
    }
  );

  let children;
  let only;

  try {
    only = React.Children.only(props.children)
    if(!!only) {
      // @ts-ignore
      children = React.cloneElement(only, {onClick: () => setVisible(true), style: {...only.props.style, cursor: "pointer"}})
    }
  } catch (e) {
    if(!only) {
      children = <span onClick={() => setVisible(true)}>{props.children}</span>
    }
  }

  return (
    <>
      {children}
      <Modal
        visible={visible}
        onOk={() => createOrg({variables: {name}})}
        zIndex={2020}
        confirmLoading={loading}
        onCancel={() => {
          setVisible(false)
          resetName()
        }}
        title={"Create New Organisation"}
      >
        <div>
          <Form onFinish={(values) => saveOrg() }>
            <Input name={"orgName"} {...bindName} />
          </Form>
        </div>
      </Modal>
    </>
  )
}
