import React, {useState} from "react";
import {useHistory} from "react-router";
import {Button, Divider, Pagination, Select} from "antd";
import {useQuery, useReactiveVar} from "@apollo/client";
import {READ_ORGS,} from "../../service/orgs";
import {CreateOrganisation} from "./CreateOrganisation";
import {PlusOutlined} from "@ant-design/icons";
import {AUTH_STATE, authState} from "../../graphql/type-policies/auth";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import {formPaginatedResponse} from "../../service/pagination";

const  {Option} = Select

interface OrgList {
  orgId: string
}

export const OrgList: React.FC<OrgList> = ({orgId, ...props}) => {
  const history = useHistory()
  const organisationId = useReactiveVar(currentOrgID)
  const currentAuthState = useReactiveVar(authState)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(6)

  const loggedIn = (): boolean => {
    return currentAuthState === AUTH_STATE.LOGGED_IN
  }

  const {data: {orgs: {entries, totalCount, pageInfo: {currentPage, pageSize}}} = {orgs: formPaginatedResponse({pageInfo: {}}) }, refetch} = useQuery(
    READ_ORGS,
    {
      variables: {
        page, limit
      }
    }
  );

  const selectOrg = (id: any): void => {
    if (id && id !== "-1") {
      currentOrgID(id)
      const path = `/dashboard/organisation/${id}`
      history.push(path)
    }
  }

  const onPageChange = (p, ps) => {
    if(p) {
      setPage(p)
    }
  }

  if(loggedIn()) {
    return (
      <>
        <Select
          size={"large"}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{width: "18rem"}}
          onSelect={(id) => {
            selectOrg(id)
          }}
          placeholder={"Organisations"}
          value={organisationId}
          key={`select-org-${orgId}`}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <div style={{display: "flex"}}>
              <Pagination
                size={"small"}
                style={{margin: "0.5rem auto -0.5rem"}}
                current={page}
                defaultPageSize={limit}
                pageSize={limit}
                total={totalCount ?? 0}
                // showSizeChanger={true}
                // pageSizeOptions={pageSizeOptions}
                onChange={onPageChange}
              />
              </div>
              <Divider/>
              <div style={{display: 'flex', flexDirection: 'column', paddingBottom: '1.4rem'}}>
                <CreateOrganisation
                  onComplete={() => {
                    if(refetch) {
                      refetch()
                    }
                  }}
                >
                  <Button
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      padding: '8px',
                      alignItems: 'center',
                      cursor: 'pointer',
                      margin: 'auto'
                    }}
                  >
                <span style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer', lineHeight: 0}}>
                  <PlusOutlined/>
                </span>
                    <span>Create New Organisation</span>
                  </Button>
                </CreateOrganisation>
              </div>
            </div>
          )}
        >
          {entries && entries.map((org: any) => (<Option key={org.id} value={org.id}>{org.name}</Option>))}
        </Select>
      </>
    )
  } else {
    return <></>
  }
}
