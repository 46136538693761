import React, {useState} from "react";
import {Button, Form, Input, InputNumber, message, Modal, Space, Switch} from "antd";
import {useForm} from "antd/lib/form/Form";
import {TypeList} from "../Eventity/TypeList";
import {JoiTypeDef} from "../../service/joi";
import {EventityMetaBuilder} from "./EventityMetaBuilder";
import {metaTypeMap} from "./tools";

interface JoiSchemaBuilder {
  onComplete(name: string, data: any): Promise<any>
  name?: string
  title?: string
}

export const JoiSchemaBuilder: React.FC<JoiSchemaBuilder> = ({onComplete, name, title}) => {
  const [visible, setVisible] = useState(false)
  const [schemaForm] = useForm()
  const [specialField, setSpecialField] = useState("")

  const submitForm = () => {
    const field = schemaForm.getFieldsValue()
    const {name} = field
    const data: JoiTypeDef = {
      '@type': field.type,
      '@required': field.required,
    }
    if (field.min) data.min = field.min
    if (field.max) data.max = field.max

    let meta: any = {}

    if (field["@meta"] && Object.keys(field["@meta"]).length > 0) {
      meta = {...field["@meta"]}
    }

    if(field.noDelete) {
      meta = {...meta, noDelete: field.noDelete}
    }
    if(field.readOnly) {
      meta = {...meta, readOnly: field.readOnly}
    }

    if(Object.keys(meta).length > 0) {
      data["@meta"] = meta
    }

    if (field.items) data.items = field.items

    onComplete(name, data)
      .then(() => {
        setVisible(false)
        setSpecialField("")
        schemaForm.resetFields()
      })
      .catch((e) => {
        message.error(e.message)
        console.log(e)
      })
  }


  return (<>
    <Button
      type={"primary"}
      onClick={() => setVisible(true)}
    >{title || "Add Schema Field"}</Button>
    <Modal
      visible={visible}
      title={"Schema Field"}
      maskClosable={true}
      okText={"Add Schema Field"}
      destroyOnClose
      onCancel={() => {
        setSpecialField("")
        schemaForm.resetFields()
        setVisible(false)
      }}
      onOk={() => {
        schemaForm.submit()
      }}
      width={800}
    >
      <Form
        form={schemaForm}
        layout={"horizontal"}
        colon={false}
        onFinish={() => {
          submitForm()
        }}
      >
        <Space style={{display: 'flex'}} align={"start"} size={"middle"}>
          <Form.Item
            rules={[{required: true}]}
            name={'name'}
          >
            <Input placeholder={"Field Name"} onChange={(e) => {
              const name = e.target.value.replace(/\s+/g, '_')
              schemaForm.setFieldsValue({name})
            }}/>
          </Form.Item>
          <Form.Item
            name={"metaType"}
            rules={[{required: true}]}
          >
            <TypeList setSelection={(s) => {
              const {type = "any", isMetaType = false} = metaTypeMap.get(s) as any
              let update: any = {metaType: s, type}
              let meta = {...schemaForm.getFieldValue("@meta")}
              if (isMetaType) {
                meta = {...meta, function: {metatype: s}}
              } else {
                if(meta.hasOwnProperty("function")) {
                  delete meta.function
                }
              }
              update = {...update, "@meta": meta}
              if(Object.keys(meta).length === 0) {
                delete update["@meta"]
              }
              schemaForm.setFieldsValue(update)
              setSpecialField(s)
            }}/>
          </Form.Item>
        </Space>
        <Space style={{display: 'flex'}} size={"middle"} align={"start"}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => prev.type !== curr.type}
          >
            {({getFieldValue}) => {

              return (getFieldValue('type') !== 'boolean') &&
                (<Form.Item
                  name={'required'}
                  valuePropName={"checked"}
                >
                  <Switch checkedChildren={"Required"} unCheckedChildren={"Optional"}></Switch>
                </Form.Item>)
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => prev.metaType !== curr.metaType}
          >

            {({getFieldValue}) => {
              const metaType = metaTypeMap.get(getFieldValue("metaType"))
              if (metaType) {
                const {hasMinMax = false} = metaTypeMap.get(getFieldValue("metaType")) as any
                return hasMinMax ? (<Form.Item name={'min'}>
                  <InputNumber placeholder={"Min"} style={{width: '4rem'}}/>
                </Form.Item>) : null
              }
              return null
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => prev.metaType !== curr.metaType}
          >
            {({getFieldValue}) => {
              const metaType = metaTypeMap.get(getFieldValue("metaType"))
              if (metaType) {
                const {hasMinMax = false} = metaTypeMap.get(getFieldValue("metaType")) as any
                return hasMinMax ? (<Form.Item name={'max'}>
                  <InputNumber placeholder={"Max"} style={{width: '4rem'}}/>
                </Form.Item>) : null
              }
              return null
            }}
          </Form.Item>
        </Space>
        <Form.Item name={"type"} noStyle/>
        <Form.Item name={"@meta"} noStyle />
        <Form.Item
          noStyle
          shouldUpdate={(prev, cur) => prev.metaType !== cur.metaType}>
          {({getFieldValue}) => {
            const metaType = metaTypeMap.get(getFieldValue("metaType"))
            if (metaType && metaType.hasOwnProperty("additionalFields")) {
              // @ts-ignore
              return metaType.additionalFields.map((field) => (
                <Form.Item noStyle name={field} preserve={false}/>
              ))
            }
          }}
        </Form.Item>
          <Form.Item
            name={'noDelete'}
            valuePropName={"checked"}
            label={"Prevent Deletion"}
            labelAlign={"left"}
            labelCol={{span: 4, push: 2}}
            wrapperCol={{pull: 4}}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name={'readOnly'}
            valuePropName={"checked"}
            label={"Read Only"}
            labelAlign={"left"}
            labelCol={{span: 3, push: 2}}
            wrapperCol={{pull: 3}}
          >
            <Switch />
          </Form.Item>
      </Form>


      <EventityMetaBuilder
        type={specialField}
        updateMetaData={(data, otherFields: any = {}, items?: any ) => {
          const clearOtherFields = Object.fromEntries(Object.keys(otherFields).map((key) => [key, undefined]))
          schemaForm.setFieldsValue({"@meta": undefined, ...clearOtherFields})
          schemaForm.setFieldsValue({"@meta": data, ...otherFields})
        }}
      />

    </Modal>
  </>)
}
