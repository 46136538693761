import React from "react";
import {ViewUsers} from "../../components/Users/ViewUsers";
import {useQuery} from "@apollo/client";
import {READ_ORG_ACCOUNTS} from "../../graphql/queries/org";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import {AddAccount} from "../../components/SysAcccount/AddAccount";
import {message} from "antd";

interface Users {}

export const Users:React.FC<Users> = ({...props}) => {
  const orgId = currentOrgID()
  const {data: {sys: {accounts}} = {sys: {accounts: []}}, loading, refetch} = useQuery(
    READ_ORG_ACCOUNTS,
    {
      variables: {
        id: orgId
      }
    }
  ) as any

  return (<>

    <ViewUsers
    accounts={accounts}
    remove={(id: string) => {
      message.error("Not implemented")
    }}
    loading={loading}
    >
      <AddAccount orgId={orgId} onComplete={() => refetch()} />
    </ViewUsers>

  </>)
}
