import React, {useEffect, useState} from "react";
import {Menu, MenuProps} from "antd";
import {Link} from "react-router-dom";
import {useLazyQuery, useQuery} from "@apollo/client";
import {WORKSPACE_PAGES} from "../../../graphql/queries/web-component";
import JSONPretty from "react-json-pretty";

interface I {
  workspaceId: string
  organisationId: string
}

const AppMenu: React.FC<I> = ({workspaceId, organisationId}) => {
  const [menuItems, setMenuItems] = useState<MenuProps['items']>([])
  const organisationPath: string = `/dashboard/organisation/${organisationId}`
  const workspacePath: string = `${organisationPath}/workspace/${workspaceId}`
  const [pages, setPages] = useState<MenuProps['items']>([])

  const [getWorkspaceComponents] = useLazyQuery(
    WORKSPACE_PAGES,
    {
      variables: {
        workspaceId,
        hasId: !!workspaceId
      },
      onCompleted: ({WorkspaceComponents}) => {

      }
    }
  )

  useEffect(() => {
    if(workspaceId) {
      getWorkspaceComponents()
        .then(({data:{WorkspaceComponents}}) => {
          let p: MenuProps['items'] = []

          if(WorkspaceComponents) {

            WorkspaceComponents.forEach((component) => {
              if(component.hasOwnProperty("pages")) {
                const {pages: ps} = component
                p = [...p, ...ps.map((page) => {
                  return {
                    label: <Link to={`${workspacePath}/p/${component.namespace}/${component.id}/${page.slug}`}>{page.name}</Link>,
                    key: `${component.id}-${page.slug}`
                  }
                })]
              }
            })

          }
          setPages(p)
        })
    } else {
      setPages([])
    }
  }, [workspaceId])

  useEffect(() => {

    let organisationMenu: MenuProps['items'] = []
    let workspaceMenu: MenuProps['items'] = [

    ]

    if(workspaceId) {

      workspaceMenu = [
        {
          label: "Workspace",
          key: "workspace-category",
          type: "group",
          children: [
            {
              label: <Link to={workspacePath}>Home</Link>,
              key: "workspace-home",
            },
            ...pages,
          ]
        },
        {
          label: "Workspace Development",
          key: "workspace-dev",
          type: "group",
          children: [
            {
              label: <Link to={`${workspacePath}/developer-settings`}>Developer Settings</Link>,
              key: "workspace-dev-settings",
            },
            {
              label: <Link to={`${workspacePath}/playground`}>GQL PLayground</Link>,
              key: "workspace-playground",
            },
          ]
        },
        {
          label: "Workspace Admin",
          key: "workspace-admin",
          type: "group",
          children: [
            {
              label: <Link to={`${workspacePath}/extensions`}>Extensions</Link>,
              key: "workspace-extensions",
            },
            {
              label: <Link to={`${workspacePath}/build-eventity`}>Schema Design</Link>,
              key: "workspace-build",
            },
            {
              label: <Link to={`${workspacePath}/apikeys`}>Api Keys</Link>,
              key: "workspace-apikeys",
            },
            {
              label: <Link to={`${workspacePath}/kms`}>Encryption Keys</Link>,
              key: "workspace-kms",
            },
          ]
        }
      ]
    }

    if(organisationId) {
      organisationMenu = [{
        label: "Organisation",
        type: "group",
        key: "organisation-category",
        children: [
          {
            label: <Link to={organisationPath}>View Organisation</Link>,
            key: "view-organisation"
          },
          {
            label: <Link to={`${organisationPath}/users`}>Organisation Users</Link>,
            key: "organisation-users"
          }
        ]
      }]
    }

    const menu: MenuProps['items'] = [
      {
        label: <Link to={"/dashboard"}>Dashboard</Link>,
        key: "dashboard"
      },
      ...workspaceMenu,
      ...organisationMenu,
    ]

    setMenuItems(menu)

  }, [workspaceId, organisationId, pages])

  return <>
    <Menu
      mode={"inline"}
      items={menuItems}
    />
  </>
}

export default AppMenu
