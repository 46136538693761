import React, {useEffect, useState} from "react";
import {Col, Row, Tree} from "antd";
import {toTitleCase} from "../../../../service/string";
import NodeData from "./NodeData";
import NodeActions from "./NodeActions";
import MetaBuilder from "./Components/MetaBuilder";
import AddChild from "./AddChild";
import {SpacedCol} from "./style";

interface I {
  data: any
  updateSchema(data: Record<string, any>): void
}

const extractTree = (schema: any, parent: string = ""): any[] => Object.keys(schema)
    .filter((key) => key.charAt(0) !== "@" && key !== "min" && key !== "max" && !!schema[key])
    .map((key) => ({
      title: toTitleCase(key),
      key: `${parent}/${key}`,
      children: extractTree(schema[key], `${parent}/${key}`)
    }))

const extractItem = (schema: Record<string, any>, path: string[]): Record<string, any> => {
  if(path.length === 0) {
    return schema
  }

  const key = path[0]
  let item = {}
  if(schema.hasOwnProperty(key)) {
    item = schema[key]
    if(path.length > 0) {
      const newpath = path.slice(1)
      item = extractItem(item, newpath)
    }
  }
  return item
}

const addItem = (schema: Record<string, any>, keypath: string[], data: any): Record<string, any> => {

  if(keypath.length === 0) {
    return schema
  }

  if(schema.hasOwnProperty(keypath[0])) {

    const key = keypath[0]

    let level = schema[key]
    const newpath = keypath.slice(1)
    if(newpath.length > 1) {
      level = {...level, ...addItem(level, newpath, data)}
      schema = {...schema, [key]: level}
    } else {
      let node = newpath[0]
      if(data) {
        if(level.hasOwnProperty(node)) {
          if(Array.isArray(data)) {
            if(Array.isArray(level[node])) {
              level = {...level, [node]: [...level[node], ...data]}
            } else {
              level = {...level, [node]: [...data]}
            }
          } else if(["string", "number"].includes(typeof data)) {
            level = {...level, [node]: data}
          } else {
            if(typeof level[node] === "object") {
              level = {...level, [node]: {...level[node], ...data}}
            } else {
              level = {...level, [node]: data}
            }
          }

        } else {
          level = {...level, [node]: data}
        }

        // Adding items to a node forces it to be an object, unless it's @meta
        if(node && key.charAt(0) !== "@" && node.charAt(0) !== "@" && !["min", "max"].includes(node)) {
          level["@type"] = "object"
        }

        schema = {...schema, [key]: level}

      } else {
        if(newpath.length === 0 || !node) {
          // @ts-ignore
          node = [...keypath].pop() || ""
          const nLevel: any = {}
          Object.keys(schema)
            .filter((key) => key !== node)
            .map((key) => nLevel[key] = schema[key])
          schema = nLevel
        } else {
          const nLevel: any = {}

          Object.keys(level)
            .filter((key) => {
              return key !== node
            })
            .map((key) => nLevel[key] = level[key])

          level = nLevel
          schema = {...schema, [key]: {...level}}
        }
      }
    }
  }

  if(keypath.length === 1) {
    // We might be adding a new node to the root?
    schema = {...schema, [keypath[0]]: data}
  }

  return schema
}

const SmartEditor: React.FC<I> = ({data, updateSchema}) => {
  const [treeData, setTreeData] = useState<any[]>([])
  const [expandedKeys, setExpandedKeys] = useState<any[]>([])
  const [selectedNode, setSelectedNode] = useState<string[]>([])
  const [selectedItem, setSelectedItem] = useState<Record<string, any>>({})
  const [schema, setSchema] = useState<Record<string, any>>({})
  const expandKey = (key: string) => {
    const nex = [...expandedKeys]
    if (expandedKeys.includes(key)) {
      const i = expandedKeys.findIndex((k) => k === key)

      nex.splice(i, 1)
      setExpandedKeys(nex)
    } else {
      nex.push(key)
      setExpandedKeys(nex)
    }
  }

  const getSelectedNode = () => {
    const node = [...selectedNode].pop()
    return node || ""
  }

  const getParentNode = (node: string[]): string[] => {
    const n = [...node]
    n.pop()
    return n
  }

  const updateSelectedNode = () => {
    if (selectedNode.length > 0) {
      setSelectedItem(extractItem(schema, selectedNode))
    } else {
      setSelectedItem({})
    }
  }

  useEffect(() => {
    setTreeData(extractTree(data))
    setSchema(data)
  }, [data])

  useEffect(() => {
    updateSelectedNode()
  }, [schema])

  useEffect(() => {
    updateSelectedNode()
  }, [selectedNode])

  return <>
    <Row style={{background: "#fff", width: "100%", marginBottom: "1rem", border: "1px solid #cacaca", minHeight: "20rem"}}>
      <Col xs={24} sm={5} style={{padding: "1rem", borderRight: "1px solid #cacaca", display: "flex", flexDirection: "column"}}>
        <Tree
          treeData={treeData}
          defaultExpandAll
          expandedKeys={expandedKeys}
          showLine
          selectedKeys={[]}
          onExpand={(keys, data) => {
            expandKey(data.node.key as string)
            // setSelectedNode(`${data.node.key}`.split("/").filter((i) => !!i))
          }}
          onSelect={(s) => {
            if (s && s[0]) {
              const selected = `${s[0]}`.split("/").filter((i) => !!i)
              // check children and do some magic so we don't collapse a key that isn't selected
              // expandKey(s[0] as any)
              setSelectedNode(selected)
            } else {
              // setSelectedNode([])
            }
          }}
        />

        <div style={{marginTop: "auto"}}>
        <AddChild onComplete={(name) => {
          updateSchema(addItem(schema, [name], {"@type": "string"}))
        }} text={"Add Root Node"} />
        </div>

      </Col>
      <SpacedCol style={{padding: "0.5rem"}} flex={"auto"}>

        {!!selectedNode && selectedNode.length > 0 && <>
          <NodeData node={selectedNode} schema={selectedItem} root={schema} onChange={(changes) => {
            let s: any = schema
            changes.forEach(({path, data = undefined}) => {
              s = addItem(s, path, data)
            })
            updateSchema(s)


            //updateSchema(addItem(schema, path, item))
          }}/>

          <MetaBuilder item={selectedItem} node={selectedNode} onChange={(path, data) => {
            updateSchema(addItem(schema, path, data))
          }} />

          <NodeActions node={selectedNode} onChange={(node, data) => {
            updateSchema(addItem(schema, node, data))
            if(!data) {
              setSelectedNode(getParentNode(node))
            } else {
              setSelectedNode(node)
            }
          }}/>

        </>}

      </SpacedCol>
    </Row>
  </>
}

export default SmartEditor
