import React, {useEffect, useState} from "react";
import {Button, Modal} from "antd";
import {ExecuteCommand} from "../Command/ExecuteCommand";
import {toTitleCase} from "../../service/string";
import {PlusOutlined} from '@ant-design/icons';


interface CreateEventity {
  eventityName: string
  eventity: any
  onComplete?(): void
}

export const CreateEventity: React.FC<CreateEventity> = ({eventityName, eventity, ...props}) => {
  const [visible, setVisible] = useState(false)
  const commandName = `Create${eventityName}`
  const [schema, setSchema] = useState<any>({})
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (!!eventity) {
      let {schema: s} = eventity
      if (!!eventity.commands && eventity.commands.length > 0) {
        const create = eventity.commands.find((com: any) => com.name === `Create${eventityName}`)
        if (!!create) {
          if (create.handler === "disabled") {
            setDisabled(true)
          }
          if (!!create.inputSchema) {
            s = create.inputSchema
          }
        }
      } else if(eventity.handlers && eventity.handlers.create && eventity.handlers.create === "disabled") {
        setDisabled(true)
      }
      setSchema(s)
    }
  }, [eventity])

  const fields = Object.keys(schema).filter((key) => key.charAt(0) !== '@') || []


  return (<>
    <Modal
      title={toTitleCase(commandName)}
      visible={visible}
      onCancel={() => {
        setVisible(false)
      }}
      footer={null}
      destroyOnClose
    >
      {commandName && <ExecuteCommand
        inputSchema={(schema as any)}
        outputSchema={{success: {"@type": "boolean"}}}
        name={commandName}
        handler={"aggregate-create"}
        onComplete={() => {
          if(!!props.onComplete) {
            props.onComplete()
          }
          setVisible(false)

        }}
      />}
    </Modal>
    <Button
      disabled={disabled}
      onClick={() => setVisible(true)}
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        padding: '8px',
        alignItems: 'center',
        cursor: disabled?"not-allowed":"pointer",

      }}
    >
      <span style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer', lineHeight: 0}}>
                  <PlusOutlined/>
                </span>
      <span>Create New {toTitleCase(eventityName)}</span>
    </Button>
  </>)
}
