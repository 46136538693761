import React from "react";
import ConfigureForm from "./ConfigureForm";

interface ConfigureExtension {
  id: string
  workspaceId: string
  configuration: any
  configurationData: any
  onComplete(data: any): Promise<void>
  loading: boolean
}

const ConfigureExtension: React.FC<ConfigureExtension> = ({configuration, onComplete, configurationData}) => {

  if (!!configuration && Object.keys(configuration).length > 0) {
    return <ConfigureForm onComplete={onComplete} configurationData={configurationData} configuration={configuration} text={"Configure Extension"} />
  } else {
    return <></>
  }
}

export default ConfigureExtension
