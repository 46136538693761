import React, {useEffect, useState} from "react";
import {useApolloClient, useLazyQuery} from "@apollo/client";
import {WORKSPACE_COMPONENTS} from "../../../../graphql/queries/web-component";
import Panel from "./Components/Panel";

interface I {
  workspaceId: string
  type: "view" | "list" | "workspace" | "edit"
  entityName: string
  context?: any
}

const Panels: React.FC<I> = ({workspaceId, type, entityName, context}) => {

  const [panels, setPanels] = useState<any[]>([])
  const client = useApolloClient()

  const [readWorkspaceComponents, {data: {WorkspaceComponents} = {WorkspaceComponents: []}}] = useLazyQuery(
    WORKSPACE_COMPONENTS,
    {
      variables: {
        workspaceId,
      }
    })

  useEffect(() => {
    if(workspaceId) {
      readWorkspaceComponents()
    }
  }, [workspaceId])

  useEffect(() => {
    if(WorkspaceComponents && WorkspaceComponents.length > 0) {
      setPanels(WorkspaceComponents
        .flatMap((c) => c.panels)
        .filter((panel) => panel)
        .filter((panel) => panel.type === type && panel.target.includes(entityName))
      )

    }
  }, [WorkspaceComponents])

  return <>
    {panels.map((panel) => <Panel workspaceId={workspaceId} context={context} client={client} tag={panel.tag} key={`${panel.tag}-${panel.target}`} />)}
  </>
}

export default Panels
