import React from "react";
import {Space} from "antd";
import Title from "antd/lib/typography/Title";

interface I {}

const Commands: React.FC<I> = ({children}) => {
  return <Space direction={"vertical"} style={{width: "100%"}}>
    <Title level={5}>Commands are not implemented yet.</Title>
    <div>
      {children}
    </div>
  </Space>
}

export default Commands
