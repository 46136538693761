import React, {useState} from "react";
import {Button, Col, Form, Input, Result, Row} from "antd";
import {LostPasswordForm} from "../../components/Auth/LostPasswordForm";
import {useMutation} from "@apollo/client";
import {RESET_PASSWORD_REQUEST} from "../../graphql/queries/auth";

interface LostPassword {}

export const LostPassword: React.FC<LostPassword> = () => {
  const [success, setSuccess] = useState(false)

  const [beginReset, {loading}] = useMutation(RESET_PASSWORD_REQUEST)

  if(success) {
    return (
      <Result
        status={"info"}
        title={"Password Reset Request"}
        subTitle={"Your request has been received. If there is an account associated with your e-mail address, a message will arrive shortly with instructions on how to proceed."}
      />
    )
  } else {
    return (<>
      <Row justify={"center"}>
        <Col xs={"auto"} flex={"15rem"}>
          <LostPasswordForm onFinish={(data) => {
            beginReset({variables: data})
              .catch(e => {
                console.log(e)
                setSuccess(true)
              })
              .then(() => {
                setSuccess(true)
              })
          }}/>
        </Col>
      </Row>
    </>)
  }
}
