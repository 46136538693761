import gql from "graphql-tag";
import {QueryResult} from "@apollo/client";

export interface ExtensionManifest {
  configuration: Record<any, any>
  [key: string]: any
}

export interface Extension {
  readonly id: string
  readonly configurationData?: Record<string, any>
  readonly installed: boolean
  readonly installedVersion: string
  readonly name: string
  readonly versionAvailable: string
  readonly manifest: ExtensionManifest
}

export interface ExtensionResult {
  readonly results: Extension[]
  readonly page: number
  readonly query: string
}

export interface ExtensionsQueryResult extends QueryResult {
  ListExtensions?: ExtensionResult
  ListInstalledExtensions?: ExtensionResult
}

export const REMOVE_EXTENSION_FROM_WORKSPACE = gql`
  mutation AddExtensionToWorkspace($id: String!) {
    RemoveExtensionFromWorkspace(id: $id) {
      success
    }
  }
`

export const ADD_EXTENSION_TO_WORKSPACE = gql`
  mutation InstallExtension($id: String! $configuration: JSONObject! ) {
    InstallExtension(id: $id, configuration: $configuration) {
      success
    }
  }
`

export const UPDATE_EXTENSION = gql`
  mutation UpdateExtension($input: UpdateExtensionToWorkspaceInput!) {
    UpdateExtensionToWorkspace(input: $input) {
      success
    }
  }
`

const ExtensionFragment = gql`

  fragment ExtensionFragment on Extension {
    id configurationData installed installedVersion name versionAvailable
  }
`

const ExtensionSmallManifest = gql`
  fragment SmallManifest on ExtensionManifest {
    configuration description identifier name namespace version
  }
`

export const READ_EXTENSIONS = gql`
  ${ExtensionFragment}
  ${ExtensionSmallManifest}
  query ListExtensions {
    ListExtensions {
      page query results {
        ...ExtensionFragment
        manifest {
          ...SmallManifest
        }
      }
    }
  }
`

export const READ_INSTALLED_EXTENSIONS = gql`
  ${ExtensionFragment}
  ${ExtensionSmallManifest}
  query ListInstalledExtensions {
    ListInstalledExtensions {
      page query results {
        ...ExtensionFragment
        manifest {
          ...SmallManifest
        }
      }
    }
  }
`

export const READ_EXTENSION_COMPONENTS = gql`
  query ListInstalledExtensions {
    ListInstalledExtensions {
      results {
        id
        installedVersion
        manifest {
          namespace
          identifier
          webcomponents
          views
          version
        }
      }
    }
  }
`


export const GET_EXTENSION_MANIFEST = gql`
  query GetExtensionManifest($id: String!) {
    GetExtensionManifest(id: $id) {
      manifest installedManifest installedVersion configuration
    }
  }
`

export const UPLOAD_MANIFEST = gql`
  mutation Inject($manifest: JSONObject! $configuration: JSONObject!) {
    InjectExtensionManifest(configuration: $configuration manifest: $manifest) {
      success
    }
  }
`

export const CONFIGURE_EXTENSION = gql`
  mutation ConfigureExtension($configuration: JSON! $id: String!) {
    ConfigureExtension(configuration: $configuration id: $id) {
      success message
    }
  }
`

export const FETCH_REMOTE_MANIFEST = gql`
  query FetchManifest($path: String!) {
    FetchRemoteManifest(path: $path) {
      name identifier configuration remoteSchema
    }
  }
`

export const INJECT_REMOTE_MANIFEST = gql`
  mutation InjectRemote($configuration: JSONObject $path: String! $remoteSchema: String) {
    InjectRemoteExtensionManifest(configuration: $configuration path: $path remoteSchema: $remoteSchema) {
      success message
    }
  }
`

export const manifestPlaceholder = {
  name: "Loading",
  description: "Loading",
  identifier: "Loading",
  namespace: "loading",
  version: "0.0.0",
  types: [],
  commands: [],
  queries: [],
  webcomponents: {},
  scenes: {},
  configuration: {},
}
