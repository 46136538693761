import React, {useState} from "react";
import {Space, Switch} from "antd";
import SmartEditor from "./SmartEditor";
import loadable from "@loadable/component";

const RawEditor = loadable(() => import("./RawEditor"), {
  fallback: <></>
})

interface I {
  schema: any
  onChange(schema: any): void
  onComplete?(): void
}

const Schema: React.FC<I> = ({schema, onChange, onComplete, children}) => {
  const [editDirect, setEditDirect] = useState(false)

  return <>
    <Space direction={"vertical"} style={{width: "100%"}}>
      <Space>
        <Switch onClick={() => setEditDirect((!editDirect))}
              checked={!editDirect}
              unCheckedChildren={"Raw Editor"}
              checkedChildren={"Smart Editor"}/>
      </Space>
      {!editDirect && <SmartEditor data={schema} updateSchema={(data) => onChange(data)}>Eventity Tree</SmartEditor>}
      {editDirect && <RawEditor editable data={schema} updateSchema={(data) => {
        onChange(data)
      }}/>}
      {children}
    </Space>
  </>
}

export default Schema
