import React, {useState} from "react";
import {Button, Form, message, Modal} from "antd";
import {Input} from "antd";
import {useMutation} from "@apollo/client";
import {UPLOAD_MANIFEST} from "../../../graphql/queries/extensions";

interface I {
  onComplete(): Promise<void>
  workspaceId: string
}

const ManifestEditor: React.FC<I> = ({workspaceId, onComplete}) => {

  const [visible, setVisible] = useState<boolean>(false)
  const [value, setValue] = useState<string>("{}")

  const [uploadManifest, {loading}] = useMutation(
    UPLOAD_MANIFEST,
    {
      onError(e) {
        message.error(e.message)
      }
    }
  )

  return <>
    <Modal
      title={"Manifest Editor"}
      onOk={() => {

        uploadManifest({variables: {manifest: JSON.parse(value), configuration: {}}})
          .then(() => {
            onComplete()
            setValue("")
            setVisible(false)
          })


      }}
      onCancel={() => {
        setValue(undefined)
        setVisible(false)
      }}
      visible={visible}
      width={"50vw"}
    >
      <Input.TextArea style={{width: "100%", height: "50vh"}} value={value} onChange={(e) => setValue(e.target.value)} />
    </Modal>
    <Button
      onClick={() => setVisible(true)}
    >Manifest Editor</Button>
  </>
}

export default ManifestEditor
