import {makeVar} from "@apollo/client";

export const eventityInFlight = makeVar({name: '', data: {}})
export const rawEventityPosition = makeVar({
  start: {row: 0, column: 0},
  end: {row: 0, column: 0},
})

export const eventityFields = {
  EventityInFlight: {
    read() {
      return eventityInFlight()
    }
  }
}
