import React from "react";
import ConfigureForm from "./ConfigureForm";
import {useMutation} from "@apollo/client";
import {UPDATE_EXTENSION} from "../../../graphql/queries/extensions";

interface UpdateExtension {
  id: string
  workspaceId: string
  configuration: any
  configurationData: any
  onComplete(): void
}

const UpdateExtension: React.FC<UpdateExtension> = ({id, workspaceId, onComplete: done, ...props}) => {
  const [updateExtension] = useMutation(
    UPDATE_EXTENSION,
  )
  const onComplete = async (data: any) => {
    await updateExtension({
      variables: {
        input: {
          extensionId: id,
          configuration: data,
          workspaceId,
        }
      }
    })
    done()
  }
  return <ConfigureForm onComplete={onComplete} {...props} text={"Update"} />
}

export default UpdateExtension
