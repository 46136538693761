import React, {useEffect, useState} from "react";
import {FormItem} from "../FormItem";
import {useQuery, useReactiveVar} from "@apollo/client";
import {currentWorkspaceId} from "../../../graphql/type-policies/workspace";
import {READ_MINIMAL_EVENTITY} from "../../../graphql/queries/eventity";
import EventityList from "./EventityList";

interface EventityLinkItem extends FormItem {}

export const EventityLinkItem: React.FC<EventityLinkItem> = ({data,onChange, name: nodeName, label, required}) => {
  const [eventityName, setEventityName] = useState<string>("")
  const [fields, setFields] = useState<string[]>([])

  useEffect(() => {
    if(data && data.hasOwnProperty("@meta")) {
      const meta = data["@meta"]
      if(meta.hasOwnProperty("function") && meta.function.hasOwnProperty("eventity")) {
        setEventityName(meta.function.eventity.name)
      } else {
        setEventityName("")
      }
    } else {
      setEventityName("")
    }
  }, [data])

  const workspaceId = useReactiveVar(currentWorkspaceId)
  const {data: {GetWorkspaceEntity: {schema, name, id}} = {GetWorkspaceEntity: {schema: {}, id: "", name: ""}}} = useQuery(
    READ_MINIMAL_EVENTITY,
    {
      variables: {
        workspaceId,
        name: eventityName
      }
    }
  )

  useEffect(() => {
    if (schema) {
      const f = Object.keys(schema) || []
      if (f.length > 0) {
        setFields(f.filter((key) => key.charAt(0) !== "@" && key !== "min" && key !== "max"))
      }
    }
  }, [schema])

  return <>

    {data && fields && schema && name && <EventityList metadata={data["@meta"]} schema={schema} workspaceId={workspaceId} fields={fields} eventityName={name} name={nodeName} label={label} required={required} />}

  </>
}
