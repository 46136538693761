import React, {useEffect, useState} from "react";
import RemoveExtension from "./RemoveExtension";
import AddExtension from "./AddExtension";
import {Card} from "antd";
import {Link, useLocation} from "react-router-dom";
import UpdateExtension from "./UpdateExtension";
import {Extension} from "../../../graphql/queries/extensions";

interface ExtensionList {
  extensions: Extension[]
  workspaceId: string
  onComplete(): void
}

const ExtensionList: React.FC<ExtensionList> = ({extensions= [], workspaceId, ...props}) => {
  const {pathname} = useLocation()
  const [finalPath, setFinalPath] = useState(pathname)
  useEffect(() => {
    const splitPath = pathname.split("/")
    const pos = splitPath.findIndex((k) => k === "extensions")

    const fpath = splitPath.slice(0, pos + 1)

    // TODO, not sure.
    setFinalPath(fpath.join("/"))
  }, [pathname])
  return <div style={{
    display: 'grid',
    gridTemplateColumns: "repeat(auto-fill, 15rem)",
    gridAutoRows: "auto",
    gridGap: "1rem",
  }}>

    {extensions.map((extension) => {
      // const installed = extension.installed || false
      const actions = []
      // let configuration: any
      const {manifest, manifest: {configuration}, configurationData, installed} = extension

      // if(extension.hasOwnProperty("configuration")) {
      //   configuration = extension.configurationData
      // }

      if (installed) {
        actions.push(<RemoveExtension id={extension.id as string} workspaceId={workspaceId} {...props} />)
        if (extension.hasOwnProperty("versionAvailable") && !!extension.versionAvailable && manifest.version !== extension.versionAvailable) {
          actions.push(<UpdateExtension id={extension.id} workspaceId={workspaceId} configuration={configuration}
                                        configurationData={configurationData} {...props}/>)
        }
      } else {
        actions.push(<AddExtension
          id={extension.id as string}
          configuration={configuration}
          workspaceId={workspaceId}
          {...props}
        />)
      }
      return (<Card
        key={extension.id}
        actions={[
          ...actions
        ]}
        className={"extensioncard"}
      >
        <Card.Meta
          style={{height: "100%"}}
          title={<Link
            to={`${finalPath}/view/${extension.id}`}>{extension.name}</Link>}
          description={<>
            {!!manifest.description && <p>{manifest.description}</p>}
            {!!manifest.version && <p>Installed Version: {manifest.version}</p>}
            {!!extension.versionAvailable && extension.versionAvailable !== manifest.version &&
              <p><em>New Version Available: {extension.versionAvailable}</em></p>}
          </>}
        />
      </Card>)
    })}
    {!!props.children && <>{props.children}</>}
  </div>
}

export default ExtensionList

