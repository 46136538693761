import React, {useState} from "react";
import {Button, Modal} from "antd";

interface I {
  name: string,
  id: string,
  onOk: () => Promise<void>
  loading: boolean
}

const DeleteEventity: React.FC<I> = ({name, id, onOk, loading}) => {
  const [visible, setVisible] = useState<boolean>(false)
  return (<>
    <Modal
      visible={visible}
      title={"Remove Eventity"}
      onOk={() => {
        onOk()
          .then(() => setVisible(false))
      }}
      onCancel={() => setVisible(false)}
      confirmLoading={loading}
    >
      <>This will remove the Eventity instance "{name}". Continue?</>
    </Modal>
    <Button type={"primary"} danger onClick={() => setVisible(true)}>Remove Eventity</Button>
  </>)
}

export default DeleteEventity
