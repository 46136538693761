import React, {ReactText} from "react";
import {toTitleCase} from "../../service/string";
import {Form, Input, InputNumber, Switch} from "antd";
import {hasHelp, isDisabled, isMetaFunctionType, metaFunction, typeMap} from "../Form/tools";
import {MetaTypeInput} from "../Form/MetaTypeInput";

interface FieldFromType {
  name: string | number | (string | number)[]
  data: any
  type: string
  hideLabel?: boolean
  fieldKey?: string | number | (string | number)[]
}

const FieldFromType: React.FC<FieldFromType> = ({name, type, data, hideLabel = false, fieldKey = []}) => {
  const rules = [];

  if(data['@required']) {
    rules.push({required: true})
  }
  if(type === 'email') {
    rules.push({type: 'email'})
  }
  if(data.min) {
    rules.push({min: data.min})
  }
  if(data.max) {
    rules.push({max: data.max})
  }

  let newFieldKey: any = []
  if(!!fieldKey) {
    if(Array.isArray(fieldKey)) {
      newFieldKey = [...fieldKey]
    } else {
      newFieldKey = [fieldKey]
    }
  }

  let label: string;
  let field: any[]
  if(Array.isArray(name)) {
    field = name
    label = toTitleCase(`${name[name.length - 1]}`)
    newFieldKey = [...newFieldKey, ...name]
  } else {
    field = [name]
    label = toTitleCase(`${name}`)
    newFieldKey = [...newFieldKey, name]
  }

  const displayLabel = (): string => {
    if(hideLabel) return ""
    return label
  }

  const disabled = isDisabled(data)

  switch (type) {
    case "boolean": {
      return (
        <Form.Item
          key={JSON.stringify(name)}
          name={field}
          // @ts-ignore
          rules={rules}
          label={displayLabel()}
          valuePropName={"checked"}
          fieldKey={newFieldKey}
          help={hasHelp(data)}
        >
          <Switch disabled={disabled} />
        </Form.Item>
      )
    }
    case "object": {
      return (
        <>
          <div style={{border: "1px solid #cacaca", padding: "0.5rem", marginBottom: "0.5rem"}}>
            <h3>{label}</h3>
            {Object.keys(data)
              .filter((key) => key.charAt(0) !== "@" && key !== "min" && key !== "max")
              .map((key) => {
                const fieldData = data[key]
                let fieldName: any;

                if(Array.isArray(name)) {
                  fieldName = [...name, key]
                } else {
                  fieldName = [name, key]
                }
                return <FieldFromType name={fieldName} type={fieldData["@type"]} data={fieldData} fieldKey={newFieldKey} key={fieldName} />
              })
            }
          </div>
        </>
      )
    }
    case "number": {
      return (<Form.Item
          key={JSON.stringify(name)}
          name={field}
          // @ts-ignore
          rules={rules}
          label={displayLabel()}
          fieldKey={newFieldKey}
          help={hasHelp(data)}
        >
          <InputNumber placeholder={label} disabled={disabled} />
        </Form.Item>
      )
    }
    case "string":
    default: {
      if(data["@meta"] && isMetaFunctionType(data["@meta"])) {
        return (
          <><MetaTypeInput
            key={JSON.stringify(name)}
            type={metaFunction(data["@meta"])}
            data={data}
            name={field}
            label={displayLabel()}
            placeholder={label}
            fieldKey={newFieldKey}
            required={data["@required"]}
            onChange={(name: string, data: string) => console.log("I would change the form data here")}
          >

          </MetaTypeInput></>)
      }
      if (typeMap.has(type)) {
        const Type: any = typeMap.get(type)
        return (

          <>
          <Type
            data={data}
            key={JSON.stringify(name)}
            name={field}
            label={displayLabel()}
            placeholder={label}
            required={data["@required"] || false}
            onChange={(name: string, data: any) => {

            }}
            fieldKey={newFieldKey}
          />

          </>
        )
      } else {
        return (<>
            <Form.Item
            key={JSON.stringify(field)}
            name={field}
            // @ts-ignore
            rules={rules}
            label={displayLabel()}
            fieldKey={newFieldKey}
            help={hasHelp(data)}
          >
            <Input placeholder={label} disabled={disabled}/>
          </Form.Item>

          </>
        )
      }
    }
  }
}

export default FieldFromType
