import React, {useState} from "react";
import {Button, Form, Input, Popover} from "antd";
import {useForm} from "antd/lib/form/Form";

interface I {
  onComplete(name: string): void
  text?: string
}

const AddChild: React.FC<I> = ({onComplete, text = "Add Child Node"}) => {
  const [visible, setVisible] = useState(false)
  const [form] = useForm()
  return <>
    <Popover
      content={<>
        <Form
          form={form}
          onFinish={(data) => {
            onComplete(data.name)
            form.resetFields()
            setVisible(false)
          }}
        >
          <Form.Item
            name={"name"}
            rules={[
              {required: true, message: "You must enter a name"}
            ]}
            required
            noStyle
          >
            <Input
              placeholder={"Node Name"}
              onChange={(e) => {
                const name = e.target.value.replace(/\s+/g, '_')
                form.setFieldsValue({name})
              }}
            />
          </Form.Item>
        </Form>
      </>}
      title={"Create Child Node"}
      visible={visible}
      trigger={"click"}
      onVisibleChange={setVisible}
    >
      <Button onClick={() => {
        setVisible(true)
      }}>{text}</Button>
    </Popover>
  </>
}

export default AddChild
