import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Button, List, Skeleton, Typography} from "antd";
import {IAction, ListAction} from "./ListAction";
import {PaginationConfig} from "antd/lib/pagination";
import {toTitleCase} from "../service/string";

const {Title} = Typography
export interface IListEntity {
  readonly __typename?: string
  readonly id?: string
  readonly name: string
  readonly title?: string
  readonly [key: string]: any
}

interface EntityList {
  name?: string
  list: Array<IListEntity>
  indexName?: string
  titleField?: string
  showTitleField?: boolean
  edit?(id: string, item?: any): void
  remove?(id: string, item?: any): void
  urlPrefix?: string
  absolute?: boolean
  actions?: Array<React.ReactNode | React.ReactNodeArray | IAction>
  noLink?: boolean
  loading?: boolean
  pagination?: false | PaginationConfig | undefined
  canFilter?: boolean
  onFilter?(data: string): void
}

export interface EntityControl {
  reload?(): void
}

export const EntityList: React.FC<EntityList> = ({actions = [], name, list= [], indexName = 'id', edit, remove, absolute = false, urlPrefix, titleField = 'title', showTitleField= false, loading, pagination = false, ...props}) => {
  const location = useLocation()

  const [innerList, setInnerList] = useState([])
  useEffect(() => {
    if(!list || (list.length === 0 && loading)) {
      let pageSize = 5

      if(pagination && pagination.pageSize) {
        pageSize = pagination.pageSize
      }

      const templist = Array.apply(null, Array(pageSize)).map((a,b) => b)

      setInnerList(templist)
    } else {
      setInnerList(list)
    }
  }, [list, pagination])

  const makePath = (type: IListEntity): any => {

    let prefix = ''
    if (type.__typename) {
      prefix = `${type.__typename.toLowerCase()}/`
    }
    if (urlPrefix && urlPrefix.length > 0) {
      prefix = `${urlPrefix}/`
    }
    const p = `${prefix}${type[indexName]}`
    let final = `/${p}`;
    if (!absolute) {
      final = `${location.pathname}/${p}`
    }

    return final.replace(/(\/)\/+/g, "$1")
  }

  const makeName = (item: IListEntity): string => {
    const name = item[titleField] || item.title || item.name
    if(!name || typeof name !== "string") {
      return item.id || "Cannot Generate Item Name"
    } else {
      return name
    }
  }

  const genActions = (id: string, item: any): any[] => {
    const itemActions = []
    let meta: any = {}
    if(item.hasOwnProperty("schema") && item.schema.hasOwnProperty("@meta")) {
      meta = item.schema["@meta"]
    }
    if(!!edit) {
      itemActions.push(<Button type={"primary"} onClick={() => edit(id, item)}>Edit</Button>)
    }
    if(!!remove && !meta.noDelete) {
      itemActions.push(<Button type={"primary"} danger onClick={() => remove(id, item)}>Remove</Button>)
    }
    actions.forEach((action: any) => {
      if(React.isValidElement(action)) {
        // @ts-ignore
        const newAction = React.cloneElement(action, {onClick: () => action.props.onClick()(id, item), actionData: {id, item}})
        itemActions.push(newAction)
      } else if(action.hasOwnProperty('name')) {

        itemActions.push(<ListAction {...action} id={id} onClickArgs={{name: makeName(item), id, item}}/>)
      }
    })
    return itemActions
  }

  return (
    <>
      {name && <Title level={3}>{name}</Title>}
      <List
        bordered
        style={{background: '#fff', marginBottom: '1.7rem'}}
        dataSource={innerList}
        // loading={loading || false}
        pagination={pagination}
        renderItem={(item) => {
          if(loading || !list) {
            let paragraph: any = false
            if(showTitleField) {
              paragraph = {rows: 1, style: {marginTop: "10px", height: "1.4rem"}}
            }
            return <>
              <List.Item
                actions={[
                  <Skeleton.Button />
                ]}
              >
                <Skeleton active paragraph={paragraph}>
                </Skeleton>
              </List.Item>
            </>
          }
          return (
            <List.Item
              actions={genActions(item[indexName], item)}
            >
              <List.Item.Meta
                title={!props.noLink?<Link
                  to={makePath(item)}>
                  {makeName(item)}
                </Link>:<span>{makeName(item)}</span>}
                description={showTitleField?toTitleCase(titleField):undefined}
              />
            </List.Item>
          )
        }}
        footer={props.children}
      />

    </>
  )
}
