import React from "react";
import {Switch} from "antd";
import {gql, useMutation} from "@apollo/client";

interface I {
  devMode: boolean
  onComplete(): Promise<void>
}

const ToggleDevMode: React.FC<I> = ({devMode = false, ...props}) => {

  const [toggleDevMode] = useMutation(
    gql`
      mutation toggleDev {
        ToggleDevelopment {
          success message
        }
      }
    `,
    {
      onCompleted() {
        props.onComplete()
      }
    }
  )

  return <>
    <Switch checked={devMode} onChange={() => toggleDevMode()} checkedChildren={"Disable Dev Mode"} unCheckedChildren={"Enable Dev Mode"} />
  </>
}

export default ToggleDevMode
