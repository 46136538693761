import React, {useEffect, useState} from "react";
import {GenerateFromJoi} from "../../../../../../components/Joi/GenerateFromJoi";

interface I {
  type: string
  metafunction: any
  metatype: any
  onComplete(data: any): void
  onCancel(): void
}

const Configure: React.FC<I> = ({type, metafunction = {}, metatype, onCancel, onComplete}) => {

  if (metatype.hasOwnProperty("build")) {
    return <>BUILD</>
  }

  if (metatype.hasOwnProperty("schema")) {
    return <>
      <GenerateFromJoi hideLabels cancel={onCancel} data={metafunction} schema={metatype.schema} execute={onComplete}
                       executeName={"Save"}/>
    </>
  }

  return <></>
}

export default Configure
