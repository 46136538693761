import {gql} from "@apollo/client";

export const LIST_API_KEYS = gql`
  query ListApiKeys {
    ApiKeys {
      name client_id id
    }
  }
`

export const READ_API_KEY = gql`
  query ReadApiKey($client_id: String!) {
    ReadApiKey(client_id: $client_id) {
      client_id name data methods types id
    }
  }
`

export const REVOKE_API_KEY = gql`
  mutation RevokeApiKey($id: ID! $client_id: String!) {
    RevokeApiKey(id: $id client_id: $client_id) {
      success
    }
  }
`

export const GENERATE_API_KEY = gql`
  mutation GenerateApiKey($methods: [String!]! $name: String! $types: [String!]!) {
    GenerateApiKey(methods: $methods types: $types name: $name ) {
      id name client_id types methods
    }
  }
`

export interface IApiKey {
  readonly id?: string
  readonly name: string
  readonly types: string[]
  readonly methods: string[]
  readonly client_id: string
}
