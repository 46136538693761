import {orgFields} from "./orgs";
import {workspaceFields} from "./workspace";
import {eventityFields} from "./eventity";
import {authFields} from "./auth";

export const fields = {
  ...orgFields,
  ...workspaceFields,
  ...eventityFields,
  ...authFields,
}
