import {gql} from "@apollo/client";

export const LIST_KEYS = gql`
  query ListKeys {
    ListEncryptionKeys {
      count
      list {
        id name
      }
    }
  }
`

export const CREATE_KEY = gql`
  mutation CreateKey($name: String!) {
    CreateEncryptionKey(name: $name) {
      id name
    }
  }
`

export const REVOKE_KEY = gql`
  mutation RevokeKey($keyId: String!) {
    RevokeEncryptionKey(keyId: $keyId) {
      success message
    }
  }
`
