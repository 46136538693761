import React from "react";
import {useMutation, useQuery} from "@apollo/client";
import {READ_API_KEY, REVOKE_API_KEY} from "../../graphql/queries/api-keys";
import {useHistory, useParams} from "react-router";
import {ApiKeyDetail} from "./Components/ApiKeyDetail";
import {Button, Card, Empty, Popconfirm, Spin} from "antd";

interface ApiKey {}

export const ApiKey: React.FC<ApiKey> = () => {
  const {clientId: client_id} = useParams() as any
  const history = useHistory()
  const {data: {ReadApiKey: apiKey} = {ReadApiKey: {}}, loading} = useQuery(
    READ_API_KEY,
    {
      variables: {
        client_id,
      }
    }
  ) as any

  const [revokeApiKey, {loading: revokeLoading}] = useMutation(
    REVOKE_API_KEY,
    {
      variables: {
        id: apiKey.id || "",
        client_id,
      }
    }
  )

  return (<>
    <Card
      style={{maxWidth: "30rem", minHeight: "30rem"}}
      actions={[
        !loading && <Popconfirm
          title={"Are you sure you wish to revoke this key?"}
          onConfirm={() => {
            revokeApiKey()
              .then(() => history.goBack())
          }}
        >
          <Button>Revoke API Key</Button>
        </Popconfirm>
      ]}
    >
      <Spin spinning={loading || revokeLoading}>
        {loading && <Empty/>}
        {!loading && <ApiKeyDetail {...apiKey} loading={false}/>}
      </Spin>
    </Card>
  </>)
}
