import React from "react";
import {FormItem} from "./FormItem";
import {DatePicker, Form} from "antd";

interface DateItem extends FormItem {}

export const DateItem: React.FC<DateItem> = ({name, label, data, required, onChange}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      required={required}
    >
      {// @ts-ignore
      }<DatePicker onChange={(date, dateString) => {
        if(!!onChange) {
          onChange(name, dateString)
        }
      }} />
    </Form.Item>
  )
}
