import React from "react";
import {Button} from "antd";
import {useMutation} from "@apollo/client";
import {truncateEventity} from "../../../../graphql/queries/generic";

interface I {
  name: string
  onComplete(): void
}

const TruncateEventity: React.FC<I> = ({name, onComplete}) => {

  const [truncate, {loading}] = useMutation(
    truncateEventity(name),
    {
      onCompleted() {
        onComplete()
      }
    }
  )

  return <Button danger type={"primary"} loading={loading} onClick={() => truncate()}>Truncate {name}</Button>
}

export default TruncateEventity
