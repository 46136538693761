import {useState} from 'react'

export const useInput = (initialValue: any, onChange: () => void = () => {}) => {
  const [value, setValue] = useState(initialValue)
  return [
    value,
    {
      value,
      onChange: (event: React.ChangeEvent<any>) => {
        onChange()
        setValue(event.target.value)
      }
    },
    () => setValue(initialValue),
    setValue,
  ]
}
