import React, {useEffect} from "react";
import {Col, Row} from 'antd'
import {WorkspacesList} from "../workspace/WorkspacesList";
import {ContentHeader} from "../layouts/style";
import {useHistory} from "react-router";
import {CreateWorkspace} from "../workspace/CreateWorkspace";
import EditableTitle from "../../components/Common/EditableTitle";
import {useLazyQuery, useMutation, useReactiveVar} from "@apollo/client";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import {READ_ORG, UPDATE_ORG} from "../../graphql/queries/org";

interface ViewOrganisation {
  // org: IOrganisation
  // orgId: string
}

export const ViewOrganisation: React.FC<ViewOrganisation> = ({})=> {
  const {push} = useHistory()
  const organisationId = useReactiveVar(currentOrgID)

  const [readOrg, {
    data: {sys: org} = {
      sys: {
        name: '',
        id: '',
        workspaces: [],
        accounts: [],
      }
    },
    refetch,
    loading,
  }] = useLazyQuery(READ_ORG, {variables: {id: organisationId, hasId: !!organisationId}})

  const [renameOrganisation, {loading: updateLoading}] = useMutation(UPDATE_ORG)

  useEffect(() => {
    if(organisationId && organisationId.length > 0) {
      readOrg()
    }
  }, [organisationId])

  return (
    <>
      <ContentHeader
        title={<EditableTitle title={org.name} onChange={async (title) => {
          await renameOrganisation({variables: {organisationId, name: title}})
          await refetch()
        }} loading={loading || updateLoading}  />}
        onBack={() => push("/dashboard")}
        extra={[
          <CreateWorkspace orgId={organisationId} />
        ]}
      />
      <Row>
        <Col xs={24}>
          <WorkspacesList orgId={organisationId} />
        </Col>
      </Row>
    </>
  )
}

