import React, {useEffect, useRef, useState} from "react";
import {ComponentContainer} from "./style";
import {notification} from "antd";

interface I {
  tag: string
  slug: string
  workspaceId: string
  client: any
  context?: any
}

const RenderComponent: React.FC<I> = ({tag, workspaceId, client, context}) => {
  let ref = useRef<HTMLDivElement>(null);
  const [componentMounted, setComponentMounted] = useState(false)
  const [coreNotification, ContextHolder] = notification.useNotification()

  useEffect(() => {
    if (!componentMounted) {
      const c = document.createElement(tag) as any
      ref.current.appendChild(c)
      setComponentMounted(true)
    }
  }, [tag, componentMounted])

  useEffect(() => {
    if (componentMounted && ref.current) {
      const webc = (ref.current.querySelector(tag) as any)
      webc.workspaceId = workspaceId
      webc.client = client
      webc.context = context
      webc.coreNotification = coreNotification
    }
  }, [componentMounted])

  return <>{ContextHolder}<ComponentContainer ref={ref} /></>
}

export default RenderComponent
