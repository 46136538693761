import React, {useEffect, useState} from "react";
import {toTitleCase} from "../../service/string";
import {useLazyQuery, useReactiveVar} from "@apollo/client";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {eventityItemQuery} from "../../graphql/queries/generic";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import {Alert, Card} from "antd";
import {Link} from "react-router-dom";

interface EventityLinkOutput {
  name: string
  data: any
  schema: Record<string, any>
}

export const EventityLinkOutput: React.FC<EventityLinkOutput> = ({name, data: itemId, schema}) => {
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const organisationId = useReactiveVar(currentOrgID)

  const [actions, setActions] = useState<any>([])

  const {["@meta"]: {function: {eventity: {name: evName, titleField}}}} = schema
  const eventityName: string = (evName as string)
  const queryText = eventityItemQuery(eventityName, ["id", titleField ?? "name"])
  const [readItem, {
    data: {
      content: {
        [eventityName]: {
          node: itemNode
        }
      }
    } = {content: {[eventityName]: {node: {}}}},
    loading,
  }] = useLazyQuery(queryText, {variables: {id: itemId}})

  useEffect(() => {
    if (itemId) {
      readItem()
    }
  }, [itemId])

  useEffect(() => {
    if(loading) {
      setActions([])
    } else {
      if(Object.keys(itemNode).length > 0) {
        setActions([
          <Link target={"_blank"} to={`/dashboard/organisation/${organisationId}/workspace/${workspaceId}/workspaceentity/${eventityName}/${itemNode.id}`}>View "{itemNode.name}"</Link>,
          <Link target={"_blank"} to={`/dashboard/organisation/${organisationId}/workspace/${workspaceId}/workspaceentity/${eventityName}`}>View "{toTitleCase(eventityName)}"</Link>,
          <span onClick={() => void readItem({fetchPolicy: "network-only"})}>Reload</span>
        ])
      } else {
        setActions([<span onClick={() => void readItem({fetchPolicy: "network-only"})}>Reload</span>])
      }
    }
  }, [loading])

  if(!loading && Object.keys(itemNode).length === 0) {
    return <>
      <h4>{toTitleCase(name)}</h4>
      <Card style={{width: "100%"}} actions={actions}>
        <Alert
          type={"error"}
          message={"Linked Eventity Not Found"}
          description={<><p>The requested item could not be found on the "{toTitleCase(eventityName)}" eventity. The eventity link may have been altered, or the item may have been deleted.</p><p>Please check the schema for this eventity.</p></>}
        />
      </Card>
    </>
  }

  return <>
    <h4>{toTitleCase(name)}</h4>
    <Card
      loading={loading}
      style={{width: "100%"}}
      actions={actions}
    >
      <Card.Meta title={toTitleCase(eventityName)} description={<><strong>Label:</strong> {itemNode?.name}</>} />
    </Card>
  </>
}
