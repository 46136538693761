import React from "react";
import {FormItem} from "./FormItem";
import {Form, Select} from "antd";
import {isDisabled} from "./tools";

interface ListItem extends FormItem {
  onUpdate?(data: any): void
}

const {Option} = Select

export const ListItem: React.FC<ListItem> = ({name, label, placeholder, data, required}) => (
  <Form.Item
    name={name}
    required={required}
    label={label}
  >
    <Select
      placeholder={placeholder || label}
      allowClear
      disabled={isDisabled(data)}
    >
      {data["@meta"] && data["@meta"].function.list_items.map((item: string, k: any) => (
        <Option value={item} key={`${k}-${item}`}>{item}</Option>
      ))}
    </Select>
  </Form.Item>
)
