import React, {useState} from "react";
import {Button, Form, Input, message, Modal, Popover, Space} from "antd";
import {LinkOutlined} from "@ant-design/icons";
import {useLazyQuery, useMutation} from "@apollo/client";
import {FETCH_REMOTE_MANIFEST, INJECT_REMOTE_MANIFEST} from "../../../../graphql/queries/extensions";
import ConfigureForm from "../ConfigureForm";
import ConfigureRemoteSchema from "./Components/ConfigureRemoteSchema";

interface I {
  onComplete(): Promise<void>
}

const AddRemoteExtension: React.FC<I> = ({onComplete}) => {

  const [pathValue, setPathValue] = useState<string>()
  const [manifest, setManifest] = useState<any>()
  const [visible, setVisible] = useState<boolean>(false)
  const [remoteSchema, setRemoteSchema] = useState<string>("")

  const [fetchManifest] = useLazyQuery(
    FETCH_REMOTE_MANIFEST,
    {
      onCompleted({FetchRemoteManifest: m}) {
        console.log(m)
        setManifest(m)
        setRemoteSchema(m.remoteSchema ?? "")
      },
      onError(e) {
        message.error(e.message)
      },
      fetchPolicy: "network-only",
    }
  )

  const [injectExtension] = useMutation(
    INJECT_REMOTE_MANIFEST,
    {
      onCompleted({InjectRemoteExtensionManifest: {success, message: info}}) {
        if(success) {
          message.success("Extension installed!")
          clear()
          onComplete()
            .then(() => {
            })
        } else {
          console.error(info)
          message.error("Installation failed")
        }
      },
      onError(e) {
        console.error("ERROR", e)
        message.error("Installation failed")
      }
    }
  )

  const execute = () => {
    fetchManifest({variables: {path: pathValue}})
      .catch(e => console.log(e))
  }

  const hasConfiguration = () => (manifest && manifest.configuration && Object.keys(manifest.configuration).length > 0)

  const complete = async (configuration?: any) => {
    injectExtension({variables: {path: pathValue, configuration, remoteSchema}})
  }

  const clear = () => {
    setPathValue(undefined)
    setVisible(false)
    setManifest(undefined)
  }


  return <>
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={() => {
        clear()
      }}
      title={"Connect Remote Application"}
      footer={false}
    >
      <p>
        Connect a remote application to your workspace. The application must have a manifest and comply with the expected API layout.
      </p>
      <Form.Item>
        <Input.Group>
          <Input
            style={{width: "80%"}}
            value={pathValue}
            type={"url"}
            onChange={(e) => setPathValue(e.target.value)}
          />
          <Button
            type={"primary"}
            style={{width: "20%"}}
            onClick={() => execute() }
          >Fetch</Button>
        </Input.Group>
      </Form.Item>
      {manifest && Object.keys(manifest).length > 0 && <>
        <Space direction={"vertical"}>
        <p><strong>Application:</strong> {manifest.name}</p>

          <ConfigureRemoteSchema
            path={remoteSchema}
            onChange={(path: string) => {
              setRemoteSchema(path)
            }}
            onReset={() => setRemoteSchema(manifest.remoteSchema)}
          />

        <p>Is this information correct?</p>

        <Form.Item>
          <Space>
            {hasConfiguration() && <ConfigureForm onComplete={complete} configuration={manifest.configuration} text={"Yes"} />}
            {!hasConfiguration() && <Button
              onClick={() => complete()}
            >Yes</Button>}
            <Button onClick={() => {
              setManifest(undefined)
            }}>No</Button>
          </Space>
        </Form.Item>
        </Space>
      </>}
    </Modal>
    <Popover content={"Connect Remote Application"}>
      <LinkOutlined
        onClick={() => {
          setVisible(true)
        }}
      />
    </Popover>
  </>
}

export default AddRemoteExtension
