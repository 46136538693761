import React from "react";
import {Route, RouteComponentProps, Switch} from "react-router";
import {ListExtensions} from "./ListExtensions";
import {ViewExtension} from "./ViewExtension";
import InstalledExtensions from "./InstalledExtensions";

interface Extensions extends RouteComponentProps {}

export const Extensions: React.FC<Extensions> = ({match}) => {
  return (
    <>
      <Switch>
        <Route exact path={match.url} component={InstalledExtensions}/>
        <Route exact path={`${match.url}/install`} component={ListExtensions}/>
        <Route path={`${match.url}/view/:extensionId`} component={ViewExtension}/>
      </Switch>
    </>
  )
}
