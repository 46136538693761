import React, {MouseEventHandler} from "react";
import {Timeline} from "antd";
import {StreamItem} from "../style";
import moment from "moment/moment";

interface IEventList {
  items: any[]
  viewItem(id: string, position: [number, number, number, number]): void
}

const EventList: React.FC<IEventList> = ({items, viewItem}) => {
  const onClick = (e: any, item: any) => {
    const rect = e.currentTarget.getBoundingClientRect()
    console.log(rect)
    viewItem(item.id, [rect.top,rect.left,rect.height,rect.width])
  }
  return (<>
    <Timeline style={{marginTop: "1rem"}} >
      {items && items.map((item: any) => <Timeline.Item>
        <StreamItem
          onClick={(e) => onClick(e, item)}
        >
          <h3>{item.type}</h3>
          <time dateTime={item.date}>{moment(item.date).format("ddd [the] Do [of] MMM YYYY")}</time>
        </StreamItem>
      </Timeline.Item>)}
    </Timeline>

  </>)
}


export default EventList
