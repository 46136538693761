import React from "react";
import {useMutation} from "@apollo/client";
import {ADD_EXTENSION_TO_WORKSPACE} from "../../../graphql/queries/extensions";
import ConfigureForm from "./ConfigureForm";
import extensions from "../../../service/extensions";

interface AddExtension {
  configuration: any
  id: string
  workspaceId: string
  onComplete(): void
}

const AddExtension: React.FC<AddExtension> = ({configuration, id, workspaceId, onComplete}) => {

  const [addExtension, {loading, data}] = useMutation(
    ADD_EXTENSION_TO_WORKSPACE,
  )

  const onAddExtension = async (id: string, configuration: any) => {
    return addExtension({variables: {id, configuration}})
      .then((result) => {
        extensions().reloadWorkspaceComponents(workspaceId)
        return result
      })
      .then(() => {
        onComplete()
      })
      .catch((e) => {
        console.log("ERROR", e)
      })
  }

  return <ConfigureForm onComplete={async (data) => {
    if(!!data && Object.keys(data).length > 0) {
      onAddExtension(id, data)
    } else {
      onAddExtension(id, {})
    }
  }} configuration={configuration} text={"Install"} loading={loading} />
}

export default AddExtension
