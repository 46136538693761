import {gql} from "@apollo/client";

export const WORKSPACE_COMPONENTS = gql`
  query WorkspaceComponents($workspaceId: String!) {
    WorkspaceComponents(workspaceId: $workspaceId) {
      id
      namespace
      version
      webcomponents
      scenes {
        name component tag
      }
      pages {
        name tag slug category
      }
      panels {
        type tag target
      }
    }
  }
`

export const WORKSPACE_PAGES = gql`
  query WorkspacePages($workspaceId: String! $hasId: Boolean!) {
    WorkspaceComponents(workspaceId: $workspaceId) @include(if: $hasId) {
      id namespace pages {
        name tag slug category
      }
    }
  }
`
