import React from "react";
import {Button} from "antd";

interface I {
  position: number
  setPosition(position: number): void
  steps: number
  validate?(): boolean
}

const Actions: React.FC<I> = ({position, setPosition, steps, validate = () => true}) => {
  return <>
    {position > 0 && <Button onClick={() => setPosition(position - 1)}>Back</Button>}
    {position < steps - 1 && <Button onClick={() => {

      if(validate()) {
        setPosition(position + 1)
      }
    }}>Next</Button>}
  </>
}

export default Actions
