export const getApiUri = () => {
  if(typeof window === "undefined") {
    return fromEnv()
  } else {
    if (window.hasOwnProperty("API_URI")) {
      return (window as any).API_URI
    } else {
      return fromEnv()
    }
  }
}

const fromEnv = () => {
  if (process.env.KUBE) {
    return "http://eventicle-core:3000"
  }
  const schema = process.env.API_SCHEMA || process.env.REACT_APP_API_SCHEMA || ''
  const host = process.env.API_HOST || process.env.REACT_APP_API_HOST || ''
  const port = process.env.API_PORT || process.env.REACT_APP_API_PORT || false
  const path = process.env.API_PATH ?? process.env.REACT_APP_API_PATH ?? ''
  if(!!schema && !!host ) {
    return `${schema}://${host}${port ? `:${port}` : ''}${path ? `${path}` : ''}`
  } else {
    return ""
  }
}
