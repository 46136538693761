import React, {useState} from "react";
import {Button, message, Modal} from "antd";
import {GenerateFromJoi} from "../../../components/Joi/GenerateFromJoi";

interface ConfigureForm {
  onComplete(data?: any): Promise<void>
  configuration: any
  configurationData?: any
  text: any
  loading?: boolean
}

const ConfigureForm: React.FC<ConfigureForm> = ({onComplete, configuration, configurationData, text, loading}) => {
  const [visible, setVisible] = useState(false)

  const start = () => {
    if (!!configuration && Object.keys(configuration).length > 0) {
      setVisible(true)
    } else {
      onComplete()
    }
  }

  return <>
    <Modal
      visible={visible}
      footer={null}
      title={"Configure Extension"}
      onCancel={() => setVisible(false)}
    >
      <GenerateFromJoi hideLabels schema={configuration} data={configurationData || {}} execute={(data) => {
        onComplete(data)
          .then(() => setVisible(false))
          .catch(e => message.error(e.message))
      }} cancel={() => setVisible(false)}/>
    </Modal>
    <Button
      loading={loading}
      type={"primary"}
      onClick={() => start()}
    >{text}</Button>
  </>
}

export default ConfigureForm
