import React from "react";
import {Button, Col, Form, Input} from "antd";
import {useForm} from "antd/lib/form/Form";

interface LostPasswordForm {
  onFinish(data: any): void
}

export const LostPasswordForm: React.FC<LostPasswordForm> = ({onFinish}) => {
  const [form] = useForm()
  return (<>
    <p>
      To reset your password, please enter your e-mail address.
    </p>
    <Form
      form={form}
      onFinish={(data) => {
        onFinish(data)
      }}
    >
      <Form.Item
        name={"email"}
        rules={[
          {required: true},
          {type: "email", message: "You must enter a valid e-mail address"}
        ]}
        validateTrigger={"onBlur"}
      >
        <Input placeholder={"E-Mail Address"}/>
      </Form.Item>
      <Form.Item>
        <Button htmlType={"submit"} type={"primary"}>Reset Password</Button>
      </Form.Item>
    </Form>
  </>)
}
