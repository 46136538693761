import {client} from "../graphql/client";
import {WORKSPACE_COMPONENTS} from "../graphql/queries/web-component";

class Extensions {
  public async reloadWorkspaceComponents(workspaceId: string, ) {
    const {data: {WorkspaceComponents}} = await client.query({
      query: WORKSPACE_COMPONENTS,
      fetchPolicy: "network-only",
      variables: {
        workspaceId
      }
    })
  }
}

let EXTENSIONS: Extensions

const extensions = () => {
  if(!EXTENSIONS) {
    EXTENSIONS = new Extensions()
  }
  return EXTENSIONS
}
export default extensions
