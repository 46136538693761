import React from "react";
import {Table} from "antd";
import {toTitleCase} from "../../service/string";

interface ArrayWithSchemaItemOutput {
  name: string
  data: any
}

export const ArrayWithSchemaItemOutput: React.FC<ArrayWithSchemaItemOutput> = ({name, data = []}) => {
  const columns = Object.keys(data[0] || {}).map((c, id) => ({
    title: toTitleCase(c),
    dataIndex: c,
    key: `${c}-${id}`
  }))
  const keyedData = data.map((item: any, id: any) => ({key: id, ...item}))
  return (<><Table dataSource={keyedData} columns={columns} style={{width: "100%"}} pagination={false} /></>)
}
