import React, {useState} from "react";
import {
  // useApolloClient,
  useMutation
} from "@apollo/client";
import RegisterForm from "./Components/RegisterForm";
import {REGISTER_USER} from "../../service/auth";
import {Button, Result} from "antd";
import {useHistory} from "react-router";

interface Register {}

enum RegisterState {
  INIT,
  SUCCESS,
  FAILED,
}

export const Register: React.FC<Register> = (props) => {
  // const client: ApolloClient<any> = useApolloClient();
  const [success, setSuccess] = useState(false);
  const [regState, setRegState] = useState<RegisterState>(RegisterState.INIT)
  const [regMessage, setRegMessage] = useState<string>()
  const history = useHistory()

  const [register] = useMutation(
    REGISTER_USER,
    {
      onCompleted({AccountSignUp: {id, name, email}}) {
        if (!!id && !!email) {
          setRegState(RegisterState.SUCCESS)
        }
      },
      onError(e) {
        setRegState(RegisterState.FAILED)
        setRegMessage(e.message)
      }
    }
  )
  if (regState === RegisterState.SUCCESS) {
    return (
      <Result
        status={"success"}
        title={"Registration Success!"}
        subTitle={"You have successfully registered with the service. A confirmation e-mail will arrive in your inbox shortly."}
        extra={[
          <Button type={"primary"} onClick={() => history.push("/login")} key={"log-in-button"}>Log In Now</Button>
        ]}
      />
    )
  } else if(regState === RegisterState.FAILED) {
    return (
      <Result
        status={"error"}
        title={"Registration Failed"}
        subTitle={regMessage}
        extra={[
          <Button type={"primary"} onClick={() => {
            setRegState(RegisterState.INIT)
            setRegMessage(undefined)
          }} key={"register-button"}>Reset</Button>
        ]}
      />
    )
  } else {
    return <RegisterForm register={register}/>
  }
}
