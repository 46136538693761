import React from "react";
import {Form, Input} from "antd";
import {FormItem} from "./FormItem";
import {generateRules, isDisabled} from "./tools";

export const StringItem: React.FC<FormItem> = ({data, label, placeholder, name, required}) => {
  return (<Form.Item
    rules={generateRules(data)}
    label={label}
    name={name}
    required={required}
  >
    <Input
      placeholder={placeholder || label}
      disabled={isDisabled(data)}
      allowClear
    />
  </Form.Item>)
}
