import styled from "styled-components";

export const FiltersExtraBody = styled.div`
  padding: 0.5rem 0;
  &>* {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const FiltersExtra = styled.div<{open?: boolean}>`
  overflow: hidden;
  margin-top: 0.5rem;
  ${(props) => {
    if(props.open) {
      return `
        max-height: 500px;
        transition: max-height 0.75s ease-in;
      `
    } else {
      return `
        max-height: 0;
        transition: max-height 0.4s ease-out;
      `
    }
  }}
`

export const FiltersAction = styled.span`
  cursor: pointer;
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
  margin-right: auto;
`
