import React, {useEffect} from "react";
import {Button, Form, Input, Space} from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {FormItem} from "./FormItem";
import {isDisabled} from "./tools";

interface ArrayItem extends FormItem {
  layout?: any
}

const tailLayout = {
  wrapperCol: { offset: 12, span: 12 },
};

export const ArrayItem: React.FC<ArrayItem> = ({onChange, name, label, data, required, layout}) => {
  return (<Form.List
    name={name}
  >{(fields, {add,remove}) => {
    let disabled = isDisabled(data)
    if(!disabled && data.max) {
      if(fields.length >= data.max) {
        disabled = true
      }
    }
    return(
      <>
        <Form.Item
          label={label}
          required={required}
        >
          <Button
            type="dashed"
            onClick={() => {
              add();
            }}
            disabled={disabled}
          >
            <PlusOutlined /> Add Array Item
          </Button>
        </Form.Item>
        {fields.map((field, index) => (
          <Form.Item
            key={field.key}
            {...tailLayout}
            {...layout}
          >
            <Space>
              <Form.Item
                {...field}
                validateTrigger={['onBlur']}
                rules={[
                  {
                    required: true,
                    message: "Please enter something or remove this element"
                  }
                ]}
                noStyle
              >
                <Input style={{width: '100%'}}/>
              </Form.Item>
              <MinusCircleOutlined
                className="dynamic-delete-button"
                style={{ margin: '0 8px' }}
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Space>
          </Form.Item>
        ))}

      </>
    )
  }}

  </Form.List>)
}
