import React from "react";
import Title from "antd/lib/typography/Title";
import JSONPretty from "react-json-pretty";

interface I {
  schema: any
  title: string
}

const Review: React.FC<I> = ({schema, title, children}) => {
  return <>
    <Title level={5}>Review Schema</Title>
    <p>Your "{title}" schema is complete. Check over to make sure everything looks right, then click next to save.</p>
    <JSONPretty json={schema} />
    <div>
      {children}
    </div>
  </>
}

export default Review
