import styled from "styled-components";
import {Layout, PageHeader} from "antd";

export const breakpoints = {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
}

export const EVFront = styled(Layout)`
  grid-template-rows: 1fr!important;

`

export const ContentHeader = styled(PageHeader)`
  padding-left: 2rem;
  margin: -2rem -2rem 1rem -2rem;
  background-color: var(--ev-secondary);
`

export const HeaderLogo = styled.span`
  display: inline-block;
  background-image: url("/assets/logo-title.png");
  background-position: 0 0;
  background-size: auto 40px;
  height: 40px;
  width: 209px;
  margin-right: 1rem;
  margin-top: 0.8rem;
  @media (max-width: ${breakpoints.lg}) {
    width: 40px;
  }
`


