import React, {useEffect, useRef, useState} from "react"
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {useApolloClient, useLazyQuery} from "@apollo/client";
import {WORKSPACE_COMPONENTS} from "../../graphql/queries/web-component";
import {notification} from "antd";

interface WebComponentWrapper {
  workspaceId: string
  schema: any
  viewName?: string
  webComponent?: any
  context?: any
}

export const WebComponentWrapper: React.FC<WebComponentWrapper> = ({context, schema, viewName = "NONE", ...props}) => {
  let ref = useRef<HTMLDivElement>(null);
  const client = useApolloClient()
  const workspaceId = currentWorkspaceId()
  const [componentMounted, setComponentMounted] = useState(false)
  const [coreNotification, ContextHolder] = notification.useNotification()

  const [readWorkspaceComponents, {data: {WorkspaceComponents} = {WorkspaceComponents: []}}] = useLazyQuery(
    WORKSPACE_COMPONENTS,
    {
      variables: {
        workspaceId,
      }
    })

  useEffect(() => {
    // This seems necessary for when we don't have a workspaceID
    if(workspaceId) {
      readWorkspaceComponents()
    }
  }, [workspaceId])

  let renderChildren = true;
  let webComponent: any;

  if(schema["@meta"]) {
    if(schema["@meta"].hasOwnProperty("views") && schema["@meta"].views.hasOwnProperty(viewName)) {

      const target: any[] = (schema["@meta"].views[viewName].uses as string).split(".")
      const tempComponent: any = WorkspaceComponents.find((c: any) => c.namespace === target[0] && c.id === target[1])
      if(tempComponent) {
        webComponent = tempComponent?.scenes.find((s: any) => s.name === target[2])
        if(webComponent) {
          renderChildren = false
        }
      }
    } else if (schema["@meta"].hasOwnProperty("display")) {
      // renderChildren = false
    }
  }

  useEffect(() => {

    if(!renderChildren && webComponent)  {
      if(!componentMounted) {
        const component = document.createElement(webComponent.tag)
        // @ts-ignore
        ref?.current.appendChild(component)
        setComponentMounted(true)
      }
    } else {
      setComponentMounted(false)
    }
  }, [renderChildren, webComponent])

  useEffect(() => {
    if(componentMounted && ref.current) {
      const webc = ref.current.querySelector(webComponent.tag)
      webc.workspaceId = workspaceId
      webc.client = client
      webc.context = context
      webc.coreNotification = coreNotification
    }
  }, [componentMounted])

  if(renderChildren) {
    return <>{props.children}</>
  } else {
    return <>
      {ContextHolder}
      <div ref={ref} />
    </>
  }


  // useEffect(() => {

  // }, [ref])
  //
  // return (<div ref={ref}></div>)

}
