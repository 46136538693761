import React from "react";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {CONFIGURE_EXTENSION, GET_EXTENSION_MANIFEST, manifestPlaceholder} from "../../graphql/queries/extensions";
import {useHistory, useParams} from "react-router";
import {Card, List, message, Typography} from "antd";
import Type from "./Components/Type";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import UpdateExtension from "./Components/UpdateExtension";
import AddExtension from "./Components/AddExtension";
import ConfigureExtension from "./Components/ConfigureExtension";
import { ContentHeader } from "../layouts/style";

const {Title} = Typography
const {Item, Item: {Meta}} = List

interface ViewExtension {}

export const ViewExtension: React.FC<ViewExtension> = () => {
  const {extensionId} = useParams<any>()
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const history = useHistory()
  const {
    data: {
      GetExtensionManifest: {
        manifest,
        installedManifest,
        installedVersion,
        configuration
      }
    } = {
      GetExtensionManifest: {
        manifest: manifestPlaceholder,
        installedManifest: manifestPlaceholder,
        installedVersion: "",
        configuration: {}
      }
    }, refetch
  } = useQuery(
    GET_EXTENSION_MANIFEST,
    {
      variables: {
        id: extensionId
      }
    }
  )

  const [configure, {loading}] = useMutation(
    CONFIGURE_EXTENSION
  )

  return (<>
    <ContentHeader
      title={"Extension Information"}
      onBack={() => history.goBack()}
    />
    <Card>
      <Meta
        title={<Title level={3}>{manifest.name}</Title>}
        description={manifest.description}
      />
      <List
        itemLayout={"vertical"}
      >
        <Item>

          {(!installedVersion || installedVersion === "0") &&
            <AddExtension configuration={manifest.configuration || {}} id={extensionId} workspaceId={workspaceId}
                          onComplete={() => console.log("Clear existing manifest list")}/>}
          {installedManifest && <ConfigureExtension configuration={installedManifest.configuration || {}}
                                                    configurationData={configuration}
                                                    id={extensionId}
                                                    workspaceId={workspaceId}
                                                    loading={loading}
                                                    onComplete={async (configuration) => {
                                                      configure({variables: {configuration, id: extensionId}})
                                                        .then(() => {

                                                          refetch()
                                                          message.success("Configuration updated!")
                                                        })
                                                    }}/>}
          {!!installedVersion && installedVersion !== "0" && manifest.version !== installedVersion &&
            <UpdateExtension id={extensionId}
                             workspaceId={workspaceId}
                             configuration={manifest.configuration}
                             configurationData={configuration}
                             onComplete={async () => {
                               console.log("Update")
                             }}
            />}
        </Item>
        <Item>
          <Meta
            title={"Version"}
            description={manifest.version}
          />
        </Item>
        {manifest.types.length > 0 && <Item>
          <Meta title={<Title level={4}>Types</Title>}/>
          {manifest.types.map((type: any) => <Type {...type} />)}
        </Item>}
        {manifest.commands.length > 0 && <Item>
          <Meta title={<Title level={4}>Commands</Title>}/>
          {manifest.commands.map((command: any) => (
            <Title level={5}>{command.type}</Title>
          ))}
        </Item>}
        {manifest.queries.length > 0 && <Item>
          <Meta title={<Title level={4}>Queries</Title>}/>
          {manifest.queries.map((query: any) => (
            <Title level={5}>{query.type}</Title>
          ))}
        </Item>}
      </List>
    </Card>

  </>)
}
