import React from "react";
import {Card} from "antd";
import {EventityItem} from "../Eventity/EventityItem";

interface ViewUser {
  user: any
}

export const ViewUser: React.FC<ViewUser> = ({user, ...props}) => (
  <>
    <Card
      style={{maxWidth: "40rem"}}
    >
      <EventityItem id={user.id} schema={{
        name: {
          "@type": "string"
        },
        email: {
          "@type": "string",
        },
      }} item={user} type={"SysAccount"} />
    </Card>
  </>
)
