import React from "react";
import {Button, Popconfirm} from "antd";

interface EventityItemRemove {
  item: any
  onConfirm(): void
}

const EventityItemRemove:React.FC<EventityItemRemove> = ({item, onConfirm}) => {
  if(!!item && item.data.hasOwnProperty("@meta") && !!item.data["@meta"].noDelete) {
    return <></>
  } else {
    return <><Popconfirm title={"Removing this node will remove all of its children"} onConfirm={onConfirm}>
      <Button type={"ghost"} danger>
        Remove Current Node
      </Button>
    </Popconfirm>
    </>
  }
}

export default EventityItemRemove
