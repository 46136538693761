import React from "react";
import {useHistory} from "react-router-dom";
import {Button, Space} from "antd";

interface LogInControls {
  loggedIn: boolean
}

export const LogInControls: React.FC<LogInControls> = ({loggedIn}) => {
  const history = useHistory()
  if (!loggedIn) {
    return (
      <Space direction={"horizontal"}>
        <Button size={"large"} type={"primary"} onClick={() => history.push("/login")}>Log In</Button>
        <Button size={"large"} onClick={() => history.push("/register")}>Register</Button>
      </Space>
    )
  } else {
    return (<></>)
  }
}
