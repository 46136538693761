import React from "react";
import {Helmet} from "react-helmet";
import {Route, Switch, match} from "react-router";
import {Profile} from "./Profile";

interface User {
  match: match
}

export const User: React.FC<User> = ({match}) => {
  return (<>
    <Helmet>
      <title>Account</title>
    </Helmet>
      <Switch>
        <Route exact path={match.url} render={() => <>ACCOUNT</>} />
        <Route path={`${match.url}/profile`} component={Profile} />
        <Route path={`${match.url}/preferences`} render={() => <>PREFERENCES/SETTINGS</>} />
      </Switch>
  </>)
}
