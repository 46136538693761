import React from 'react';
import {ApolloProvider} from "@apollo/client";
import {AuthService} from "./service/auth";
import {Router} from "react-router";
import createHistory from "./createHistory";
import {AppRoot} from "./AppRoot";
import {READ_ME} from "./graphql/queries/me";
import {client, initClient} from "./graphql/client";

export const App = () => {
  initClient()
  const authService = new AuthService(client);

  if(AuthService.getAuthToken()) {
    client.query({
        query: READ_ME
      })
      .then(() => {
        authService.setLoggedIn()
      })
      .catch(e => {
        // This is handled in the client error link now.
      })
  } else {
    authService.logOut()
  }

  return (
    <ApolloProvider client={client}>
      <Router history={createHistory('/')}>
        <AppRoot />
      </Router>
    </ApolloProvider>
  )
}


