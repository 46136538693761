import {gql} from "@apollo/client";

export const EVENTITY_LIST = '';

export const READ_EVENTITY = gql`
  query GetWorkspaceEntity($name: String! $workspaceId: String!) {
    GetWorkspaceEntity(name: $name, workspaceId: $workspaceId) {
      name id schema commands {
        inputSchema outputSchema handler name
      }
      handlers {
        create delete update list get
      }
    }
  }
`

export const READ_MINIMAL_EVENTITY = gql`
  query GetMinimalEventity($name: String! $workspaceId: String!) {
    GetWorkspaceEntity(name: $name, workspaceId: $workspaceId) {
      name id schema
    }
  }
`

export const CREATE_WORKSPACE_ENTITY = gql`
  mutation CreateWorkspaceEntity($input: CreateWorkspaceEntityInput!) {
    CreateWorkspaceEntity(input: $input) {
      id
    }
  }
`

export const UPDATE_WORKSPACE_ENTITY = gql`
  mutation UpdateWorkspaceEntity($input: UpdateWorkspaceEntityInput!) {
    UpdateWorkspaceEntity(input: $input) {
      id
    }
  }
`

export interface IEventityData {
  readonly data: IEventityDescription
}
export interface IEventityDescription {
  readonly name: string
  readonly id: string
  readonly schema: any
}

export const DELETE_WORKSPACE_ENTITY = gql`
  mutation DeleteWorkspaceEntity($input: DeleteWorkspaceEntityInput!) {
    DeleteWorkspaceEntity(input: $input) {
      id
    }
  }
`
