import React, {useEffect, useState} from "react";
import {EventityDataTree, IEventity, isTitle} from "../../service/eventity";
import {Button, Col, message, Row, Space, Tree} from "antd";
import {EventityItemDetail} from "./EventityItemDetail";
import {JoiSchemaBuilder} from "../Form/JoiSchemaBuilder";
import EventityItemRemove from "./EventityItemRemove";

interface EventityTree {
  eventity: IEventity,
  editable?: boolean
  remove?(name: string): void
  updateSchema?(schema: any): void
}

export const EventityTree: React.FC<EventityTree> = ({eventity: {data}, editable, remove, updateSchema}) => {

  const [item, setItem] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])

  const [evTree, setEvTree] = useState(new EventityDataTree("root", {}))

  const [treeData, setTreeData] = useState([])

  const [finalData, setFinalData] = useState({})

  useEffect(() => {
    setEvTree(new EventityDataTree(
      "root",
      {...data, "@type": "object"})
    )
    setTreeData(evTree.extractTree())
  }, [data])

  useEffect(() => {
    setFinalData(evTree.extractJOI())
  }, [evTree])


  useEffect(() => {
    const data = evTree.extractTree()
    setTreeData(data)
  }, [finalData])

  const updateTree = () => {
    const extracted = evTree.extractJOI()
    setFinalData(extracted)
    if (!!updateSchema) {
      const sendOut: any = {}
      Object.keys(extracted.root)
        // .filter((key) => (key.charAt(0) !== "@" && key !== "@meta"))
        .forEach((key) => sendOut[key] = extracted.root[key])
      updateSchema(sendOut)
    }
  }


  return (<>
    <Row style={{background: "#fff", width: "100%"}}>
      <Col xs={24} sm={6}
           style={{padding: "1rem", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>

        <Tree
          onSelect={(s, info) => {
            // @ts-ignore
            setSelectedKeys(s)
            if (s && s[0]) {
              // @ts-ignore
              setItem(s[0].split("/"))
            } else {
              setItem([])
            }
          }}
          treeData={treeData}
          defaultExpandedKeys={["root"]}
          defaultSelectedKeys={selectedKeys}
          selectedKeys={selectedKeys}
          autoExpandParent
          defaultExpandAll
          showLine
          style={{marginBottom: "auto"}}
        />

        <div>
          <Button size={"small"} onClick={() => {
            // @ts-ignore
            setSelectedKeys([])
            // @ts-ignore
            setItem([])
          }}>Clear Selection</Button>
        </div>
      </Col>
      <Col xs={24} sm={18} style={{borderLeft: "2px solid #c0c0c0", padding: "1rem"}}>
        <EventityItemDetail item={evTree.find(item)} root={evTree} onChange={() => updateTree()}/>
        <Space direction={"horizontal"}>
          {!!editable && <JoiSchemaBuilder onComplete={async (name, data) => {
            let inItem: string[] = item
            if (inItem.length === 0) {
              inItem = ["root"]
            }
            const parent = evTree.find(inItem)
            if (parent) {
              if(parent.hasChild(name)) {
                throw new Error("A node of the same name already exists at this level in the tree.")
              }
              parent.createChild(name, data)
              updateTree()
            }
          }} name={"schema"} title={selectedKeys.length > 0 ? "Add Schema Child Node" : "Add Schema Root Node"}/>}
          {(selectedKeys.length > 0) && <EventityItemRemove item={evTree.find(item)} onConfirm={() => {
            let inItem: string[] = item
            if (inItem.length === 0) {
              message.error("There was no node to remove.")
              return
            }
            const parent = evTree.find(evTree.findParentPath(inItem))
            if(parent) {
              const itemKey: string = inItem.pop() as any
              parent.removeChild(itemKey)
              if(isTitle(evTree, itemKey)) {
                evTree.removeMeta("titleField")
              }
              updateTree()
              // @ts-ignore
              setSelectedKeys([])
              setItem([])
            }
          }} />}
        </Space>
      </Col>
    </Row>
  </>)
}
