import React from "react";
import {Col, Empty, Row, Switch} from "antd";
import {toTitleCase} from "../../service/string";
import JSONPretty from "react-json-pretty";
import {isTitle} from "../../service/eventity";

interface EventityItemDetail {
  item: any
  root: any
  onChange(): void
}

export const EventityItemDetail: React.FC<EventityItemDetail> = ({item, root, ...props}) => {

  const toggleTitleField = () => {
    if(!isTitle(root, item.name)) {
      root.addMeta("titleField", item.name)
    } else {
      root.removeMeta("titleField")
    }
    props.onChange()
  }

  return (
    <>
      <Row gutter={10}>
        <Col flex={"100%"} style={{minHeight: "20rem"}}>
          {!item && <Empty description={false}/>}
          {item && item.data && <>
            <Row>
              <Col xs={6}>
                Name
              </Col>
              <Col style={{paddingRight: "0.5rem"}}>
                {toTitleCase(item.name)}
              </Col>

            </Row>
            {item.data["@type"] === "string" && <Row>
              <Col xs={6}>Use as List Title</Col>
              <Col><Switch onClick={() => {
              toggleTitleField()
            }} checked={isTitle(root, item.name)} /></Col></Row>}
            <Row>
              <Col xs={6}>
                Type
              </Col>
              <Col>
                {item.data["@type"]}
              </Col>
            </Row>
            {item.data["@required"] &&
            <Row>
              <Col xs={6}>
                <strong>Required</strong>
              </Col>
            </Row>
            }
            {item.data["@meta"] && <Row>
              <Col xs={6}>Meta Data</Col>
              <Col><JSONPretty json={item.data["@meta"]} /> </Col>
            </Row>}
          </>}
        </Col>
      </Row>
      {React.Children.count(props.children) > 0 && <Row justify={"center"} style={{paddingTop: "1rem"}}>
        <Col>
          {props.children}
        </Col>
      </Row>}
    </>
  )
}
