import React, {useState} from "react";
import {Button, Modal, Form, Input, message} from "antd";
import {useMutation} from "@apollo/client";
import {ADD_ACCOUNT_TO_ORG, READ_ORG_ACCOUNTS} from "../../graphql/queries/org";
import {useForm} from "antd/lib/form/Form";


interface AddAccount {
  orgId: string
  onComplete?(): void
}

export const AddAccount: React.FC<AddAccount> = ({orgId, onComplete}) => {

  const [visible, setVisible] = useState(false)

  const [addAccountToOrg, {data, loading}] = useMutation(
    ADD_ACCOUNT_TO_ORG,
    {
      onCompleted({AddAccountToOrganisation: {status}}: any) {
        switch(status) {
          case "INVITED": {
            message.info({content: "An invitation has been sent to the user"})
            break;
          }
          case "SUCCESS": {
            if(onComplete) {
              onComplete()
            }
            message.success({content: "The user has been added to your organisation"})
            break;
          }
        }
        setVisible(false)
      }
    },
  )

  const completeForm = () => {
    userForm.validateFields()
      .then(values => {
        addAccountToOrg({
          variables: {
            orgId,
            email: values.email
          }
        })
          .then(() => {
            setVisible(false)
            userForm.resetFields()
          })
          .catch((e) => console.log(e))

      })
      .catch(error => console.log(error))
  }

  const [userForm] = useForm()

  return (<>
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false)
          userForm.resetFields()
        }}
        onOk={completeForm}
        title={"Add Account to Organisation"}
        confirmLoading={loading}
      >
        <Form
          form={userForm}
          onFinish={(values) => {
            completeForm()
          }}
        >
          <Form.Item
            name={"email"}
            rules={[
              {type: "email", message: "You must enter an e-mail address"},
              {required: true, message: "You must enter an email address"}
            ]}
          >
            <Input placeholder={"E-Mail address"} />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={() => setVisible(true)}>Add Account</Button>
    </>
  )
}
