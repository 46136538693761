import React from "react";
import {Avatar, Dropdown} from "antd";
import {SITE_USER} from "../../graphql/queries/me";
import {UserMenu} from "./UserMenu";

interface UserAvatarMenu {
  user: SITE_USER
}

export const UserAvatarMenu: React.FC<UserAvatarMenu> = ({user}) => {

  return (<>
    <Dropdown overlay={<UserMenu user={user} />} trigger={["click"]}>
      <Avatar className={"user-menu root-avatar"} size={"large"} gap={1}>{user.name.charAt(0).toLocaleUpperCase()}</Avatar>
    </Dropdown>
  </>)
}
