import {gql} from "@apollo/client";

export const WORKSPACE_ENTITIES_FRAGMENT = gql`
  fragment WorkspaceEntities on WorkspaceEntityPage {
      totalCount
      entries {
          handlers {
              create update delete get list
          }
          id name schema
          commands {
              handler inputSchema outputSchema name
          }
      }
  }
`

export const DEV_ENABLED = gql`
  ${WORKSPACE_ENTITIES_FRAGMENT}
  query devSettings($id: String! $limit: Int $page: Int $sort: [Sort!]) {
    workspace(id: $id) {
      development_enabled,
      entities(limit: $limit, page: $page sort: $sort) {
        ...WorkspaceEntities
      }
    }
  }
`

export const GET_WORKSPACE_ENTITIES = gql`
  query GetWorkspace($id: String! $page: Int, $limit: Int $sort: [Sort!]) {
    workspace(id: $id) {
      entities(page: $page limit: $limit sort: $sort) {
        totalCount
        pageInfo {
          pageSize currentPage
        }
        entries {
          name id workspaceId schema owner
        }
      }
    }
  }
`

export const RENAME_WORKSPACE = gql`
  mutation RenameWorkspace($input: UpdateWorkspaceInput!) {
    UpdateWorkspace(input: $input) {
      id
    }
  }
`
