import React from "react";
import {filterMetaFields} from "../../../../../../graphql/queries/generic";
import {MetaTypeDisplay} from "../../../../../../components/Joi/MetaTypeDisplay";
import {Col, Row} from "antd";
import {toTitleCase} from "../../../../../../service/string";
import {DisplayJoiField} from "../../../../../../components/Joi/DisplayJoiField";

interface I {
  metafunction: any
  metatype: any
  node: string[]
  onComplete(data: {schema: any, metadata: any}): void
}

const Display: React.FC<I> = ({metafunction, metatype, node, children, onComplete}) => {
  const name = [...node].pop()
  if (metatype.hasOwnProperty("schema")) {
    const schema = metatype.schema
    return <>
      {filterMetaFields(Object.keys(schema)).map((key) => {
        let data = []
        if(metafunction.hasOwnProperty(key)) {
          data = metafunction[key]
        }
        return <React.Fragment key={`row-item-${key}`}>
          {key}
          <Row key={`schema-item-${key}`} className={"ant-row ant-form-item"}>
            <MetaTypeDisplay name={key} schema={schema[key]} data={data}>
              <Col xs={6} className={"ant-form-item-label"}>
                <label>{toTitleCase(key)}</label>
              </Col>
              <Col flex={"auto"} style={{padding: '5px 12px'}}>
                <DisplayJoiField schema={schema[key]} data={data}/>
              </Col>
            </MetaTypeDisplay>
          </Row>
          <Row style={{marginBottom: '24px'}}>
            <Col offset={6} flex={"auto"}>{children}</Col>
          </Row>
        </React.Fragment>
      })}
    </>
  } else if(metatype.hasOwnProperty("build")) {
    const Build = metatype["build"]
    return <>
      <Build
        data={metafunction}
        name={name}
        updateFields={(data) => {
          onComplete(data)
        }}
      />
    </>
  } else {
    return <>NO DISPLAY</>
  }
}

export default Display
