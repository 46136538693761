import React from "react";
import {Affix, Card} from "antd";
import JSONPretty from "react-json-pretty";
import "./event.scss"

interface IViewEvent {
  item: any
  id: string
  offset?: number
  pos: any
}

const ViewEvent: React.FC<IViewEvent> = ({item, id, offset= 80, pos}) => (
  <div style={{position: "relative", paddingTop: "0.5rem"}}>
    <Affix
      offsetTop={offset}
      className={"event-container"}
    >
      <Card
        title={item.type}
      >
        <JSONPretty json={item} />
      </Card>
    </Affix>
  </div>
)


export default ViewEvent
