import React from "react";

import {Link} from "react-router-dom";
import {Alert, Row, Col, Form, Input, Button} from "antd";

interface LogInForm {
  login(a: {variables: {email: string, password: string}}): any
  error: any
  resetError(): void
}

export const LogInForm: React.FC<LogInForm> = ({login, error, ...props}) => {

  // const [email, bindEmail] = useInput("", props.resetError)
  // const [password, bindPassword] = useInput("", props.resetError);


  const onFinish = ({email, password}: any) => {
    login({variables: {email, password}})
  }

  return (
    <>
      <Row justify={"center"}>
        <Col xs={"auto"} flex={"15rem"}>
          <Form onFinish={(values) => onFinish(values)} validateTrigger={"onBlur"}>

            <Form.Item
              name={"email"}
              rules={[
                {required: true},
                {type: "email", message: "You must enter a valid e-mail address"}
              ]}
            >
              <Input placeholder={"Email Address"}/>
            </Form.Item>
            <Form.Item
              name={"password"}
              rules={[
                {required: true}
              ]}
            >
              <Input.Password placeholder={"Password"} />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"} block>
                Log in
              </Button>
            </Form.Item>
          </Form>
          <div
            style={{display: "flex", justifyContent: "space-between"}}
          >
            <Link to={"/register"}>Register now!</Link>
            <Link to={"/lost-password"}>Lost Password?</Link>
          </div>
        </Col>
      </Row>
      {(error && error.state) && <Row justify={"center"}>
        <Col xs={"auto"}>
          <Alert type={"warning"} message={error.message} />
        </Col>
      </Row>}
    </>
  )
}
