import React from "react";
import {GenerateFromJoi} from "../../components/Joi/GenerateFromJoi";
import {DocumentNode, useMutation} from "@apollo/client";
import {commandMutation} from "../../graphql/queries/generic";

interface ExecuteCommand {
  inputSchema: JSON | any
  outputSchema: JSON | any
  name: string
  handler: string
  executeName?: string
  onComplete?(): void
  onError?(e: any): void
  refetchQueries?: [{query: DocumentNode, variables?: any}]
}

export const ExecuteCommand: React.FC<ExecuteCommand> = ({name, inputSchema, outputSchema, handler, onComplete, onError, refetchQueries= [], ...props}) => {

  const fields = Object.keys(outputSchema)

  const mutation = commandMutation(name, fields)

  const [execute] = useMutation(
    mutation,
    {
      onCompleted: (data) => {
        console.log('Completed', data)
        if (!!onComplete) {
          onComplete()
        }
      },
      onError: (e) => {
        console.error(e)
        if (!!onError) {
          onError(e)
        }
      },
      // refetchQueries,
    }
  )

  // if (
  //   inputSchema.hasOwnProperty("@meta")
  //   && inputSchema["@meta"].hasOwnProperty("views")
  //   && inputSchema["@meta"].views.hasOwnProperty(name)
  // ) {
  //   const usesView = inputSchema["@meta"].views[name];
  //   const views = currentWorkspaceViews()
  //   if(views.hasOwnProperty(usesView.uses)) {
  //     const view = views[usesView.uses]
  //     return (<WebComponentWrapper webComponent={view} />)
  //   }
  // }

  return (
    <>

      <GenerateFromJoi
      // @ts-ignore
      execute={(values) => execute({variables: {input: values}})}
      schema={inputSchema}
      excludeKeys={handler === "aggregate-create" ? ['id'] : []}
      {...props}
    /></>)

}
