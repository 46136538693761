import React, {useEffect, useState} from "react";
import {formPaginatedResponse} from "../../../service/pagination";
import {contentQuery} from "../../../graphql/queries/generic";
import {useQuery} from "@apollo/client";
import {Checkbox, Form, Input, List, Radio} from "antd";

interface EventityList {
  metadata: Record<string, any>
  schema: any
  workspaceId: string
  fields: string[]
  eventityName: string
  name: any
  required: boolean
  label: string
}

const EventityList: React.FC<EventityList> = ({metadata, workspaceId, schema, fields, eventityName, name, required, label}) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [filter, setFilters] = useState<Record<string, any> | undefined>([])
  const [list, setList] = useState<any>([])
  const [titleField, setTitleField] = useState("")

  const [totalCount, setTotalCount] = useState<number>(0)


  const query = contentQuery(eventityName, ['id', ...fields])

  const {
    data: {
      content: {[eventityName]: {search: {totalCount: sourceTotalCount, entries}}}
    } = {content: {[eventityName]: {search: formPaginatedResponse<any>({})}}},
    refetch,
    fetchMore,
    loading
  } = useQuery<any, { page: number, limit: number, filter?: any }>(
    query,
    {
      variables: {
        page,
        limit: pageSize,

      }
    },
  )

  useEffect(() => {
    if (entries) {
      // let titleField = ""
      let tf = fields[0]
      if (metadata?.function?.eventity?.titleField) {
        tf = metadata?.function?.eventity?.titleField
      }
      setTitleField(tf)
      // setList(entries)
    }
  }, [entries])

  return <>
    <Form.Item name={name} hidden />
    <Form.Item
      noStyle
      shouldUpdate={(prev, cur) => prev !== cur}
    >
      {({getFieldValue, setFieldsValue}) => {
        const value = getFieldValue(name)

        return <>
          <List
          header={label}
          bordered
          pagination={{
            total: sourceTotalCount,
            defaultPageSize: 5,
            pageSize,
            onChange: (p, ps) => {
              setPage(p)
              if(ps) {
                setPageSize(ps)
              }
            }
          }}
          style={{marginBottom: "1rem"}}
          size={"small"}
          dataSource={entries}
          renderItem={(item: any) => {

            const title = item[titleField]
            let checked = false
            if(value === item.id) {
              checked = true
            }
            return <List.Item
              key={`item-${item.id}`}
              actions={[
                <Radio checked={checked} onChange={() => {
                  setFieldsValue({[name]: item.id})
                }} />
              ]}
            >
              {title}
            </List.Item>
          }}
        />
        </>
      }}

    </Form.Item>
  </>
}

export default EventityList
