import React from "react";
import {FormItem} from "./FormItem";
import {Form, Input} from "antd";
import {isDisabled} from "./tools";

interface TextAreaItem extends FormItem {

}

export const TextAreaItem: React.FC<TextAreaItem> = ({name, label, placeholder, data, required}) => {
  return (
    <Form.Item
      name={name}
      required={required}
      label={label}
    >
      <Input.TextArea
        placeholder={placeholder || label}
        disabled={isDisabled(data)}
        allowClear
        />
    </Form.Item>
  )
}
