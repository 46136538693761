import React, {useEffect, useState} from "react";

import {Button, Modal} from "antd";
import {GenerateFromJoi} from "../../../../components/Joi/GenerateFromJoi";
import JSONPretty from "react-json-pretty";

interface GenerateApiKey {
  generateKey(name: string, types: string[], methods?: []): void
  entities: any
}

export const GenerateApiKey: React.FC<GenerateApiKey> = ({generateKey, entities}) => {
  const [visible, setVisible] = useState(false)

  const [selectedTypes, setSelectedTypes] = useState<string[]>([])
  const [schema, setSchema] = useState<any>({})

  const entitiesList = () => {
    if (entities && entities.entries && typeof entities.entries !== "function") {

      return entities.entries.map((entity) => ({
        value: entity.name,
        label: entity.name
      }))
    } else {
      return []
    }
  }

  const methodsList = () => {
    if (entities && entities.entries && typeof entities.entries !== "function") {
      const {entries} = entities
      const m = entries
        .filter((entry) => selectedTypes.includes(entry.name))
        .flatMap((entry) => {
          let cc = []
          let qq = []
          if (entry.commands) {
            cc = entry.commands.map((c) => ({value: c.name, label: c.name}))
          }
          if (entry.queries) {
            qq = entry.queries.map((q) => ({value: q.name, label: q.name}))
          }
          return [...cc, ...qq]
        })
      return m
    }
    return []
  }

  useEffect(() => {

    const typeItems: any[] = entitiesList()
    const methodItems: any[] = methodsList()

    const schema2 = {
      name: {
        "@type": "string",
        "@required": true,
      },
      types: {
        "@type": "array",
        "@meta": {
          "function": {
            metatype: "multiple-choice",
            items: typeItems
          }
        }
      },
      methods: {
        "@type": "array",
        "@meta": {
          "function": {
            metatype: "multiple-choice",
            items: methodItems
          }
        }
      }
    }

    setSchema(schema2)
  }, [entities, selectedTypes])

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        title={"Generate API Key"}
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <GenerateFromJoi
          executeName={"Generate Key"} schema={schema} execute={async (variables) => {
            await generateKey(variables.name, variables.types, variables.methods)
            setVisible(false)
          }}
          onFieldsChange={(fields) => {
            if(fields.types) {
              setSelectedTypes(fields.types)
            }
          }}
        />
      </Modal>
      <Button type={"primary"} onClick={() => setVisible(true)}>Generate API Key</Button>
    </>
  )
}
