import React, {useEffect, useState} from "react";
import {IWorkspaceEntity} from "../../../../service/workspace";
import {Space, Table} from "antd";
import TruncateEventity from "./TruncateEventity";

interface I {
  entities: any
}

const DeveloperActions: React.FC<I> = ({entities, ...props}) => {

  const [data, setData] = useState<any[]>([])

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size={"middle"}>
          <TruncateEventity name={record.name} onComplete={() => {}} />
        </Space>
      )
    }
  ]

  useEffect(() => {
    if(!!entities) {
      const {entries = []} = entities
      if(Array.isArray(entries)) {
        console.log("IS ARRAY", entries)
        const d = entries.map((entity: IWorkspaceEntity) => ({
          id: entity.id,
          name: entity.name,
          key: entity.id,
        }))
        setData(d)
      }

    }
  }, [entities])

  return <>
    <Table dataSource={data} columns={columns} style={{maxWidth: '40rem'}}/>
  </>
}

export default DeveloperActions
