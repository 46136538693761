import React from "react";
import JSONPretty from "react-json-pretty";
import {Card, Col, Row, Typography} from "antd";

const {Title} = Typography

interface Type {
  name: string
  schema: any
}

const Type: React.FC<Type> = ({name, schema}) => (<>
  <Card style={{marginBottom: "0.5rem", textAlign: "initial"}}>
  <Title level={4}>{name}</Title>

  {Object.keys(schema).map((key) => {
      if (key === "@meta") {
        return (
          <><Row gutter={10}>
            <Col xs={24}>
              Meta:
            </Col>
          </Row>
          <Row gutter={10}>
            <Col>
              <JSONPretty json={schema[key]} />
            </Col>
          </Row></>
          )
      } else {
        return (
          <Row gutter={10}>
            <Col>Field: {key}</Col>
            {schema[key].hasOwnProperty("@type") && <Col>Type: {schema[key]["@type"]}</Col>}
          </Row>
        )
      }
    }
  )}
  </Card>
</>)

export default Type
