import styled from "styled-components";
import {Col} from "antd";

export const SpacedCol = styled(Col)`
>* {
  margin: 0 0 1rem 0;
  &:last-child {
    margin: 0;
  }
}
`
